export const queryKeys = {
  CHECK_AUTH: "check-auth",
  FAVOURITES: "favourites",
  REGION_DISTRICTS: "REGION_DISTRICTS",
  CITY: "CITY",
  MAIN_REGIONS: "MAIN_REGIONS",
  METROS: "METROS",
  UYSOT_COIN: "UYSOT_COIN",
  USER_NOTIF:"USER_NOTIF"
}
