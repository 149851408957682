import React, { useEffect } from "react";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { useGetUyotCoin } from "pages/main-layout/services/queries";
import { Drawer, Grid } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import UysotCoinContent from "./uysot-coin-content";
import MiniCloseIcon from "assets/icons/MiniCloseIcon";
import { formatAmount } from "utils/helpers/format-amount";
import { cx } from "utils/cx";
import styles from './index.module.scss';
const UysotCoinMobile = () => {
  const dispatch = useAppDispatch();
  const {
    data
  } = useGetUyotCoin();
  const {
    setCoinMobileVisible
  } = favouritesReducerActions;
  const {
    md
  } = Grid.useBreakpoint();
  const coinVisible = useAppSelector(state => state.favouritesReducer.coinMobileVisible);
  const coinCounts = data?.reduce((prev, acc) => {
    return prev + acc?.count_coin;
  }, 0);
  const handleClose = () => {
    dispatch(setCoinMobileVisible(false));
  };
  useEffect(() => {
    if (coinVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.overflowX = "hidden";
    }
  }, [coinVisible]);
  const handleOpenUysotCoin = () => {
    dispatch(setCoinMobileVisible(true));
  };
  return <>
      <div className="flex items-center gap-1 md:gap-2.5 bg-[#4D6F68] pl-[11px] rounded-full cursor-pointer w-max h-7 relative" onClick={handleOpenUysotCoin}>
        <span className="text-base text-white font-semibold">
          {formatAmount(coinCounts as number) || 0}
        </span>
        <div className={cx("w-[28.5px] h-[30px]", styles.coin_container)} />
      </div>
      {!md && <Drawer open={coinVisible} placement='bottom' className='[&_.ant-drawer-content]:rounded-t-xl [&_.ant-drawer-content-wrapper]:!h-auto [&_.ant-drawer-header]:!hidden [&_.ant-drawer-close]:hidden' onClose={handleClose}>
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-8">
              <div className="text-xl font-bold flex items-center">
                <span className="text-[#1D2939]">U</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0DC683] to-[#00A389]">
                  COIN
                </span>
              </div>
              <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full bg-[#F2F2F7] cursor-pointer" onClick={handleClose}>
                <MiniCloseIcon size={11} />
              </div>
            </div>
            <UysotCoinContent data={data} coinCounts={coinCounts} />
          </div>
        </Drawer>}
    </>;
};
export default UysotCoinMobile;