import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { cx } from 'utils/cx';
import { StaticImageData } from 'next/image';
import { proBrandData } from 'pages/app/utils/constants/pro-brand-modal-data';
import { useAppSelector } from 'hooks/reduxHooks';
import ShimmerImage from 'common/shimmer-image';
import styles from './index.module.scss';
const SwiperPart = () => {
  const {
    locale
  } = useRouter();
  const swiperRef = useRef<SwiperType | null>(null);
  const open = useAppSelector(state => state.proBrandReducer.open);
  useEffect(() => {
    if (open && swiperRef.current) {
      swiperRef.current.slideTo(0);
    }
  }, [open]);

  // shadow dagi px larni remga o'tkazmadim.Chunki juda kichkina raqam bo'lib ketardi.
  return <div className={styles.container} data-sentry-component="SwiperPart" data-sentry-source-file="index.tsx">
        <Swiper spaceBetween={10} slidesPerView={1} pagination={{
      clickable: true,
      el: '.custom_pagination_pro'
    }} navigation={{
      prevEl: "#navigation_prev",
      nextEl: "#navigation_next"
    }} modules={[Pagination, Navigation]} className={styles.swiper_part} onSwiper={swiper => {
      swiperRef.current = swiper;
    }} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">
            {proBrandData?.map(el => <SwiperSlide className='flex flex-col gap-4' key={el?.name?.uz}>
                    <div className={cx('w-full gap-4 bg-gradient-to-b from-purple-50 pt-[3.125rem] px-8 to-purple-300 h-[18.125rem] justify-center items-center overflow-hidden')}>
                            <div className='text-[1.6875rem] w-full font-bold text-[var(--color-pink)] text-center mb-4'>#{(el?.name?.[(locale || 'ru') as never] as string)?.toUpperCase()}</div>
                            <div className='flex justify-center items-center flex-col'>
                                {el?.image && <div className='w-[14.5rem] flex justify-center items-center'>
                                        <ShimmerImage className='!object-contain' src={el?.image as unknown as StaticImageData} alt={el?.name?.[(locale || 'ru') as never]} />
                                    </div>}
                            </div>
                        {el?.social_networks && <div className='flex items-center justify-center mt-5 gap-2'>
                                {el?.social_networks?.map(el => <div key={el?.text?.uz} className='flex flex-col gap-2 w-28 h-[5.625rem] justify-center items-center bg-white rounded-lg shadow-[inset_0px_2.6px_2.6px_0px_#E5E5E540,inset_0px_-1.3px_2.6px_0px_#62626240,0px_2.6px_4.87px_0px_#00000040]'>
                                        <div className='w-[2.6875rem] h-[2.6875rem] flex justify-center items-center'>
                                            <ShimmerImage alt={el?.text?.uz} src={el?.image} />
                                        </div>
                                        <span className='text-[0.5625rem] font-extrabold text-gray-900'>{el?.amount}</span>
                                        <span className='text-[0.375rem] font-semibold text-gray-800'>{el?.text?.[(locale || 'ru') as never]}</span>
                                </div>)}
                            </div>}
                    </div>
                    <div className='px-3'>
                        <div key={el?.title?.uz} className={cx('rounded-[0.625rem] p-2.5 border-none bg-[var(--lightest-blue)] flex flex-col gap-2')}>
                            <span className='text-base font-semibold text-[var(--dark-gray-blue)] text-center'>{el?.title?.[(locale || 'ru') as never]}</span>
                            <span className='text-xs font-normal text-[var(--cool-gray)] text-center'>
                                    {el?.description?.[(locale || 'ru') as never]}
                            </span>
                        </div>
                    </div>
                </SwiperSlide>)}
        </Swiper>

        <div className={styles.custom_pagination_container}>
          <div className={cx("custom_pagination_pro", styles.custom_pagination_pro)}></div>
        </div>
    </div>;
};
export default SwiperPart;