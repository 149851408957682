import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { deadlineItems } from '../filter-desktop-modal/filterItems';
import { layoutLang } from 'utils/language/layout';
const DeadlineItem = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const {
    setDeadlineYears
  } = favouritesReducerActions;
  const deadline_years = useAppSelector(state => state.favouritesReducer.filter.deadline_years);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const handleAdd = (value: string) => {
    if (deadline_years.includes(value)) {
      const newDeadlineItems = deadline_years?.filter(item => item !== value);
      dispatch(setDeadlineYears(newDeadlineItems));
    } else {
      dispatch(setDeadlineYears([...deadline_years, value]));
    }
  };
  useEffect(() => {
    if (query?.deadline_years) {
      if (Array.isArray(query?.deadline_years)) {
        dispatch(setDeadlineYears(query?.deadline_years));
      } else {
        dispatch(setDeadlineYears([query?.deadline_years]));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="DeadlineItem" data-sentry-source-file="deadline-item.tsx">
      {layoutLang[locale || 'ru']["Tugash_muddati"]}
      <div className="flex items-center gap-2">
        {deadlineItems?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 
           ${deadline_years?.includes(item?.value) ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => handleAdd(item?.value)}>
            {item?.name[locale as keyof typeof item.name]}
          </div>)}
      </div>
    </div>;
};
export default DeadlineItem;