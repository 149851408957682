import React from 'react';
import { Drawer, Grid, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { dailyActiveUserCoinActions } from 'store/reducers/daily-active-user-coin-reducer';
import Content from './content';
const DailyActiveUserCoinModalDrawer = () => {
  const dispatch = useAppDispatch();
  const {
    md
  } = Grid.useBreakpoint();
  const open = useAppSelector(state => state.dailyActiveUserCoinReducer.dailyActiveCoinModal.open);
  const {
    setOpen
  } = dailyActiveUserCoinActions;
  const handleClose = () => {
    dispatch(setOpen({
      open: false,
      description: null,
      title: null
    }));
    // window.history.back(); // Remove the history entry when closing
  };

  // useEffect(() => {
  //   const handleBackButton = () => {
  //     if (open) {
  //       dispatch(setOpen({
  //         open:false,
  //         description:null,
  //         title:null
  //       })); // Close the modal/drawer instead of navigating away
  //       return;
  //     }
  //     window.history.back();
  //   };

  //   if (open) {
  //     window.history.pushState({ modalOpen: true }, '', window.location.href); // Push modal open state
  //   }

  //   window.addEventListener('popstate', handleBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', handleBackButton);
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open, dispatch]);

  return <>
      {!md ? <Drawer open={open} onClose={handleClose} placement="bottom" className="[&_.ant-drawer-header]:hidden [&_.ant-drawer-content]:rounded-t-xl [&_.ant-drawer-content-wrapper]:!h-auto">
          <Content />
        </Drawer> : <Modal centered open={open} onCancel={handleClose} className="[&_.ant-modal-content]:rounded-xl [&_.ant-modal-close]:!hidden !w-[408px]" footer={null}>
          <Content />
        </Modal>}
    </>;
};
export default DailyActiveUserCoinModalDrawer;