import React from "react";
import HeaderBottom from "./bottom/HeaderBottom";
import AppSuggestion from "../../../../pages/app-suggestion/Index";
import { useRouter } from "next/router";
import styles from "./header.module.scss";
const Header: React.FC = () => {
  const {
    pathname
  } = useRouter();
  return <div className={styles.header} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      {pathname !== '/dl' && <AppSuggestion />}
      <HeaderBottom data-sentry-element="HeaderBottom" data-sentry-source-file="Header.tsx" />
    </div>;
};
export default Header;