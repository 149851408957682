import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ApartmentPlansModel } from "pages/detailed-residential-complex/utils/models/apartment-plans-model";

interface InitialState {
    open:boolean;
    modalData:{
        open:boolean;
        item:Omit<ApartmentPlansModel,'images'> & {
            image:string
        } | null
    } 
}

const initialState:InitialState = {
    open:false,
    modalData:{
        open:false,
        item:null
    }
}

export const planirovkaSlice = createSlice({
    name:'planirovka',
    initialState,
    reducers:{
        setOpenPlanirovka:(state,action:PayloadAction<boolean>)=>{
            state.open = action.payload
        },
        setOpenPlanirovkaModal:(state,action:PayloadAction<{ open:boolean,item:Omit<ApartmentPlansModel,'images'> & {
            image:string
        } | null }>)=>{
            state.modalData.open = action.payload.open
            state.modalData.item = action.payload.item
        }
    }
})

export default planirovkaSlice.reducer
export const planirovkaActions = planirovkaSlice.actions