import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type InitialState = {
    dailyActiveCoinModal:{
        open:boolean;
        description:{
            uz:string;
            ru:string
        } | null
        title:{
            uz:string;
            ru:string
        } | null
    };
    getBigPrizeModalOpen:{
        open:boolean;
        id:number | undefined;
        amount:number | undefined
    };
    bigPrizeModal:{
        open:boolean;
        id?:number | undefined;
        amount?:number | undefined
    }
}

export const initialState:InitialState = {
    dailyActiveCoinModal:{
        description:null,
        title:null,
        open:false
    },
    getBigPrizeModalOpen:{
        id:undefined,
        open:false,
        amount:undefined
    },
    bigPrizeModal:{
        id:undefined,
        open:false,
        amount:undefined
    }
}

export const dailyActiveUserCoinSlice = createSlice({
    initialState,
    name:"daily-active-user-coin",
    reducers:{
        setOpen:(state,action:PayloadAction<{
            open:boolean;
            description:{
                uz:string;
                ru:string
            } | null
            title:{
                uz:string;
                ru:string
            } | null
        }>)=>{
            state.dailyActiveCoinModal = action.payload
        },
        setOpenGetBigPrizeModal:(state,action:PayloadAction<{
            open:boolean;
            id:number | undefined;
            amount:number | undefined
        }>)=>{
            state.getBigPrizeModalOpen = action.payload
        },
        setOpenBigPrizeModal:(state,action:PayloadAction<{
            id?:undefined,
            open:boolean,
            amount?:undefined
        }>)=>{
            state.bigPrizeModal = action.payload
        }
    }
});

export default dailyActiveUserCoinSlice.reducer
export const dailyActiveUserCoinActions = dailyActiveUserCoinSlice.actions