import React, { useEffect, useState, useMemo } from "react";
import { Button, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useGetDailyActiveUserCoins } from "pages/app/services/queries";
import { useActivateUserDailyCoin } from "pages/app/services/mutations";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { DailyActiveUserCoindConditions } from "pages/app/utils/models/daily-active-user-coin-model";
import { cx } from "utils/cx";
import { dailyActiveUserCoinActions } from "store/reducers/daily-active-user-coin-reducer";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import Item from "./item";
import ShimmerImage from "common/shimmer-image";
import bigPrizeImg from "../../../../../assets/images/daily-coin-big-prize.webp";
import disabledBigPrizeImg from "../../../../../assets/images/disabled-big-prize.webp";
import { userReducerActions } from "store/reducers/userReducer";
import { formatTime } from "utils/helpers/format-time";
dayjs.extend(durationPlugin);
const Content = () => {
  const open = useAppSelector(state => state.dailyActiveUserCoinReducer.dailyActiveCoinModal.open);
  const description = useAppSelector(state => state.dailyActiveUserCoinReducer.dailyActiveCoinModal.description);
  const title = useAppSelector(state => state.dailyActiveUserCoinReducer.dailyActiveCoinModal.title);
  const mutate = useActivateUserDailyCoin();
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const {
    setOpenBigPrizeModal,
    setOpen
  } = dailyActiveUserCoinActions;
  const isAuth = useAppSelector(state => state.userReducer.isAuth);
  const {
    setVisibleLoginModal
  } = userReducerActions;
  const {
    data: userCoins,
    isFetching,
    refetch
  } = useGetDailyActiveUserCoins(open);
  const [activeOne, setActiveOne] = useState<DailyActiveUserCoindConditions>();
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    if (userCoins?.start_at && dayjs().unix() < userCoins?.start_at) {
      setTimeLeft(Math.max(Number(userCoins?.start_at) - dayjs().unix(), 0));
    } else {
      setTimeLeft(0);
    }
  }, [userCoins?.start_at]);
  useEffect(() => {
    if (timeLeft <= 0) refetch();
    const interval = setInterval(() => {
      setTimeLeft(prev => Math.max(prev - 1, 0));
    }, 1000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);
  useEffect(() => {
    if (userCoins && Object.keys(userCoins)?.length > 0 && userCoins?.daily_conditions?.length > 0) {
      const firstNotReceivedOne = userCoins?.daily_conditions?.find(el => el?.received === false);
      setActiveOne(firstNotReceivedOne);
    }
  }, [userCoins]);
  const getButtonText = useMemo(() => {
    if (activeOne?.type === "big_prize") return layoutLang[locale || 'ru']["bigPrizeTitle"];
    if (timeLeft) return <div className="flex flex-col">
          <span className="!text-[13px] !font-medium !text-white">
            {layoutLang[locale || 'ru']["nextPrizeText"]}
          </span>
          <span className="!text-[13px] !font-medium !text-white">
            {formatTime(timeLeft, locale as never)}
          </span>
        </div>;
    return layoutLang[locale || 'ru']["receiveCoin"];
  }, [timeLeft, activeOne, locale]);
  const handleOpenBigPrizeModal = () => {
    dispatch(setOpenBigPrizeModal({
      open: true,
      id: activeOne?.id as never,
      amount: activeOne?.amount as never
    }));
  };
  const handleActivate = () => mutate.mutate({
    id: activeOne?.id as never
  });
  const handleOnClick = () => {
    if (!isAuth) {
      dispatch(setVisibleLoginModal(true));
      return;
    }
    if (activeOne?.type === 'big_prize') {
      handleActivate();
      handleOpenBigPrizeModal();
    } else {
      handleActivate();
    }
  };
  const handleClose = () => {
    dispatch(setOpen({
      description: null,
      title: null,
      open: false
    }));
  };
  return <Spin spinning={isFetching} data-sentry-element="Spin" data-sentry-component="Content" data-sentry-source-file="index.tsx">
      <div className="flex flex-col gap-5  justify-center">
        <div className="flex flex-col items-center justify-center gap-3">
          <span className="text-[22px] font-semibold md:text-2xl text-black text-center">
            {title?.[locale as never]}
          </span>
          <span className="text-sm font-medium text-[#475467] text-center">
            "{description?.[locale as never]}"
          </span>
        </div>
        <div className="flex items-center gap-x-3 gap-y-7 flex-wrap mt-4">
          {userCoins?.daily_conditions?.map(el => <div key={el?.id}>
              {el?.type === "big_prize" ? <div className="relative w-[212px] md:w-[235px] h-[99px]">
                  <span className="absolute top-[15px] z-[1] left-4 text-[20px] font-semibold text-white w-1/2 line-clamp-2">
                    {layoutLang[locale || 'ru']["bigPrizeTitle"]}
                  </span>
                  <ShimmerImage src={activeOne?.type === 'big_prize' || el?.received ? bigPrizeImg : disabledBigPrizeImg} alt="big-prize-image" />
                </div> : <Item due_at={userCoins?.due_at} item={el} start_at={userCoins?.start_at} activeId={activeOne?.id} />}
            </div>)}
        </div>
        <div className="flex  gap-[7.65px] mt-3 md:mt-[30px]">
          <span className="w-[100px] h-[49px] rounded-xl py-4 px-5 flex justify-center items-center bg-[#F2F2F0] cursor-pointer" onClick={handleClose}>
            {layoutLang[locale || 'ru']["close"]}
          </span>
          <Button type="primary" className={cx("w-[250.35px] h-[49px] rounded-xl", timeLeft && "!bg-[#949494] border border-solid !border-[#949494]")} onClick={handleOnClick} loading={mutate.isLoading} disabled={mutate.isLoading || !!timeLeft} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {getButtonText}
          </Button>
        </div>
      </div>
    </Spin>;
};
export default Content;