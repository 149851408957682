import React from "react";
interface IProps {
  stroke?: string | undefined;
  size?: number;
}
const CheckIcon: React.FC<IProps> = ({
  stroke = "#00A389",
  size
}) => {
  return <svg width={size || "24"} height={size || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="CheckIcon" data-sentry-source-file="CheckIcon.tsx">
      <path d="M20 6L9 17L4 12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="CheckIcon.tsx" />
    </svg>;
};
export default CheckIcon;