import React from 'react';
const DailyUserCoin: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <>
    {props?.fill ? <svg {...props} width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M37.4079 26.7889C34.1873 37.6346 23.4829 44.0101 13.4988 41.0291C3.51473 38.0481 -1.96814 26.8394 1.25246 15.9937C4.47305 5.14802 15.1775 -1.22757 25.1616 1.75343C35.1457 4.73442 40.6285 15.9432 37.4079 26.7889Z" fill="#21BEA5" />
        <path d="M13.645 40.9139C23.3031 41.5037 32.1335 34.4558 35.4724 23.4155C36.4451 20.1989 37.2316 17.4812 37.4247 14.3354C38.6135 18.7143 38.6382 22.4457 37.4247 26.9022C34.2847 37.2852 23.3031 44.2562 13.645 40.9139Z" fill="#14B39A" />
        <path d="M34.6044 25.7951C31.4058 36.5901 21.3604 43.1112 12.1674 40.3605C2.97441 37.6098 -1.88496 26.6289 1.31369 15.834C4.51234 5.03902 14.5577 -1.48212 23.7507 1.26858C32.9437 4.01928 37.8031 15.0002 34.6044 25.7951Z" fill="#12D2B2" />
        <path d="M21.6204 40.0633C27.443 38.128 32.5424 33.0555 34.7937 26.1385C38.2851 15.4115 33.6044 4.31041 24.339 1.34363C20.911 0.246002 17.3358 0.415572 14.0146 1.60816C17.2219 0.542109 20.6487 0.427929 23.9396 1.48169C33.205 4.44847 37.8858 15.5495 34.3944 26.2766C32.1947 33.0349 27.2759 38.0324 21.6204 40.0633Z" fill="#59FEEB" />
        <path d="M31.2522 24.9377C28.5997 33.8431 20.2354 39.2128 12.5701 36.9311C4.90475 34.6494 0.841092 25.5804 3.49364 16.675C6.1462 7.76952 14.5105 2.3999 22.1758 4.68159C29.8411 6.96327 33.9048 16.0322 31.2522 24.9377Z" fill="#18B299" />
        <g filter="url(#filter0_i_14820_11258)">
        <ellipse cx="13.9206" cy="16.8455" rx="13.9206" ry="16.8455" transform="matrix(0.958025 0.286686 -0.284075 0.958802 9.7207 0.900391)" fill="#1ADBBD" />
        </g>
        <g filter="url(#filter1_d_14820_11258)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49951 24.4669C8.75137 23.639 9.65614 23.183 10.5203 23.4486L21.9954 26.9743C22.8596 27.2399 23.356 28.1264 23.1041 28.9543C22.8522 29.7823 21.9475 30.2383 21.0832 29.9727L9.60821 26.4469C8.744 26.1814 8.24762 25.2949 8.49951 24.4669Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.581 11.1359C20.3933 10.6996 21.401 11.0092 21.8317 11.8274L27.3462 22.3038C27.7768 23.1221 27.4674 24.1391 26.6551 24.5754C25.8428 25.0118 24.835 24.7021 24.4043 23.8838L19.6698 14.8891L10.7398 19.6853C9.92744 20.1217 8.91974 19.8121 8.48905 18.9938C8.05834 18.1755 8.36772 17.1585 9.18009 16.7222L19.581 11.1359Z" fill="white" />
        <path d="M19.7365 22.1951C19.3272 23.5406 17.9055 24.2965 16.5612 23.8834C15.2169 23.4704 14.459 22.0448 14.8683 20.6993C15.2776 19.3539 16.6992 18.598 18.0435 19.0111C19.3878 19.4241 20.1458 20.8497 19.7365 22.1951Z" fill="#FFAE5B" />
        </g>
        <defs>
        <filter id="filter0_i_14820_11258" x="3.31561" y="4.40137" width="29.1287" height="33.2822" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-0.78351" />
        <feGaussianBlur stdDeviation="0.391755" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00673509 0 0 0 0 0.564534 0 0 0 0 0.472745 0 0 0 0.31 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_14820_11258" />
        </filter>
        <filter id="filter1_d_14820_11258" x="7.51141" y="10.938" width="20.8126" height="21.4609" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1.56702" />
        <feGaussianBlur stdDeviation="0.391755" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14820_11258" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14820_11258" result="shape" />
        </filter>
        </defs>
    </svg> : <svg {...props} width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37.4079 26.7889C34.1873 37.6346 23.4829 44.0101 13.4988 41.0291C3.51473 38.0481 -1.96814 26.8394 1.25246 15.9937C4.47305 5.14802 15.1775 -1.22757 25.1616 1.75343C35.1457 4.73442 40.6285 15.9432 37.4079 26.7889Z" fill="#A3A3A3" />
    <path d="M13.645 40.9139C23.3031 41.5037 32.1335 34.4558 35.4724 23.4155C36.4451 20.1989 37.2316 17.4812 37.4247 14.3354C38.6135 18.7143 38.6382 22.4457 37.4247 26.9022C34.2847 37.2852 23.3031 44.2562 13.645 40.9139Z" fill="#949494" />
    <path d="M34.6044 25.7951C31.4058 36.5901 21.3604 43.1112 12.1674 40.3605C2.97441 37.6098 -1.88496 26.6289 1.31369 15.834C4.51234 5.03902 14.5577 -1.48212 23.7507 1.26858C32.9437 4.01928 37.8031 15.0002 34.6044 25.7951Z" fill="#BDBDBD" />
    <path d="M21.6204 40.0633C27.443 38.128 32.5424 33.0555 34.7937 26.1385C38.2851 15.4115 33.6044 4.31041 24.339 1.34363C20.911 0.246002 17.3358 0.415572 14.0146 1.60816C17.2219 0.542109 20.6487 0.427929 23.9396 1.48169C33.205 4.44847 37.8858 15.5495 34.3944 26.2766C32.1947 33.0349 27.2759 38.0324 21.6204 40.0633Z" fill="#7F7F7F" />
    <path d="M31.2522 24.9377C28.5997 33.8431 20.2354 39.2128 12.5701 36.9311C4.90475 34.6494 0.841092 25.5804 3.49364 16.675C6.1462 7.76952 14.5105 2.3999 22.1758 4.68159C29.8411 6.96327 33.9048 16.0322 31.2522 24.9377Z" fill="#8F8F8F" />
    <g filter="url(#filter0_i_14820_15418)">
    <ellipse cx="13.9206" cy="16.8455" rx="13.9206" ry="16.8455" transform="matrix(0.958025 0.286686 -0.284075 0.958802 9.7207 0.900391)" fill="#DBDBDB" />
    </g>
    <g filter="url(#filter1_d_14820_15418)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49951 24.4669C8.75137 23.639 9.65614 23.183 10.5203 23.4486L21.9954 26.9743C22.8596 27.2399 23.356 28.1264 23.1041 28.9543C22.8522 29.7823 21.9475 30.2383 21.0832 29.9727L9.60821 26.4469C8.744 26.1814 8.24762 25.2949 8.49951 24.4669Z" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.581 11.1359C20.3933 10.6996 21.401 11.0092 21.8317 11.8274L27.3462 22.3038C27.7768 23.1221 27.4674 24.1391 26.6551 24.5754C25.8428 25.0118 24.835 24.7021 24.4043 23.8838L19.6698 14.8891L10.7398 19.6853C9.92744 20.1217 8.91974 19.8121 8.48905 18.9938C8.05834 18.1755 8.36772 17.1585 9.18009 16.7222L19.581 11.1359Z" fill="white" />
    <path d="M19.7365 22.1951C19.3272 23.5406 17.9055 24.2965 16.5612 23.8834C15.2169 23.4704 14.459 22.0448 14.8683 20.6993C15.2776 19.3539 16.6992 18.598 18.0435 19.0111C19.3878 19.4241 20.1458 20.8497 19.7365 22.1951Z" fill="#BDBDBD" />
    </g>
    <defs>
    <filter id="filter0_i_14820_15418" x="3.31561" y="4.40137" width="29.1287" height="33.2822" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix" />
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
    <feOffset dx="-0.78351" />
    <feGaussianBlur stdDeviation="0.391755" />
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
    <feColorMatrix type="matrix" values="0 0 0 0 0.231982 0 0 0 0 0.231982 0 0 0 0 0.231982 0 0 0 0.31 0" />
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_14820_15418" />
    </filter>
    <filter id="filter1_d_14820_15418" x="7.51141" y="10.938" width="20.8126" height="21.4609" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix" />
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
    <feOffset dy="1.56702" />
    <feGaussianBlur stdDeviation="0.391755" />
    <feComposite in2="hardAlpha" operator="out" />
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14820_15418" />
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14820_15418" result="shape" />
    </filter>
    </defs>
    </svg>}
    </>;
};
export default DailyUserCoin;