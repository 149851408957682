import { layoutLang } from "utils/language/layout";

export const formatTime = (seconds: number,locale:string) => {
  const twoDigits = (n: number) => n.toString().padStart(1, "0");
  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${twoDigits(hours)} ${layoutLang[locale || 'ru']['soat']}`;
  } else if (minutes > 0) {
    return `${twoDigits(minutes)} ${layoutLang[locale || 'ru']['daqiqa']}`;
  } else {
    return `${twoDigits(remainingSeconds)} ${layoutLang[locale || 'ru']['soniya']}`;
  }
};
