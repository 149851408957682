import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Input } from 'antd';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { localeFormatter } from 'utils/helpers/localeFormatter';
import { layoutLang } from 'utils/language/layout';
import { filterTypes } from '../filterItems';
import { makeStaticFilters } from 'utils/helpers/makeStaticFilters';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import RepairedItem from 'common/filter-desktop/filter-item/repaired-item';
import FilterMetro from 'pages/main/components/filter-metro/FilterMetro';
import SearchLocation from 'pages/main/components/search-location/SearchLocation';
interface IProps {
  setTypeFilter: React.Dispatch<React.SetStateAction<string>>;
  typeFilter: string;
}
const rowClass = "grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4";
const FilterDesktopTop: React.FC<IProps> = ({
  setTypeFilter,
  typeFilter
}) => {
  const dispatch = useAppDispatch();
  const {
    locale,
    pathname,
    query
  } = useRouter();
  const staticFilters = makeStaticFilters((query?.apartmentId || query?.id) as string);
  const filter = useAppSelector(state => state.favouritesReducer.filter);
  const filterType = useAppSelector(state => state.favouritesReducer.filterType);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    setFloorValue,
    setAreaValue,
    setStudio,
    setChangeComplexName,
    setPayments,
    setRepaired,
    setClasses
  } = favouritesReducerActions;
  useEffect(() => {
    if (pathname.includes("kvartiri")) {
      setTypeFilter("apartment");
    } else if (pathname.includes("novostroyki")) {
      setTypeFilter("complex");
    } else {
      setTypeFilter(filterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, openDesktopModal]);
  useEffect(() => {
    if (query?.complex_name) {
      dispatch(setChangeComplexName(String(query?.complex_name)));
    }
    if (staticFilters?.rooms_count) {
      dispatch(setStudio(staticFilters?.rooms_count));
    }
    if (staticFilters?.payment_method_ids) {
      dispatch(setPayments(staticFilters?.payment_method_ids));
    }
    if (staticFilters.repaired) {
      dispatch(setRepaired(staticFilters.repaired));
    }
    if (staticFilters.class_ids) {
      dispatch(setClasses(staticFilters.class_ids));
    }
    if (query?.min_floor) {
      dispatch(setFloorValue({
        type: "min_floor",
        value: query?.min_floor !== null ? Number(query?.min_floor) : null
      }));
    }
    if (query?.max_floor) {
      dispatch(setFloorValue({
        type: "max_floor",
        value: query?.max_floor !== null ? Number(query?.max_floor) : null
      }));
    }
    if (query?.min_area) {
      dispatch(setAreaValue({
        type: "min_area",
        value: query?.min_area !== null ? Number(query?.min_area) : null
      }));
    }
    if (query?.max_area) {
      dispatch(setAreaValue({
        type: "max_area",
        value: query?.max_area !== null ? Number(query?.max_area) : null
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type: "min_floor" | "max_floor") => {
    let newValue = e.target.value.replace(/\D/g, "");
    if (/^0+$/.test(newValue)) {
      newValue = "0";
    }
    newValue = newValue.replace(/^0+(\d)/, "$1");
    const numValue = newValue ? parseInt(newValue, 10) : null;
    dispatch(setFloorValue({
      type,
      value: numValue!
    }));
  };
  return <>
      <div className={rowClass}>
        {layoutLang[locale || 'ru']["Расположение"]}
        <div className="w-[370px]">
          <SearchLocation type="filter" data-sentry-element="SearchLocation" data-sentry-source-file="filter-desktop-top.tsx" />
        </div>
        <div className="w-[370px]">
          <FilterMetro type="filter" data-sentry-element="FilterMetro" data-sentry-source-file="filter-desktop-top.tsx" />
        </div>
      </div>
      <div className={rowClass}>
        {layoutLang[locale || "ru"]['qavat']}
        <Input onChange={e => handleChange(e, "min_floor")} value={filter?.min_floor !== null ? filter?.min_floor?.toLocaleString("ru") : ''} size="large" placeholder={layoutLang[locale || "ru"]['dan']} data-sentry-element="Input" data-sentry-source-file="filter-desktop-top.tsx" />
        <Input onChange={e => handleChange(e, "max_floor")} value={filter?.max_floor !== null ? filter?.max_floor?.toLocaleString("ru") : ''} size="large" placeholder={layoutLang[locale || "ru"]['gacha']} data-sentry-element="Input" data-sentry-source-file="filter-desktop-top.tsx" />
      </div>
      <RepairedItem data-sentry-element="RepairedItem" data-sentry-source-file="filter-desktop-top.tsx" />
      <div className={rowClass}>
        {layoutLang[locale || "ru"]["korinish_turi"]}
        <div className="flex items-center gap-2">
          {filterTypes?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 ${typeFilter === item?.value ? "bg-[#e7fbf5]" : "bg-[#f2f4f7]"} ${typeFilter === item?.value ? "text-[#00A389]" : "text-[#000000D9]"}`} onClick={() => setTypeFilter(item?.value as "apartment" | "complex")}>
              {item?.name[locale as keyof typeof item.name]}
            </div>)}
        </div>
      </div>
      <div className={rowClass}>
        {layoutLang[locale || "ru"]["maydon"]}
        <Input onChange={e => {
        dispatch(setAreaValue({
          type: "min_area",
          value: Number(e.target.value?.replace(/\s/g, ""))
        }));
      }} value={localeFormatter(String(filter?.min_area))} size="large" placeholder={layoutLang[locale || "ru"]['dan']} suffix={layoutLang[locale || "ru"]["м²"]} data-sentry-element="Input" data-sentry-source-file="filter-desktop-top.tsx" />
        <Input onChange={e => {
        dispatch(setAreaValue({
          type: "max_area",
          value: Number(e.target.value?.replace(/\s/g, ""))
        }));
      }} value={localeFormatter(String(filter?.max_area))} size="large" placeholder={layoutLang[locale || "ru"]['gacha']} suffix={layoutLang[locale || "ru"]["м²"]} data-sentry-element="Input" data-sentry-source-file="filter-desktop-top.tsx" />
      </div>
    </>;
};
export default FilterDesktopTop;