import React, { useMemo } from 'react';
import { DailyActiveUserCoindConditions } from 'pages/app/utils/models/daily-active-user-coin-model';
import DailyUserCoin from 'assets/icons/daily-user-coin';
import { cx } from 'utils/cx';
import { layoutLang } from 'utils/language/layout';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import SecondCheckIcon from 'assets/icons/second-check-icon';
type Props = {
  item: DailyActiveUserCoindConditions;
  start_at: number | null;
  due_at: number | null;
  activeId: number | undefined;
};
const Item: React.FC<Props> = ({
  item,
  activeId,
  due_at,
  start_at
}) => {
  const {
    locale
  } = useRouter();
  const getTitle = useMemo(() => {
    if (item?.received) return <div className='flex items-center gap-1'>
            <span>{layoutLang[locale || 'ru']['received']}</span>
            <span className='w-[13px] h-[13px] rounded-full bg-[#1FD577] flex justify-center items-center'>
                <SecondCheckIcon />
            </span>
        </div>;
    if (activeId === item?.id) {
      if (!start_at && !due_at) return layoutLang[locale || 'ru']['today'];
      if (start_at && due_at) {
        const startOfToday = dayjs().startOf("day").unix();
        const endOfToday = dayjs().endOf("day").unix();
        return due_at >= startOfToday && start_at <= endOfToday ? layoutLang[locale || 'ru']['today'] : layoutLang[locale || 'ru']['tomorrow'];
      }
    }
    return `${item?.step}-${layoutLang[locale || 'ru']['kun']}`;
  }, [item, activeId, start_at, due_at, locale]);
  return <div className={cx('flex flex-col gap-1 items-center justify-center w-[100px] md:w-[112px]')} data-sentry-component="Item" data-sentry-source-file="item.tsx">
            <div className={cx('flex items-center w-full  justify-center gap-2 relative h-[57.35px] rounded-[11.47px] py-[9.56px] px-[19.12px] bg-[#F9F9F9] text-[15.29px] font-bold text-[#344054]', item?.id === activeId && "border-[2px] border-solid border-[#1ADBBD] bg-[#CEFFF7]")}>
                <span className='absolute bottom-[34px]'>
                    <DailyUserCoin fill={item?.id === activeId || item?.received ? '#1ADBBD' : ''} data-sentry-element="DailyUserCoin" data-sentry-source-file="item.tsx" />
                </span>
                <div className='mt-2.5'>
                    <span>{item?.amount}</span> <span className='text-[12.57px]'>UCoin</span>
                </div>
            </div>
            <span className='text-xs font-medium text-[#344054]'>
                {getTitle}
            </span>
        </div>;
};
export default Item;