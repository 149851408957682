import { isEmptyObject } from "../../../utils/helpers/isEmptyObject";
import { LOCATION, SIZE } from "../../../utils/constants/queryParamsKeys";
import queryString, { stringify } from "query-string";
import { ParsedUrlQuery } from "querystring";
import $api from "../../../services/RequestService";
import { isNumberOdd } from "utils/helpers/isNumberOdd";
import { EXCLUDE_URL } from "pages/residential-complexes/utils/constants/excludeUrl";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
import { isValidUrl } from "utils/helpers/isValidUrl";
import { makeStaticFilters } from "utils/helpers/makeStaticFilters";

// apartments
export const fetchApartments = (query?: ParsedUrlQuery, complex_id?: number) => {
  const staticFilters = makeStaticFilters(query?.apartmentId as string)
  const newQuery = query?.apartmentId && !isValidUrl(query?.apartmentId as string) ? query : removeObjProperties(query!, "apartmentId")
  let url = `/apartment/view`;
  const result = (query?.location as string)?.includes("gorod-tashkent") ? (query?.location as string)?.split("gorod-tashkent-")[1] : (query?.location as string)?.split(`${(query?.location as string)?.split("-")[0]}-`)[1];

  const queryCopy: ParsedUrlQuery = newQuery?.[LOCATION] === DEFAULT_LOCATION ? {
    page: "1",
    [Array.isArray(newQuery?.metro_line_ids) ? "metro_line_ids" : "metro_line_ids[]"]: newQuery?.metro_line_ids,
    [Array.isArray(newQuery?.nearest_place_ids) ? "nearest_place_ids" : "nearest_place_ids[]"]: newQuery?.nearest_place_ids,
    [Array.isArray(newQuery?.complex_ids) ? "complex_ids" : "complex_ids[]"]: newQuery?.complex_ids,
    ...staticFilters,
    ...newQuery
  } :  {
    page: "1",
    [result ? "district_url_name" : "city_url_name"]: query ? result ? result : query[LOCATION] : undefined,
    [Array.isArray(newQuery?.metro_line_ids) ? "metro_line_ids" : "metro_line_ids[]"]: newQuery?.metro_line_ids,
    [Array.isArray(newQuery?.nearest_place_ids) ? "nearest_place_ids" : "nearest_place_ids[]"]: newQuery?.nearest_place_ids,
    [Array.isArray(newQuery?.complex_ids) ? "complex_ids" : "complex_ids[]"]: newQuery?.complex_ids,
    ...staticFilters,
    ...newQuery
  };

  if (complex_id) {
    if (!isEmptyObject(queryCopy)) {
      url += `?${stringify(
        {
          ...queryCopy,
          [SIZE]: !queryCopy?.page || isNumberOdd(Number(queryCopy?.page)) ? 14 : 15,
          complex_id: complex_id
        },
        { arrayFormat: "comma" }
      )}`;
    } else {
      url += `?${stringify({ complex_id: complex_id }, { arrayFormat: "comma" })}`;
    }
    url = queryString.exclude(url, [EXCLUDE_URL.location]);
  } else if (!isEmptyObject(queryCopy)) {
    url += `?${stringify(
      {
        ...queryCopy,
        [SIZE]: 14
      },
      { arrayFormat: "bracket" }
    )}`;
    url = queryString.exclude(url, [EXCLUDE_URL.location]);
  }

  return {
    fetch: async () => {
      const res = await $api.get(url);
      return res.data.data;
    },
    url
  };
};

//payment-methods
export const fetchNewPaymentMethods = async () => {
  const res = await $api.get(`/payment-method/select`);
  return res.data.data;
};
