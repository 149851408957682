import { layoutLang } from "utils/language/layout"

export const filterPriceSeparator = (price: number, locale?: any) => {
  if (price < 1000) {
    return price
  }
  if (price < 1000000) {
    return (price / 1000)?.toString()?.includes(".")
      ? (price / 1000).toFixed(1) + " " + layoutLang[locale || 'ru']["ming"]
      : (price / 1000).toFixed(0) + " " + layoutLang[locale || 'ru']["ming"]
  }
  if (price < 1000000000) {
    return (price / 1000000)?.toString()?.includes(".")
      ? (price / 1000000).toFixed(1) + " " + layoutLang[locale || 'ru']["mln"]
      : (price / 1000000).toFixed(0) + " " + layoutLang[locale || 'ru']["mln"]
  }
  if (price < 100000000000) {
    return (price / 1000000000)?.toString()?.includes(".")
      ? (price / 1000000000).toFixed(1) +
          " " +
          layoutLang[locale || 'ru']["mlrd"]
      : (price / 1000000000).toFixed(0) +
          " " +
          layoutLang[locale || 'ru']["mlrd"]
  }
  return (price / 1000000000000)?.toString()?.includes(".")
    ? (price / 1000000000000).toFixed(1) +
        " " +
        layoutLang[locale || 'ru']["trln"]
    : (price / 1000000000000).toFixed(0) +
        " " +
        layoutLang[locale || 'ru']["trln"]
}
