import React from "react";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { Collapse } from "antd";
import { useGetMainRegions, useGetMetros, useGetRegionDistricts } from "pages/main-layout/services/queries";
import { cx } from "utils/cx";
import { UZ } from "utils/constants/locales";
import CustomLink from "common/custom-link";
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper";
import ArrowIcon from "assets/icons/ArrowIcon";
import styles from "./footerFilter.module.scss";
import { staticLinks } from "utils/constants/staticLinks";
const {
  Panel
} = Collapse;
const FooterFilter = () => {
  const {
    query,
    locale,
    pathname
  } = useRouter();
  const {
    data
  } = useGetMainRegions();
  const {
    data: districts
  } = useGetRegionDistricts('gorod-tashkent');
  const {
    data: metros
  } = useGetMetros();
  const result = (query?.location as string)?.split("gorod-tashkent-")[1];
  const citiesHref = (url: string) => pathname?.includes("kvartiri") ? query?.currency ? `/gorod-tashkent-${url}/kvartiri?currency=${query?.currency}` : `/gorod-tashkent-${url}/kvartiri` : query?.currency ? `/gorod-tashkent-${url}/novostroyki?currency=${query?.currency}` : `/gorod-tashkent-${url}/novostroyki`;
  const regionsHref = (url: string) => pathname?.includes("kvartiri") ? query?.currency ? `/${url}/kvartiri?currency=${query?.currency}` : `/${url}/kvartiri` : query?.currency ? `/${url}/novostroyki?currency=${query?.currency}` : `/${url}/novostroyki`;
  const metrosHref = (url: string) => pathname?.includes("kvartiri") ? query?.currency ? `/gorod-tashkent/kvartiri/metro-${url}?currency=${query?.currency}` : `/gorod-tashkent/kvartiri/metro-${url}` : query?.currency ? `/gorod-tashkent/novostroyki/metro-${url}?currency=${query?.currency}` : `/gorod-tashkent/novostroyki/metro-${url}`;
  const nearestHref = (id: number) => pathname?.includes("kvartiri") ? query?.currency ? `/gorod-tashkent/kvartiri?nearest_place_ids=${id}?currency=${query?.currency}` : `/gorod-tashkent/kvartiri?nearest_place_ids=${id}` : query?.currency ? `/gorod-tashkent/novostroyki?nearest_place_ids=${id}?currency=${query?.currency}` : `/gorod-tashkent/novostroyki?nearest_place_ids=${id}`;
  const Cities = () => <div className={styles.container_elements} data-sentry-component="Cities" data-sentry-source-file="FooterFilter.tsx">
      {districts?.map(item => {
      return <CustomLink href={citiesHref(item?.url)} key={item?.id}>
            <p className={`${item?.url === result ? "text-[#086ADA]" : "text-[#3A4252]"} text-base font-normal cursor-pointer`}>
              {locale === UZ ? item?.name?.uz?.replace("tumani", "") : item?.name?.ru?.replace("район", "")}
            </p>
          </CustomLink>;
    })}
    </div>;
  const Regions = () => <div className={styles.container_elements} data-sentry-component="Regions" data-sentry-source-file="FooterFilter.tsx">
      {data?.map(item => {
      return <CustomLink href={regionsHref(item?.url)} key={item?.id}>
            <p className={`${item?.url === query?.location ? "text-[#086ADA]" : "text-[#3A4252]"} text-base font-normal cursor-pointer`}>
              {locale === UZ ? item?.name?.uz?.replace("viloyati", "") : item?.name?.ru?.replace("область", "")}
            </p>
          </CustomLink>;
    })}
    </div>;
  const Metros = () => <div className={styles.container_elements} data-sentry-component="Metros" data-sentry-source-file="FooterFilter.tsx">
      {metros?.map(item => {
      return <div className="flex flex-col gap-3" key={item?.id}>
            <span className="text-[#101828] text-base font-semibold">
              {item?.name[locale as keyof typeof item.name]}
            </span>
            {item?.metros?.map(el => <CustomLink href={metrosHref(el?.slug)} key={el?.id}>
                <p className={`${el?.id === Number(query?.["nearest_place_ids"]) ? "text-[#086ADA]" : "text-[#3A4252]"} text-base font-normal cursor-pointer`}>
                  {locale === UZ ? el?.name?.uz : el?.name?.ru}
                </p>
              </CustomLink>)}
          </div>;
    })}
    </div>;
  return <div className={styles.container} data-sentry-component="FooterFilter" data-sentry-source-file="FooterFilter.tsx">
      <div className={styles.container_body}>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri") ? layoutLang[locale || 'ru']["Toshkentdagi_yangi_kvartiralar"] : layoutLang[locale || 'ru']["Toshkentdagi_yangi_uylar"]}
          </h3>
          <Cities data-sentry-element="Cities" data-sentry-source-file="FooterFilter.tsx" />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri") ? layoutLang[locale || 'ru']["Butun_Ozbekiston_boylab_kvartiralar"] : layoutLang[locale || 'ru']["Butun_Ozbekiston_boylab_yangi_uylar"]}
          </h3>
          <Regions data-sentry-element="Regions" data-sentry-source-file="FooterFilter.tsx" />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {layoutLang[locale || 'ru']["Metro yaqinidagi uylar"]}
          </h3>
          <Metros data-sentry-element="Metros" data-sentry-source-file="FooterFilter.tsx" />
        </div>
        <div className="hidden">
          {staticLinks?.map((item, index) => <CustomLink key={index} href={item?.link[locale as keyof typeof item.link]}>{item?.label[locale as keyof typeof item.label]}
            </CustomLink>)}
        </div>
      </div>
      <NonSSRWrapper data-sentry-element="NonSSRWrapper" data-sentry-source-file="FooterFilter.tsx">
        <div className={styles.container_bodyCollapse}>
          <Collapse expandIconPosition="end" bordered={false} defaultActiveKey={["1", "2", "3"]} expandIcon={props => <ArrowIcon stroke="#101828" width={20} height={20} isRotated={!props?.isActive} />} data-sentry-element="Collapse" data-sentry-source-file="FooterFilter.tsx">
            <Panel className="bg-white mt-8" header={<h3 className="text-[#101828] text-xl font-semibold w-[90%]">
                  {pathname?.includes("kvartiri") ? layoutLang[locale || 'ru']["Toshkentdagi_yangi_kvartiralar"] : layoutLang[locale || 'ru']["Toshkentdagi_yangi_uylar"]}
                </h3>} key={"1"} data-sentry-element="Panel" data-sentry-source-file="FooterFilter.tsx">
              <Cities data-sentry-element="Cities" data-sentry-source-file="FooterFilter.tsx" />
            </Panel>
            <Panel className="bg-white mt-8" header={<h3 className="text-[#101828] text-xl font-semibold w-[90%]">
                  {pathname?.includes("kvartiri") ? layoutLang[locale || 'ru']["Butun_Ozbekiston_boylab_kvartiralar"] : layoutLang[locale || 'ru']["Butun_Ozbekiston_boylab_yangi_uylar"]}
                </h3>} key={"2"} data-sentry-element="Panel" data-sentry-source-file="FooterFilter.tsx">
              <Regions data-sentry-element="Regions" data-sentry-source-file="FooterFilter.tsx" />
            </Panel>
            <Panel className="bg-white mb-8 mt-8" header={<h3 className="text-[#101828] text-xl font-semibold w-[93%]">
                  {layoutLang[locale || 'ru']["Metro yaqinidagi uylar"]}
                </h3>} key={"3"} data-sentry-element="Panel" data-sentry-source-file="FooterFilter.tsx">
              <Collapse bordered={false} defaultActiveKey={[String(metros?.[0]?.id)]} accordion={true} className={cx("flex flex-col items-center justify-center gap-3 w-full", styles.metros_collapse)} expandIconPosition="end" expandIcon={props => <ArrowIcon stroke="#101828" width={20} height={20} isRotated={!props?.isActive} />} data-sentry-element="Collapse" data-sentry-source-file="FooterFilter.tsx">
                {metros?.map(metro => <Panel className="bg-[#F2F4F7] rounded-lg py-2 px-3 w-full" key={String(metro?.id)} header={<h3 className="text-[#1D2939] text-xl font-semibold w-[93%] truncate" title={locale === UZ ? metro?.name?.uz : metro?.name?.ru}>
                        {locale === UZ ? metro?.name?.uz : metro?.name?.ru}
                      </h3>}>
                    <div className="flex flex-col gap-[10px]">
                      {metro?.metros?.map(item => <CustomLink href={nearestHref(item?.id)} key={item?.id}>
                          <span className={cx("text-base font-normal text-[#3A4252] truncate", item?.id === Number(query?.["nearest_place_ids"]) ? "text-[#086ADA]" : "text-[#3A4252]")} title={locale === UZ ? item?.name?.uz : item?.name?.ru}>
                            {locale === UZ ? item?.name?.uz : item?.name?.ru}
                          </span>
                        </CustomLink>)}
                    </div>
                  </Panel>)}
              </Collapse>
            </Panel>
          </Collapse>
        </div>
      </NonSSRWrapper>
    </div>;
};
export default FooterFilter;