import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import ConfettiExplosion from 'react-confetti-explosion';
import { cx } from 'utils/cx';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { dailyActiveUserCoinActions } from 'store/reducers/daily-active-user-coin-reducer';
import styles from './index.module.scss';
const BigPrizeModal = () => {
  const [isExploding, setIsExploding] = useState(false);
  const {
    setOpenGetBigPrizeModal,
    setOpenBigPrizeModal
  } = dailyActiveUserCoinActions;
  const dispatch = useAppDispatch();
  const open = useAppSelector(state => state.dailyActiveUserCoinReducer.bigPrizeModal.open);
  const id = useAppSelector(state => state.dailyActiveUserCoinReducer.bigPrizeModal.id);
  const amount = useAppSelector(state => state.dailyActiveUserCoinReducer.bigPrizeModal.amount);
  useEffect(() => {
    if (open) {
      setIsExploding(false); // Reset animation state

      setTimeout(() => {
        setIsExploding(true); // Start animation after 3 seconds
      }, 3000);
      setTimeout(() => {
        dispatch(setOpenBigPrizeModal({
          open: false,
          amount: undefined,
          id: undefined
        }));
        dispatch(setOpenGetBigPrizeModal({
          amount,
          id,
          open: true
        }));
      }, 4500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dispatch, amount, id]);
  return <Modal centered destroyOnClose zIndex={1000003} open={open} footer={null} className='[&_.ant-modal-close]:hidden [&_.ant-modal-content]:shadow-none  [&_.ant-modal-content]:bg-transparent [&_.ant-modal-body]:p-0' data-sentry-element="Modal" data-sentry-component="BigPrizeModal" data-sentry-source-file="index.tsx">
                {!isExploding && <div className={cx('w-full flex justify-center items-center h-full relative rounded-full')}>
                    <div className={cx(' rounded-full absolute w-full h-full z-[-1]', styles.prize_container)} />
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src="https://s3-alpha-sig.figma.com/img/4500/71b4/85edd3212e5f9396e5a523fe96685f47?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=mDe77Fjn-BVaN3hjiqrgTS21T3h2KS5jCnNIjuH-Wj3b3Bdxw4kA55JQ2icvFIHGt0KjEELNWvSZOiimKCj06ndy23T83-0ShzIW6aOGG7xItBbS0KNspaVfV5wQOdQpyK~74UK-GObg1ivIeU651aALi8pqbcdxaaVh48LZx-1M1gmF~NBsDwDr8C2Yy92LRPVenM~RpELNW6BRW9HPylUCN-wGHBWB9xM2TznEqxyCA8mLmv5xoVMoc-vFpQ8HXQyaNU5~DbEg9tP6Q0i31iAeRRe0N3fH4Lh5daWrDFaMcgzqerLUEeOJFvOKZayp20GUVTvn54~Vmyt6U4p0Ow__" alt="Winning Animation" className="w-full h-full" />
                </div>}
            <div className='w-full h-full flex justify-center items-center'>
                {isExploding && <ConfettiExplosion zIndex={1000003} duration={4500} />}
            </div>
        </Modal>;
};
export default BigPrizeModal;