/* eslint-disable no-console */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDtEloM6yCEpn9xtXoWnKh64p9c9yiwq0U',
  authDomain: 'uysotuz-marketpleys.firebaseapp.com',
  projectId: 'uysotuz-marketpleys',
  storageBucket: 'uysotuz-marketpleys.appspot.com',
  messagingSenderId: '763730302416',
  appId: '1:763730302416:web:ed5a3f62999eb1d032d5d5',
  measurementId: 'G-WN2290V66H'
};

let appInstance = null;
let messagingInstance: Messaging | null = null;
let isInitialized = false;

const initializeFirebase = () => {
  if (typeof window === 'undefined') return null;
  if (isInitialized) {
    return messagingInstance;
  }

  appInstance = initializeApp(firebaseConfig);
  messagingInstance = getMessaging(appInstance);
  isInitialized = true;

  // Remove manual registration; Firebase will handle it via getToken
  return messagingInstance;
};

export const sendRequest = async (setToken: (token: string) => void, setPermission: (permission: string) => void) => {
  const messaging = initializeFirebase();
  if (!messaging) return;

  const existingToken = localStorage.getItem('deviceToken');
  if (existingToken && Notification.permission === 'granted') {
    setToken(existingToken);
    setPermission('granted');
    return;
  }

  try {
    const permission = await Notification.requestPermission();
    setPermission(permission);
    if (permission !== 'granted') return;

    // Let Firebase auto-register the service worker
    const currentToken = await getToken(messaging, {
      vapidKey: 'BK-kfcTSdyw1xhXe8ApXBN5lwsDXgvzxwz_vBKQXMxtHQVubqfn_dqi5sTAe1xYywx8RHPl7iywkVqkEI_HLStk'
    });

    if (currentToken) {
      setToken(currentToken);
      localStorage.setItem('deviceToken', currentToken);
    } else {
      console.log('Failed to generate the registration token.');
    }
  } catch (error) {
    console.error('An error occurred when requesting the token:', error);
  }
};
