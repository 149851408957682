import React from 'react';
import ClassItem from 'common/filter-desktop/filter-item/class-item';
import DeadlineItem from 'common/filter-desktop/filter-item/deadline-item';
import DelayItem from 'common/filter-desktop/filter-item/delay-item';
import PaymentItem from 'common/filter-desktop/filter-item/payment-item';
import BuildItem from 'common/filter-desktop/filter-item/build-item';
import DistanceMetroItem from 'common/filter-desktop/filter-item/distance-metro-item';
import DiscountItem from 'common/filter-desktop/filter-item/discount-item';
interface IProps {
  setDiscount: React.Dispatch<React.SetStateAction<{
    name: string;
    value: string;
  }[] | undefined>>;
  discount: {
    name: string;
    value: string;
  }[] | undefined;
}
const FilterDesktopBottom: React.FC<IProps> = ({
  setDiscount,
  discount
}) => {
  return <>
      <DelayItem data-sentry-element="DelayItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
      <BuildItem data-sentry-element="BuildItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
      <DistanceMetroItem data-sentry-element="DistanceMetroItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
      <ClassItem data-sentry-element="ClassItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
      <DeadlineItem data-sentry-element="DeadlineItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
      <PaymentItem data-sentry-element="PaymentItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
      <DiscountItem discount={discount} setDiscount={setDiscount} data-sentry-element="DiscountItem" data-sentry-source-file="filter-desktop-bottom.tsx" />
    </>;
};
export default FilterDesktopBottom;