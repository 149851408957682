export const lengthValidator = (
  length: number,
  handleSuccess?: () => void,
  handleError?: () => void
) => ({
  validator(_: unknown, value: string) {
    const cleanedValue = value?.replace(/[+()_-\s]/g, "") || "";

    if (cleanedValue.length !== length) {
      handleError && handleError();
      return Promise.reject(new Error(""));
    }

    handleSuccess && handleSuccess();
    return Promise.resolve();
  },
});
