import React from "react";
import { useRouter } from "next/router";
import { useAppDispatch } from "hooks/reduxHooks";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import FooterMapIcon from "assets/icons/FooterMapIcon";
import ComplexIcon from "assets/icons/ComplexIcon";
import ApartmentIcon from "assets/icons/ApartmentIcon";
import ArticleIcon from "assets/icons/ArticleIcon";
import CustomLink from "common/custom-link";
import Video2Icon from "assets/icons/video2-icon";
const MobileFooterMenu = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    pathname,
    query
  } = useRouter();
  const {
    setChangeFilterType
  } = favouritesReducerActions;
  const menuItems = [{
    text: {
      uz: "Yangi bino",
      ru: "Новостройки"
    },
    children: <ComplexIcon fill={pathname?.includes("novostroyki") ? "#00A389" : "#475467"} />,
    link: `/${DEFAULT_LOCATION}/novostroyki`,
    url: "novostroyki"
  }, {
    text: {
      uz: "Xonadon",
      ru: "Квартиры"
    },
    children: <ApartmentIcon fill={pathname?.includes("kvartiri") ? "#00A389" : "#475467"} />,
    link: `/${DEFAULT_LOCATION}/kvartiri`,
    url: "kvartiri"
  }, {
    text: {
      uz: "Reels",
      ru: "Reels"
    },
    children: <Video2Icon width={20} height={20} stroke={pathname?.includes("reels") ? "#00A389" : "#475467"} />,
    link: `/reels`,
    url: "reels"
  }, {
    text: {
      uz: "Jurnal",
      ru: "Журнал"
    },
    children: <ArticleIcon fill={pathname?.includes("jurnal") ? "#00A389" : "#475467"} width={20} height={20} />,
    link: "/jurnal",
    url: "jurnal"
  }, {
    text: {
      uz: "Xarita",
      ru: "Карта"
    },
    children: <FooterMapIcon fill={pathname?.includes("karta") ? "#00A389" : "#475467"} />,
    link: "/karta?type=complex",
    url: "karta"
  }];
  const active = "text-[#00A389] flex items-center justify-center rounded-2xl";
  return <div className="fixed bottom-0 left-0 bg-white z-[101] w-full sm:hidden flex items-center justify-between border border-solid border-[#EAECF0] px-4 h-[55px]" data-sentry-component="MobileFooterMenu" data-sentry-source-file="MobileFooterMenu.tsx">
      {menuItems?.map(item => {
      return <CustomLink href={query?.currency ? `${item?.link}?currency=${query?.currency}` : item?.link} key={item?.text[(locale || 'ru') as keyof typeof item.text]} className={"flex flex-col items-center gap-1"} onClick={() => item?.url && dispatch(setChangeFilterType(item?.url === "novostroyki" ? "complex" : "apartment"))}>
            <div className={pathname.includes(item?.url) ? active : "flex items-center justify-center"}>
              {item?.children}
            </div>
            <p className={`${pathname.includes(item?.url) ? "text-[#00A389]" : "text-[#667085]"}  text-xs font-medium`}>
              {item?.text[(locale || 'ru') as keyof typeof item.text]}
            </p>
          </CustomLink>;
    })}
    </div>;
};
export default MobileFooterMenu;