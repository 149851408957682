import { staticUrls } from "utils/constants/staticUrls";

export
  function findMatchingKey(
    filters: any
  ): string | null {
  for (const key in staticUrls) {
    const conditions = staticUrls[key];

    // `filters` faqat `conditions` dagi kalitlardan iborat ekanligini tekshiramiz
    const filterKeys = Object.keys(filters);
    const conditionKeys = Object.keys(conditions);

    if (!filterKeys.every((k) => conditionKeys.includes(k))) {
      continue; // Agar ortiqcha kalit bo'lsa, o'tkazib yuboramiz
    }

    // Barcha shartlarni tekshiramiz
    let isMatch = true;
    for (const conditionKey in conditions) {
      if (
        JSON.stringify(conditions[conditionKey as keyof typeof conditions]) !==
        JSON.stringify(filters[conditionKey as keyof typeof filters])
      ) {
        isMatch = false;
        break;
      }
    }

    if (isMatch) {
      return key;
    }
  }
  return null;
}