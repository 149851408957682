import React from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import { DEFAULT_LOCATION } from 'utils/constants/defaultLocation';
import { rootPaths } from 'utils/constants/rootPaths';
import { breadCrumbsUrls } from 'utils/constants/staticUrls';
import { capitalizeWords } from 'utils/helpers/capitalize-words';
import { isValidUrl } from 'utils/helpers/isValidUrl';
import { layoutLang } from 'utils/language/layout';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import CustomLink from 'common/custom-link';
const CommonBreadCrumbs = () => {
  const {
    query,
    locale,
    pathname
  } = useRouter();
  const lang = layoutLang[locale || 'ru'];
  const isComplex = pathname.includes('novostroyki') ? 'novostroyki' : 'kvartiri';
  const {
    breadCrumbsLocation,
    apartmentValue,
    mortgageCalculator
  } = useAppSelector(state => state.favouritesReducer);
  const activeSegment = mortgageCalculator.activeSegment;
  const staticQueryKeys = breadCrumbsUrls[(query?.id || query?.apartmentId) as keyof typeof breadCrumbsUrls];
  const calcTitle = {
    1: lang['Ипотечный калькулятор'],
    2: lang['muddatli_tolov_kalkulyatori'],
    3: lang['tolov_kalkulyatori']
  };
  const breadcrumbs = [];
  breadcrumbs.push({
    href: rootPaths.INDEX,
    label: 'Uysot'
  });
  if (pathname.includes('/mortgage-calculator')) {
    breadcrumbs.push({
      label: calcTitle[activeSegment as keyof typeof calcTitle]
    });
  } else if (pathname.includes('/referral')) {
    breadcrumbs.push({
      label: capitalizeWords(layoutLang[locale || 'ru']['Referral'])
    });
  } else if (pathname.includes('/bonuses')) {
    breadcrumbs.push({
      label: capitalizeWords(layoutLang[locale || 'ru']['Chegirma va bonuslar'])
    });
  } else if (pathname.includes('/favourites')) {
    breadcrumbs.push({
      label: capitalizeWords(layoutLang[locale || 'ru']['Избранное'])
    });
  } else {
    breadcrumbs.push({
      href: `/${DEFAULT_LOCATION}${isComplex === 'novostroyki' ? rootPaths.RESIDENTIAL_COMPLEXES : rootPaths.APARTMENTS}`,
      label: capitalizeWords(lang[isComplex])
    });
    if (query?.location !== DEFAULT_LOCATION) {
      breadcrumbs.push({
        href: `/${query?.location}${isComplex === 'novostroyki' ? rootPaths.RESIDENTIAL_COMPLEXES : rootPaths.APARTMENTS}`,
        label: breadCrumbsLocation
      });
    }
    const idOrApartmentId = query?.id || query?.apartmentId;
    if (idOrApartmentId) {
      const isValid = isValidUrl(idOrApartmentId as string);
      breadcrumbs.push({
        href: isValid ? `/${query?.location}${isComplex === 'novostroyki' ? rootPaths.RESIDENTIAL_COMPLEXES : rootPaths.APARTMENTS}/${idOrApartmentId}` : undefined,
        label: isValid ? staticQueryKeys[locale as keyof typeof staticQueryKeys] || idOrApartmentId : apartmentValue || idOrApartmentId
      });
    }
  }
  const staticPaths = ['/[location]/novostroyki', '/[location]/kvartiri', '/[location]/kvartiri/[apartmentId]', '/[location]/novostroyki/[id]', '/mortgage-calculator', '/referral', '/bonuses', '/favourites'];
  const apartmentPath = '/[location]/kvartiri/[apartmentId]';
  const novostroykiPath = '/[location]/novostroyki/[id]';
  const isStaticPath = staticPaths.includes(pathname);
  const isValidApartmentId = query?.apartmentId ? isValidUrl(query.apartmentId as string) && pathname === apartmentPath : true;
  const isValidNovostroykiId = query?.id ? isValidUrl(query.id as string) && pathname === novostroykiPath : true;
  if (!isStaticPath || !isValidApartmentId || !isValidNovostroykiId) {
    return null;
  } else return <div className={`mx-auto flex w-full max-w-[1230px] flex-wrap items-center gap-1 bg-[#F2F4F6] px-3 pt-2.5 md:px-0 ${pathname.includes('mortgage-calculator') ? 'md:pt-3' : 'md:pt-3'} pb-2.5 md:pb-3`}>
        {breadcrumbs.map((item, index) => {
      const isLast = index === breadcrumbs.length - 1;
      return <div key={index} className="flex items-center gap-1.5">
              {item.href ? <CustomLink href={item.href} className={`w-max text-xs ${isLast ? 'text-[#00A389]' : 'text-[#98A2B3]'}`}>
                  {item.label}
                </CustomLink> : <span className={`w-max text-xs ${isLast ? 'text-[#00A389]' : 'text-[#98A2B3]'}`}>{item.label}</span>}
              {index !== breadcrumbs.length - 1 && <ArrowRightIcon stroke="#98A2B3" width={12} height={12} />}
            </div>;
    })}
      </div>;
};
export default CommonBreadCrumbs;