import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { localeReducerActions } from "store/reducers/localeReducer";
import { userReducerActions } from "store/reducers/userReducer";
import { USD, UZS } from "utils/constants/locales";
import { layoutLang } from "utils/language/layout";
const MenuCurrency = () => {
  const {
    locale
  } = useRouter();
  const dispatch = useAppDispatch();
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const openCurrency = useAppSelector(state => state.userReducer?.openCurrency);
  const {
    changeUsdValue,
    changeCurrency
  } = localeReducerActions;
  const {
    setOpenCurrency
  } = userReducerActions;
  const handleClose = () => {
    dispatch(setOpenCurrency(false));
  };
  useEffect(() => {
    fetch(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/`).then(res => res.json()).then((res: any[]) => {
      dispatch(changeUsdValue(res?.find(item => item?.Ccy === "USD")?.Rate));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className={`${openCurrency ? "bg-[#00000040]" : "bg-transparent"} fixed bottom-0 left-0 h-full w-full ${openCurrency ? "z-[10000000]" : "-z-10"} transition-all duration-500`} data-sentry-component="MenuCurrency" data-sentry-source-file="MenuCurrency.tsx">
      <div className="absolute left-0 top-0 z-10 h-full w-full" onClick={handleClose} />
      <div className={`fixed z-20 w-full rounded-t-2xl bg-white ${openCurrency ? "bottom-0" : "bottom-[-100%]"} transition-all duration-500`}>
        <div className={`z-2 absolute left-0 flex w-full flex-col gap-3 rounded-t-2xl bg-white px-4 py-7 ${openCurrency ? "bottom-0" : "bottom-[-100%]"} transition-all duration-500`}>
          <span className="text-xl font-medium text-[#101828]">{layoutLang[locale || 'ru']["Valyuta"]}</span>
          <div className="flex items-center gap-3">
            <div className={`flex h-11 flex-1 cursor-pointer items-center justify-center rounded-xl border border-solid text-base font-medium text-[#1D2939] transition-all duration-500 hover:border-[#2BCD93] hover:bg-[#E9FAF4] hover:text-[#2BCD93] ${currency === "usd" ? "bg-[#E9FAF4]" : "bg-[#F2F4F7]"} ${currency === "usd" ? "border-[#2BCD93]" : "border-transparent"} ${currency === "usd" ? "text-[#2BCD93]" : "text-[#1D2939]"}`} onClick={() => {
            dispatch(changeCurrency(USD));
            dispatch(setOpenCurrency(false));
          }}>
              USD
            </div>
            <div className={`flex h-11 flex-1 cursor-pointer items-center justify-center rounded-xl border border-solid text-base font-medium text-[#1D2939] transition-all duration-500 hover:border-[#2BCD93] hover:bg-[#E9FAF4] hover:text-[#2BCD93] ${currency === "uzs" ? "bg-[#E9FAF4]" : "bg-[#F2F4F7]"} ${currency === "uzs" ? "border-[#2BCD93]" : "border-transparent"} ${currency === "uzs" ? "text-[#2BCD93]" : "text-[#1D2939]"}`} onClick={() => {
            dispatch(changeCurrency(UZS));
            dispatch(setOpenCurrency(false));
          }}>
              UZS
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default MenuCurrency;