import React, { useEffect } from 'react';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { layoutLang } from 'utils/language/layout';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import { useGetNewPaymentMethods } from 'pages/apartments/services/queries';
const PaymentItem = () => {
  const {
    data
  } = useGetNewPaymentMethods();
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const payment_method_ids = useAppSelector(state => state.favouritesReducer.filter.payment_method_ids);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    setPayments
  } = favouritesReducerActions;
  const handleAdd = (id: string) => {
    if (payment_method_ids.includes(id)) {
      const newPaymentMetIds = payment_method_ids?.filter(item => item !== id);
      dispatch(setPayments(newPaymentMetIds));
    } else {
      dispatch(setPayments([...payment_method_ids, id]));
    }
  };
  useEffect(() => {
    if (query?.payment_method_ids) {
      if (Array.isArray(query?.payment_method_ids)) {
        dispatch(setPayments(query?.payment_method_ids));
      } else {
        dispatch(setPayments([query?.payment_method_ids]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="PaymentItem" data-sentry-source-file="payment-item.tsx">
      {layoutLang[locale || 'ru']["tolov_turi"]}
      <div className="flex items-center gap-2">
        {data?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 
          ${payment_method_ids?.includes(String(item?.id)) ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => handleAdd(String(item?.id))}>
            {item?.name[locale as keyof typeof item.name]}
          </div>)}
      </div>
    </div>;
};
export default PaymentItem;