import ExpoLogoIcon from "assets/icons/ExpoLogoIcon";
import FacebookMiniIcon from "assets/icons/FacebookMiniIcon";
import InstagramMiniIcon from "assets/icons/InstagramMiniIcon";
import TelegramMiniIcon from "assets/icons/TelegramMiniIcon";
import React from "react";
const Footer = () => {
  return <div className="bg-[#1B815F] py-[40px] flex justify-center text-white" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="max-width flex justify-between md:flex-row flex-col w-full items-center md:items-start md:gap-0 gap-[32px]">
        <div className="md:block flex flex-col items-center">
          <ExpoLogoIcon data-sentry-element="ExpoLogoIcon" data-sentry-source-file="Footer.tsx" />
          <p className="uppercase mt-[48px] text-[14px] leading-[20.75px] tracking-[4px] text-center hidden md:block">
            uysot® barcha huquqlar himoyalangan
          </p>
        </div>
        <div className="flex md:gap-[78px] gap-[32px] md:flex-row flex-col items-center md:items-start">
          <div className="flex flex-col gap-2">
            <a href={"tel:+998781136350"} className="text-[24px] font-[300] leading-[35.57px] tracking-[3px]">
              +998 (78) 113 63 50
            </a>
            <a href={"tel:+998557050705"} className="text-[24px] font-[300] leading-[35.57px] tracking-[3px]">
                +998 (55) 705 07 05
              </a>
            </div>
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=admin@uysot.uz" target="_blank" className="text-[24px] font-[300] leading-[35.57px] tracking-[3px]">
            admin@uysot.uz
          </a>
          <div className="flex flex-col items-center">
            <div className="flex gap-[32px]">
              <a href={"https://t.me/uysot_uz"} target="_blank">
                <TelegramMiniIcon data-sentry-element="TelegramMiniIcon" data-sentry-source-file="Footer.tsx" />
              </a>
              <a href={"https://www.instagram.com/uysot_uz/"} target="_blank">
                <InstagramMiniIcon data-sentry-element="InstagramMiniIcon" data-sentry-source-file="Footer.tsx" />
              </a>
              <a href={"https://www.facebook.com/uysot.uz/"} target="_blank">
                <FacebookMiniIcon data-sentry-element="FacebookMiniIcon" data-sentry-source-file="Footer.tsx" />
              </a>
            </div>
            <p className="uppercase mt-[48px] text-[14px] leading-[20.75px] font-[400] tracking-[4px]">
              uysot.UZ • 2025
            </p>
            <p className="uppercase mt-[48px] text-[14px] leading-[20.75px] tracking-[4px] text-center mobile_only">
              uysot® barcha huquqlar himoyalangan
            </p>
          </div>
        </div>
      </div>
    </div>;
};
export default Footer;