import React from 'react';
import { Drawer } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import RegionCity from 'pages/main-layout/components/region-city/RegionCity';
import styles from '../filterItems.module.scss';
const RegionItem = () => {
  const dispatch = useAppDispatch();
  const openRegionDrawer = useAppSelector(state => state.favouritesReducer.openRegionDrawer);
  const {
    setOpenRegionDrawer
  } = favouritesReducerActions;
  const handleClose = () => {
    dispatch(setOpenRegionDrawer(false));
  };
  return <Drawer className={styles.regionItem} open={openRegionDrawer} placement='bottom' onClose={handleClose} contentWrapperStyle={{
    height: '100dvh'
  }} zIndex={10000000} data-sentry-element="Drawer" data-sentry-component="RegionItem" data-sentry-source-file="region-item.tsx">
      <RegionCity data-sentry-element="RegionCity" data-sentry-source-file="region-item.tsx" />
    </Drawer>;
};
export default RegionItem;