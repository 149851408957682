import { Grid, Tooltip } from "antd";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { layoutLang } from "utils/language/layout";
import { OneConditionCoin } from "pages/main-layout/utils/models/uysotCoinModel";
import { dailyActiveUserCoinActions } from "store/reducers/daily-active-user-coin-reducer";
import AnotherCheckIcon from "assets/icons/another-check-icon";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { userReducerActions } from "store/reducers/userReducer";
import { cx } from "utils/cx";
import { UZ } from "utils/constants/locales";
import { formatTime } from "utils/helpers/format-time";
import styles from './index.module.scss';
dayjs.extend(durationPlugin);
interface IProps {
  data: OneConditionCoin[] | undefined;
  coinCounts: number | undefined;
}
const UysotCoinContent: React.FC<IProps> = ({
  coinCounts,
  data
}) => {
  const {
    locale
  } = useRouter();
  const {
    setOpen
  } = dailyActiveUserCoinActions;
  const dispatch = useAppDispatch();
  const {
    md
  } = Grid.useBreakpoint();
  const handlOpenDailyUserCoinModal = () => {
    dispatch(setOpen({
      open: true,
      description: data?.[2]?.description as never,
      title: data?.[2]?.name as never
    }));
  };
  const isAuth = useAppSelector(state => state.userReducer.isAuth);
  const {
    setVisibleLoginModal
  } = userReducerActions;
  const [timeLeft, setTimeLeft] = useState(0);
  const start_at = data?.[2]?.info?.start_at;
  useEffect(() => {
    if (start_at) {
      setTimeLeft(Math.max(Number(start_at) - dayjs().unix(), 0));
    }
  }, [start_at]);
  useEffect(() => {
    if (!timeLeft || timeLeft <= 0) return;
    const interval = setInterval(() => {
      setTimeLeft(prev => Math.max(prev - 1, 0));
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft]);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, type: string) => {
    if (!isAuth && type === "invited_friends") {
      e.preventDefault();
      dispatch(setVisibleLoginModal(true));
    }
    if (type === 'visited_the_site') {
      e.preventDefault();
      handlOpenDailyUserCoinModal();
    }
  };
  const showTaken = () => {
    if (!start_at || start_at && start_at <= dayjs().unix()) {
      return <span className="text-[#98A2B3]"> • {locale === 'uz' ? 'Mavjud' : 'Доступно'}</span>;
    }
    return null;
  };
  return <div className="flex flex-col w-full items-center" data-sentry-component="UysotCoinContent" data-sentry-source-file="uysot-coin-content.tsx">
      <div className="flex items-center gap-3">
        <span className="text-[#475467] font-bold text-[56px] leading-[73px]">
          {coinCounts?.toLocaleString('ru')}
        </span>
        <div className={cx("w-10 h-[44px]", styles.coin_container)} />
      </div>
      <div className="w-full flex flex-col gap-3 mt-8">
        {data?.map((item, index) => <Tooltip overlay={item?.description[locale as keyof typeof item.description]} key={item?.id} trigger={!md && item?.type === 'visited_the_site' ? [] : "hover"}>
            <a href={(item?.type === "invited_friends" || item?.type === 'visited_the_site') && !isAuth ? '' : item?.link} target={(item?.type === "invited_friends" || item?.type === 'visited_the_site') && !isAuth ? '_self' : md ? "_blank" : "_self"} className="flex items-center justify-between bg-[#F2F4F7] p-3 pr-3.5 rounded-2xl cursor-pointer" onClick={e => handleClick(e, item?.type)}>
              <div className={`flex items-center gap-2 ${item?.type !== "registered" ? 'w-full' : 'max-w-[76%]'}`}>
                {item?.done ? <div>
                    <AnotherCheckIcon />
                  </div> : <div className="w-6 h-6 flex items-center justify-center rounded-full bg-white text-[#0DC683] text-sm font-medium">
                    {index + 1}
                  </div>}
                <div className="flex flex-col flex-1">
                  <span className="text-[#475467] font-semibold md:text-base text-[13px]">
                    {item?.name[locale as keyof typeof item.name]}
                  </span>
                  {index === 1 && (locale === 'uz' ? <div className="text-[11px] md:text-[13px] font-medium">
                      <span className="text-[#98A2B3]">Har bir do‘st </span>
                      <span className="text-[#00A389]">+{item?.amount} U</span>
                      <span className="text-[#1D2939]">Coin</span>
                      <span className="text-[#98A2B3] ml-2"> • Do‘stlar: {item?.referral_users_count} ta</span>
                    </div> : <div className="text-[11px] md:text-[13px] font-medium">
                      <span className="text-[#00A389]">+{item?.amount} U</span>
                      <span className="text-[#1D2939]">Coin</span>
                      <span className="text-[#98A2B3]"> за каждого друга </span>
                      <span className="text-[#98A2B3] ml-2"> • Друзья: {item?.referral_users_count}</span>
                    </div>)}
                  {index === 2 && (locale === 'uz' ? <div className="text-[11px] md:text-[13px] font-medium">
                      <span className="text-[#98A2B3]">Keyingi mukofot:</span>
                      <span className="text-[#00A389]"> +{item?.info?.next_step_amount} U</span>
                      <span className="text-[#1D2939]">Coin</span>
                      {item?.info?.start_at && <span className="text-[#98A2B3] ml-2"> •  <span className="text-[#F04438]">{formatTime(timeLeft, locale as never)} keyin</span></span>}
                      {showTaken()}
                    </div> : <div className="text-[11px] md:text-[13px] font-medium">
                      {item?.info?.start_at ? <>
                          {timeLeft > 0 ? <span className="text-[#98A2B3]"> Следующий награда: </span> : null}
                        </> : null}
                      <span className="text-[#00A389]">+{item?.info?.next_step_amount} U</span>
                      <span className="text-[#1D2939]">Coin</span>
                      {item?.info?.start_at ? <>
                          {timeLeft > 0 ? <span className="text-[#98A2B3] ml-2"> • Через  <span className="text-[#F04438]">{formatTime(timeLeft, locale as never)}</span></span> : null}
                        </> : null}
                      {showTaken()}
                    </div>)}
                </div>
              </div>
              <div className={`flex items-center justify-end gap-[5px] ${item?.count_coin > 0 ? 'w-[24%]' : 'w-max'}`}>
                {item?.type === "invited_friends" ? item?.count_coin >= 0 && <span className="text-[#00A389] font-bold md:text-xl text-[17px] flex">
                    <p>+</p><p>{item?.count_coin}</p>
                  </span> : <span className="text-[#00A389] font-bold md:text-xl text-[17px] flex">
                  <p>+</p> <p>{item?.count_coin || 0}</p>
                </span>}
                <div className={cx("w-[26px] h-[28px]", styles.coin_container)} />
              </div>
            </a>
          </Tooltip>)}
      </div>
      <a href={locale === UZ ? "https://docs.google.com/document/d/1ze17hWnxBKRvzkrnYFnondKDqV90XcTgLbSZ177FSys/edit?usp=sharing" : "https://docs.google.com/document/d/1ze17hWnxBKRvzkrnYFnondKDqV90XcTgLbSZ177FSys/edit?tab=t.angzvfulcjv5"} target="_blank" className="w-full text-start mt-3 underline text-[#98A2B3] text-[13px] font-medium cursor-pointer">
        {layoutLang[locale || 'ru']["foydalanish_shartlari"]}
      </a>
    </div>;
};
export default UysotCoinContent;