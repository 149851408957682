import React from 'react';
import { Button, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { dailyActiveUserCoinActions } from 'store/reducers/daily-active-user-coin-reducer';
import { useRouter } from 'next/router';
import { layoutLang } from 'utils/language/layout';
import ShimmerImage from 'common/shimmer-image';
import coinImg from '../../../../assets/images/get-big-prize.webp';
const GetBigPrizeModal = () => {
  const dispatch = useAppDispatch();
  const {
    setOpen,
    setOpenGetBigPrizeModal
  } = dailyActiveUserCoinActions;
  const {
    locale
  } = useRouter();
  const open = useAppSelector(state => state.dailyActiveUserCoinReducer.getBigPrizeModalOpen.open);
  const amount = useAppSelector(state => state.dailyActiveUserCoinReducer.getBigPrizeModalOpen.amount);
  const handleClose = () => {
    dispatch(setOpen({
      open: false,
      description: null,
      title: null
    }));
    dispatch(setOpenGetBigPrizeModal({
      id: undefined,
      open: false,
      amount: undefined
    }));
  };
  return <Modal centered open={open} className='[&_.ant-modal-content]:rounded-[23.28px] [&_.ant-modal-close]:hidden !w-[492px]' footer={null} onCancel={handleClose} zIndex={1000004} data-sentry-element="Modal" data-sentry-component="GetBigPrizeModal" data-sentry-source-file="index.tsx">
            <div className='flex flex-col gap gap-4 justify-center items-center'>
                <div className='w-[125px] h-[125px]'>
                    <ShimmerImage src={coinImg} alt='get-big-prize-image' data-sentry-element="ShimmerImage" data-sentry-source-file="index.tsx" />
                </div>
                <span className='text-2xl font-bold text-[#344054]'>{amount} UCoin</span>
                <span className='text-sm font-medium text-[#909090] text-center line-clamp-2 w-[80%]'>{layoutLang[locale || 'ru']['successDilaogText']}</span>
                <Button type='primary' className='h-[49px] rounded-xl w-full' onClick={handleClose} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                    {layoutLang[locale || 'ru']["receiveCoin"]}
                </Button>
            </div>
        </Modal>;
};
export default GetBigPrizeModal;