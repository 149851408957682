import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { wrapper } from '../src/store/store';
import { colors } from '../src/utils/constants/colors';
import { Provider } from 'react-redux';
import { Expo } from '../src/app';
import Head from 'next/head';
import Script from 'next/script';
import ReactGA from 'react-ga4';
import CallBackModal from '../src/pages/app/components/call-back-modal/CallBackModal';
import MainLayout from '../src/pages/main-layout/Index';
import Quiz from 'app/quiz/Quiz';
import AppWrapper from '../src/common/app-wrapper/AppWrapper';
import type { AppProps } from 'next/app';
import * as process from 'process';
import 'antd/dist/antd.variable.min.css';
import 'swiper/css';
import '../src/styles/Common.scss';
import '../src/styles/Main.scss';
type pageProps = {
  pageProps: {
    messages: AbstractIntlMessages | undefined;
    dehydratedState: DehydratedState;
  };
};
ConfigProvider.config({
  theme: {
    primaryColor: colors.PRIMARY_COLOR
  }
});
ReactGA.initialize('G-16JG6T4477');
function MyApp({
  Component,
  pageProps,
  ...rest
}: AppProps & pageProps) {
  const {
    store
  } = wrapper.useWrappedStore(rest);
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient());
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // @ts-ignore
      import('react-facebook-pixel').then(x => x.default).then(ReactPixel => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string);
        ReactPixel.pageView();
        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
    }
  }, [router.events]);
  const isPolicyPage = router.pathname === '/policy' || router?.pathname === '/referral' || router?.pathname === '/quiz' || router?.pathname === '/favourites' || router?.pathname === '/bonuses';

  // just a comment

  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <meta charSet="UTF-8" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="viewport" content="width=device-width, initial-scale=1" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <meta name="facebook-domain-verification" content="y55ne5u8vg73mp2132esibsvq2xkq5" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <meta name="google-site-verification" content="Yr7IJZKT7hINItRFnXtinouEzcBB5UOmwfwS4_Br3e8" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        {isPolicyPage && <meta name="robots" content="noindex, follow" />}
        <meta name="yandex-verification" content="7dc0f07b55c8f23d" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
        {router.pathname?.includes('expo') && <>
            <title>UYSOT | EXPO</title>
          </>}
      </Head>
      <NextIntlClientProvider messages={pageProps?.messages} locale={router.locale || 'ru'} timeZone="Asia/Tashkent" data-sentry-element="NextIntlClientProvider" data-sentry-source-file="_app.tsx">
        <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
          <Hydrate state={pageProps.dehydratedState} data-sentry-element="Hydrate" data-sentry-source-file="_app.tsx">
            {router.pathname?.includes('expo') ? <Expo>
                <Component {...pageProps} />
              </Expo> : router?.pathname?.includes('quiz') ? <Provider store={store}>
                <Quiz />
                <CallBackModal />
              </Provider> : <Provider store={store}>
                <MainLayout>
                  <Component {...pageProps} />
                </MainLayout>
                <SpeedInsights />
                <CallBackModal />
                <ReactQueryDevtools initialIsOpen={false} />
                <AppWrapper />
              </Provider>}
          </Hydrate>
        </QueryClientProvider>
      </NextIntlClientProvider>
      <Script async defer id="google-analytics" strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-10943131584');
        `}
      </Script>
      {process.env.NODE_ENV === 'production' && <>
          <Script async defer strategy="lazyOnload" id="yandex-metrics" type="text/javascript">
            {`
              (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();
              for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
              k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

              ym(92795399, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true,
                ecommerce:"dataLayer"
              });
            `}
          </Script>
        </>}
    </>;
}
export default MyApp;