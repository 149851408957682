import queryString from "query-string"

export const endpoints = {
  CALL_BACK: "/lead/create",
  API_BANNER_VIEW: (query?: {
    page_type?: string
    uuid?: string
    lang?: string
  }) => `/advertisement/view?${queryString.stringify(query || {})}`,
  API_BANNER_VIEW_UUID: (uuid?: string) => `/advertisement/view/${uuid}`,
  DAILY_ACTIVE_USER_COINS_VIEW:"/daily-coin-condition/view",
  DAILY_ACTIVE_USER_COINS_ACTIVATE:'/daily-coin-condition/activate'
}
