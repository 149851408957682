export const rootPaths = {
  INDEX: "/",
  MORTGAGE_CALCULATOR: "/mortgage-calculator",
  RESIDENTIAL_COMPLEXES: "/novostroyki",
  APARTMENTS: "/kvartiri",
  MAGAZINES: "/jurnal",
  BUILDERS: "/zastroyshiki",
  FAVOURITES: "/favourites",
  MAP: "/karta",
  FAQ: "/faq",
  REELS: "/reels",
}
