import React from 'react';
import { Drawer, Grid, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setActiveIndex, setOpenProBrandModal } from 'store/reducers/pro-brand-reducer';
import { layoutLang } from 'utils/language/layout';
import { useRouter } from 'next/router';
import DesktopContent from './desktop-content';
import MobileContent from './mobile-content';
import CustomLink from 'common/custom-link';
const ProBrandModal = () => {
  const {
    md
  } = Grid.useBreakpoint();
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const open = useAppSelector(state => state.proBrandReducer.open);
  const handleClose = () => {
    dispatch(setOpenProBrandModal(false));
    dispatch(setActiveIndex(0));
  };
  const footer = <CustomLink href="https://t.me/uysot_manager" target="_blank" rel="noopener noreferrer" className='w-full hover:text-white  rounded-xl py-4 px-7 border border-solid border-[var(--lightest-blue-mist)] flex justify-center items-center text-white bg-gradient-to-r from-[#578CFF] to-[#BA78E2]'>
            {layoutLang[locale || 'ru']["E‘lon joylashtirish"]}
        </CustomLink>;
  return <>
    {md ? <Modal centered open={open} closeIcon={null} className='[&_.ant-modal.header]:!hidden [&_.ant-modal-content]:bg-transparent [&_.ant-modal-footer]:!hidden  [&_.ant-modal-body]:!p-0 !w-[22.875rem] md:!w-[62.375rem] [&_.ant-modal-close-x]:!hidden' onCancel={handleClose}>
        <DesktopContent />
    </Modal> : <Drawer open={open} placement='bottom' closeIcon={null} footer={footer} className='[&_.ant-drawer-body]:!p-0 [&_.ant-drawer-header]:!hidden [&_.ant-drawer-footer]:!border-none [&_.ant-drawer-content-wrapper]:!h-auto [&_.ant-drawer-content]:rounded-t-xl' onClose={handleClose}>
            <MobileContent />
    </Drawer>}
    </>;
};
export default ProBrandModal;