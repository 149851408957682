import { useEffect, useState } from "react";
import { phoneNumberFixer } from "../../../../../utils/helpers/phoneNumberFixer";
import { useRegister } from "../../../services/mutations";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import styles from "../loginModal.module.scss";
type Props = {
  phoneNumber: string;
};
const Countdown: React.FC<Props> = ({
  phoneNumber
}) => {
  const [time, setTime] = useState(59);
  const register = useRegister(handleAfterSuccess);
  const {
    locale
  } = useRouter();

  // after success
  function handleAfterSuccess() {
    setTime(59);
  }

  // start countdown
  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  // handle resend code
  const handleResendCode = () => {
    register.mutate({
      phone: phoneNumberFixer(phoneNumber)
    });
  };
  if (time) {
    return <p className={styles.resend_code_countdown}>
        {layoutLang[locale || 'ru']['Повторная отправка будет доступна']}
        <br />
        {locale === 'uz' ? "" : "Через"} <span className={styles.time}>{time}</span> {layoutLang[locale || 'ru']['Секунд']}.
      </p>;
  } else {
    return <p className={styles.resend_code} onClick={handleResendCode} aria-hidden>
        {layoutLang[locale || 'ru']['Отправить код ещё раз']}
      </p>;
  }
};
export default Countdown;