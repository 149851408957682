const RuFlagIcon = ({
  width = 23,
  height = 18
}: {
  width?: number;
  height?: number;
}) => {
  const id = `clip-${Math.random().toString(36).substr(2, 9)}`;
  return <svg width={width} height={height} viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="RuFlagIcon" data-sentry-source-file="RuFlagIcon.tsx">
      <g clipPath={`url(#${id})`} data-sentry-element="g" data-sentry-source-file="RuFlagIcon.tsx">
        <rect y="0.5" width="23" height="17" rx="3" fill="#1A47B8" data-sentry-element="rect" data-sentry-source-file="RuFlagIcon.tsx" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 11.8334H23V17.5H0V11.8334Z" fill="#F93939" data-sentry-element="path" data-sentry-source-file="RuFlagIcon.tsx" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5H23V6.16667H0V0.5Z" fill="white" data-sentry-element="path" data-sentry-source-file="RuFlagIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="RuFlagIcon.tsx">
        <clipPath id={id} data-sentry-element="clipPath" data-sentry-source-file="RuFlagIcon.tsx">
          <rect y="0.5" width="23" height="17" rx="3" fill="white" data-sentry-element="rect" data-sentry-source-file="RuFlagIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default RuFlagIcon;