import React, { useState } from "react";
import Image, { ImageProps } from "next/image";
import { cx } from "utils/cx";
interface ShimmerImageProps extends ImageProps {
  shimmerWidth?: string;
  shimmerHeight?: string;
}
const ShimmerImage: React.FC<ShimmerImageProps> = ({
  shimmerWidth = "100%",
  shimmerHeight = "100%",
  alt,
  objectFit,
  src,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return <div style={{
    position: "relative",
    overflow: "hidden",
    width: shimmerWidth,
    height: shimmerHeight
  }} className={props.className} data-sentry-component="ShimmerImage" data-sentry-source-file="index.tsx">
      {/* Shimmer effect */}
      {!isLoaded && <div className="absolute inset-0 bg-gray-200 animate-pulse h-full w-full"></div>}

      {/* Image */}
      <Image {...props} src={src} alt={alt} onLoadingComplete={() => setIsLoaded(true)} className={cx(!isLoaded ? "invisible" : "visible", props?.className)} objectFit={objectFit ?? "contain"} title={alt} loading="lazy" priority={props.priority} // Use `priority` only if explicitly passed
    data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </div>;
};
export default ShimmerImage;