import axios from 'axios';

import { endpoints } from '../utils/constants/endpoints';
import { localStorageKeys } from '../utils/constants/localStorageKeys';
import { isBrowser } from '../utils/helpers/isBrowser';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_NEW
});

$api.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      return new Promise((resolve, reject) => {
        $api
          .get(endpoints.REFRESH)
          .then(() => {
            resolve($api(originalRequest));
          })
          .catch(async err => {
            await $api.get(endpoints.LOGOUT).then(() => {
              if (isBrowser()) {
                localStorage.removeItem(localStorageKeys.IS_AUTH);
                localStorage.removeItem(localStorageKeys.APARTMENT);
                localStorage.removeItem(localStorageKeys.COMPLEX);
                location.reload();
              }
            });
            reject(err);
          });
      });
    }
    return Promise.reject(error);
  }
);

export default $api;
