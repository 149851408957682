import React from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { LOCATION } from "utils/constants/queryParamsKeys";
import { rootPaths } from "utils/constants/rootPaths";
import { clearObj } from "utils/helpers/clearObj";
import { findMatchingKey } from "utils/helpers/findMatchingKey";
import { makeStaticFilters } from "utils/helpers/makeStaticFilters";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
import { layoutLang } from "utils/language/layout";
import ListIcon from "assets/icons/ListIcon";
import MapIcon from "assets/icons/MapIcon";
import MapMenuIcon from "assets/icons/MapMenuIcon";
import CustomLink from "common/custom-link";
interface IProps {
  position?: boolean;
}
const NavigateButton: React.FC<IProps> = ({
  position
}) => {
  const dispatch = useAppDispatch();
  const {
    query,
    pathname,
    locale
  } = useRouter();
  const {
    setChangeFilterType,
    setOpenMapPriceDrawer
  } = favouritesReducerActions;
  const filterType = useAppSelector(state => state.favouritesReducer?.filterType);
  const filter = useAppSelector(state => state.favouritesReducer?.filter);
  const nearest_places = useAppSelector(state => state.filterReducer?.nearest_places);

  // location
  const newLocation: string = query[LOCATION] ? query[LOCATION] as string : DEFAULT_LOCATION;
  const isMap = pathname?.includes("karta");
  const staticFilters = makeStaticFilters((query?.apartmentId || query?.id) as string);
  const finalQuery = {
    ...removeObjProperties(query, 'apartmentId', 'city_id', 'id'),
    ...staticFilters
  };
  const path_name = isMap ? findMatchingKey(removeObjProperties(clearObj({
    ...filter,
    nearest_place_ids: nearest_places?.map(item => String(item?.id))
  }), 'city_id')) ? `/${newLocation}${filterType === "apartment" ? rootPaths.APARTMENTS : rootPaths.RESIDENTIAL_COMPLEXES}/${findMatchingKey(removeObjProperties(clearObj({
    ...filter,
    nearest_place_ids: nearest_places?.map(item => String(item?.id))
  }), 'city_id'))}` : `/${newLocation}${filterType === "apartment" ? rootPaths.APARTMENTS : rootPaths.RESIDENTIAL_COMPLEXES}` : rootPaths.MAP;
  const handleNavigate = () => {
    pathname.includes("kvartiri") ? dispatch(setChangeFilterType("apartment")) : dispatch(setChangeFilterType("complex"));
  };
  const handleOpenMapPriceDrawer = () => {
    dispatch(setOpenMapPriceDrawer(true));
  };
  return <div className={`${position ? 'fixed' : 'static'} bottom-[71px] right-4 z-[99]`} data-sentry-component="NavigateButton" data-sentry-source-file="NavigateButton.tsx">
      {pathname.includes("karta") && <div className="flex items-center gap-2 bg-[#1D2939] h-[38px] rounded-lg cursor-pointer mb-3 w-max px-3" onClick={handleOpenMapPriceDrawer}>
          <MapMenuIcon stroke="#fff" />
          <span className="text-white font-semibold">{layoutLang[locale || 'ru']["narx_turi"]}</span>
        </div>}
      <CustomLink href={{
      pathname: path_name,
      query: !isMap && query[LOCATION] !== DEFAULT_LOCATION ? {
        ...finalQuery,
        [LOCATION]: query[LOCATION]
      } : findMatchingKey(removeObjProperties(clearObj({
        ...filter,
        nearest_place_ids: nearest_places?.map(item => String(item?.id))
      }), 'city_id')) && isMap ? '' : {
        ...removeObjProperties(finalQuery, 'location'),
        type: filterType
      }
    } as never} className={`${position ? 'bg-[#00A389]' : 'bg-transparent border border-solid border-[#00A389]'}  bottom-[71px] left-4 z-[99] h-[38px] md:h-11 px-4 rounded-lg md:rounded-xl flex items-center justify-center cursor-pointer text-base font-semibold w-max`} onClick={handleNavigate} data-sentry-element="CustomLink" data-sentry-source-file="NavigateButton.tsx">
        {isMap ? <div className={`flex items-center gap-2.5 ${position ? "!text-white" : "!text-[#00A389]"} `}>
            <ListIcon />
            <span>{layoutLang[locale || 'ru']["royxat"]}</span>
          </div> : <div className={`flex items-center gap-2.5 ${position ? "!text-white" : "!text-[#00A389]"} `}>
            <MapIcon stroke={position ? "#fff" : "#00A389"} />
            <span>{layoutLang[locale || 'ru']["Xarita"]}</span>
          </div>}
      </CustomLink>
    </div>;
};
export default NavigateButton;