import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import { layoutLang } from "utils/language/layout";
import { isBrowser } from "utils/helpers/isBrowser";
import { maxPrices, prePayment } from "./utils/constants/quizConstants";
import { QuizStateModel } from "./utils/models/quizStateModel";
import { PaymentModel } from "./utils/models/paymentModel";
import { localStorageKeys } from "utils/constants/localStorageKeys";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { rootPaths } from "utils/constants/rootPaths";
import { useGetRegionCity } from "pages/main-layout/services/queries";
import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon";
import UysotIcon from "assets/icons/UysotIcon";
import ComplexCard from "common/common-card/complex/ComplexCard";
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper";
import QuizComponent1 from "./components/QuizComponent1";
import QuizComponent2 from "./components/QuizComponent2";
import QuizComponent3 from "./components/QuizComponent3";
import QuizComponent4 from "./components/QuizComponent4";
import QuizComponent5 from "./components/QuizComponent5";
import QuizComponent6 from "./components/QuizComponent6";
import CustomLink from "common/custom-link";
import ShimmerImage from "common/shimmer-image";
import quizImg from './../../assets/images/QuizImg.webp';
export const itemStyle = "border border-solid border-[#EAECF0] rounded-[10px] px-3.5 py-2.5 text-[#101828] text-base font-medium cursor-pointer hover:!border-[#00A389] hover:bg-[#EEFBF6] transition-all duration-300 flex items-center justify-between";
const Quiz = () => {
  const {
    locale,
    back
  } = useRouter();
  const [page, setPage] = useState<number>(1);
  const [quizData, setQuizData] = useState<NewResidentalComplexModel[]>([]);
  const [quizState, setQuizState] = useState<QuizStateModel>({
    name: "",
    phone: ""
  });
  const {
    data: region
  } = useGetRegionCity();
  const [roomsCount, setRoomsCount] = useState<number[]>([]);
  const [total_amount, setTotalAmount] = useState<string>("");
  const [status, setStatus] = useState(false);
  const getPhone = isBrowser() && localStorage.getItem(localStorageKeys.PHONE);
  useEffect(() => {
    setQuizState(state => ({
      ...state,
      phone: String(getPhone)?.replace(/\D/g, "")
    }));
  }, [getPhone]);
  const finalPayment: PaymentModel[] = quizState?.payment_method_id === 2 ? prePayment : maxPrices;
  const leftSide = [{
    id: 1,
    component: <QuizComponent1 quizState={quizState} setQuizState={setQuizState} page={page} setPage={setPage} />
  }, {
    id: 2,
    component: <QuizComponent2 page={page} quizState={quizState} setPage={setPage} roomsCount={roomsCount} setRoomsCount={setRoomsCount} />
  }, {
    id: 3,
    component: <QuizComponent3 page={page} quizState={quizState} setPage={setPage} setQuizState={setQuizState} />
  }, {
    id: 4,
    component: <QuizComponent4 page={page} quizState={quizState} setPage={setPage} setQuizState={setQuizState} finalPayment={finalPayment} setTotalAmount={setTotalAmount} total_amount={total_amount} />
  }, {
    id: 5,
    component: <QuizComponent5 page={page} quizState={quizState} setPage={setPage} setQuizState={setQuizState} />
  }, {
    id: 6,
    component: <QuizComponent6 page={page} quizState={quizState} setPage={setPage} setQuizState={setQuizState} finalPayment={finalPayment} total_amount={total_amount} setStatus={setStatus} roomsCount={roomsCount} setQuizData={setQuizData} />
  }];
  const filterLeftSide = quizState?.payment_method_id === 2 ? leftSide : leftSide?.filter(item => item?.id !== 5);
  const currentComponent = filterLeftSide?.find(item => item?.id === page)?.component;
  const currentDistrict = region?.find(item => item?.id === quizState?.city_id)?.url;
  const moreLink = `/${currentDistrict}/kvartiri`;
  return <div className="bg-white flex items-center" data-sentry-component="Quiz" data-sentry-source-file="Quiz.tsx">
      {!status ? <div className="max-w-[1230px] mx-auto flex justify-between w-full items-center flex-wrap-reverse gap-10 h-screen sm:px-0 px-5 relative pt-[72px] xl:pt-0">
          {currentComponent}
          <div className="w-[430px] hidden sm:block">
            {/* <QuizIcon /> */}
            <ShimmerImage alt="quiz-image" title="quiz-image" src={quizImg} />
            <p className="text-base">
              <b>{layoutLang[locale || 'ru']["tanlash_qiyinmi"]}</b>
              <CustomLink href={`/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`} className="text-[#00A389]">
                «Uysot»
              </CustomLink>
              {layoutLang[locale || 'ru']["mos_uy"]}
            </p>
          </div>
          <div className="absolute top-6 left-5 sm:left-0">
            <div
        // href={`/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`}
        className="cursor-pointer flex items-center gap-5" onClick={back}>
              <JKArrowLeftIcon fill="#475467" />
              <UysotIcon />
            </div>
          </div>
        </div> : <div className="max-w-[1230px] mx-auto h-full w-full flex items-center relative">
          <div className="absolute top-6 left-5 sm:left-0 flex items-center gap-5">
            <CustomLink href={`/${DEFAULT_LOCATION}${rootPaths.RESIDENTIAL_COMPLEXES}`} className="cursor-pointer">
              <JKArrowLeftIcon fill="#475467" />
            </CustomLink>
            <UysotIcon />
          </div>
          <div className="flex flex-col gap-6 w-full pt-[130px] px-5 sm:px-0 pb-5 sm:pb-0 min-h-screen">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#344054] text-2xl font-medium">
                {layoutLang[locale || 'ru']["siz_bilan_boglanamiz"]}
              </h1>
              <p className="text-[#98A2B3] text-base font-medium">
                {layoutLang[locale || 'ru']["uysotdan_taklif"]}
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 w-full flex-wrap">
              {quizData?.slice(0, 3)?.map(item => <NonSSRWrapper key={item?.id}>
                  <ComplexCard data={item} key={item?.id} />
                </NonSSRWrapper>)}
            </div>
            <div className="flex items-center justify-center">
              <CustomLink href={moreLink} className="border border-[#ebebeb] border-solid h-[43px] flex items-center justify-center px-8 rounded-md text-[#00A389] text-base font-medium">
                {layoutLang[locale || 'ru']["Hammasi"]}
              </CustomLink>
            </div>
          </div>
        </div>}
    </div>;
};
export default Quiz;