import React from 'react';
import { Grid, Tooltip } from 'antd';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { userReducerActions } from '../../../store/reducers/userReducer';
import { gaActions, gaCategories } from '../../../utils/constants/ga';
import { RU, UZ } from '../../../utils/constants/locales';
import { rootPaths } from '../../../utils/constants/rootPaths';
import { isEmptyArr } from '../../../utils/helpers/isEmptyArr';
import { makeUrl } from '../../../utils/helpers/makeUrl';
import { NewResidentalComplexModel } from '../../../utils/models/residentialComplexModel';
import { useRouter } from 'next/router';
import { layoutLang } from '../../../utils/language/layout';
import { isEmptyObject } from 'utils/helpers/isEmptyObject';
import { useCreateUserInteractions } from 'pages/detailed-magazine/service/mutations';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { cx } from 'utils/cx';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ReactGA from 'react-ga4';
import CommonCardTop from '../top/CommonCardTop';
import PhoneIcon from '../../../assets/icons/PhoneIcon';
import Placements from '../../../pages/detailed-residential-complex/components/placements/Placements';
import BadgeIcon from 'assets/icons/BadgeIcon';
import CustomLink from 'common/custom-link';
import ComplexApartments from '../complex-apartments/ComplexApartments';
import ComplexAvailability from '../complex-availability/complex-availability';
import SortComp from '../sort-comp/sort-comp';
import styles from '../commonCard.module.scss';
dayjs.extend(advancedFormat);
type Props = {
  data: NewResidentalComplexModel;
  isMap?: boolean;
  queryKey?: (string | number)[];
};
const ComplexCard: React.FC<Props> = ({
  data,
  isMap,
  queryKey
}) => {
  const dispatch = useAppDispatch();
  const {
    locale,
    pathname,
    query
  } = useRouter();
  const {
    setCallBackModal
  } = userReducerActions;
  const {
    clearFilters,
    setWindowPosition,
    setOpenArticleDrawer
  } = favouritesReducerActions;
  const {
    md
  } = Grid.useBreakpoint();
  const handleClose = () => {
    dispatch(setOpenArticleDrawer({
      id: data?.id,
      open: false
    }));
  };
  const userInteraction = useCreateUserInteractions();
  const handleUserInteraction = () => {
    dispatch(setWindowPosition({
      positionY: 0,
      activeKey: undefined
    }));
    dispatch(clearFilters());
    isMap && handleClose();
    userInteraction.mutate({
      interaction_type: 'view',
      complex_id: data?.id
    });
  };

  // coll back
  const handleCallBack = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setCallBackModal({
      visible: true,
      type: 'complex',
      id: data?.id
    }));
    ReactGA.event({
      action: gaActions.CALL_ME,
      category: gaCategories.PHONE_NUMBER
    });
  };
  const cardHref = query?.currency ? `/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}/${makeUrl(data?.slug || data?.name)}-${data?.id}?currency=${query?.currency}` : `/${data?.district?.city?.url}${rootPaths.RESIDENTIAL_COMPLEXES}/${makeUrl(data?.slug || data?.name)}-${data?.id}`;
  const brandHref = query?.currency ? `${rootPaths.BUILDERS}/${makeUrl(data?.brand?.slug ? data?.brand?.slug : data?.brand?.name)}?currency=${query?.currency}` : `${rootPaths.BUILDERS}/${makeUrl(data?.brand?.slug ? data?.brand?.slug : data?.brand?.name)}`;
  const hasSortComp = data?.sort_key && data?.sort_key !== 'discount_percent' && data?.sort_key !== 'total_price';
  return <div className="relative w-full" data-sentry-component="ComplexCard" data-sentry-source-file="ComplexCard.tsx">
      <CustomLink href={cardHref} target={isMap && md ? '_blank' : '_self'} className="static" onClick={handleUserInteraction} title={`${locale === RU ? layoutLang[locale || 'ru']['ЖК'] : ''} "${data?.name}" ${locale === UZ ? layoutLang[locale || 'ru']['ЖК'] : ''}`} data-sentry-element="CustomLink" data-sentry-source-file="ComplexCard.tsx">
        <span className="absolute left-0 top-0 z-[1] block h-full w-full"></span>
      </CustomLink>
      <div className={`${styles.complex_card} ${pathname.includes('novostroyki') ? `h-auto ${hasSortComp ? 'md:h-[560px]' : 'md:h-[516px]'}` : `h-[492px] ${hasSortComp ? 'md:h-[560px]' : 'md:h-[516px]'}`} relative`}>
        <CommonCardTop images={data?.image} classes={data?.class} payments={data?.payment_methods} is_top={data?.is_top} alt={locale === UZ ? `${data?.name || data?.name} turar-joy majmuasi | ${layoutLang[locale || 'ru']['Каталог ЖК']} ${data?.district?.city?.name?.uz ? `${data?.district?.city?.name?.uz}da` : ''} | Uysot` : `${layoutLang[locale || 'ru']['TJM']} ${data?.name || data?.name} | ${layoutLang[locale || 'ru']['Каталог ЖК']} в ${data?.district?.city?.name?.ru ?? ''} | Uysot`} data={data} type="complex" cardLinkHref={cardHref} cardLinkTarget={isMap && md ? '_blank' : '_self'} cardLinkTitle={`${locale === RU ? layoutLang[locale || 'ru']['ЖК'] : ''} "${data?.name}" ${locale === UZ ? layoutLang[locale || 'ru']['ЖК'] : ''}`} onCardClick={handleUserInteraction} queryKey={queryKey} data-sentry-element="CommonCardTop" data-sentry-source-file="ComplexCard.tsx" />
        <div className={styles.complex_card_bottom}>
          <div className="flex flex-col gap-3">
            <div className={cx(isEmptyArr(data?.payment_methods), 'flex flex-col gap-1.5')}>
              <div className={cx(styles.name)}>
                <div className={cx(isEmptyArr(data?.payment_methods))}>
                  <h3>{`${locale === RU ? layoutLang[locale || 'ru']['ЖК'] : ''} "${data?.name}" ${locale === UZ ? layoutLang[locale || 'ru']['ЖК'] : ''}`}</h3>
                </div>
              </div>
              <p className={styles.address}>
                {`${data?.district?.city?.name[locale as keyof typeof data.district.city.name]}${data?.district?.name ? `, ${data?.district?.name[locale as keyof typeof data.district.name]}` : ''}`}
              </p>
              <div className={`mt-1.5 flex flex-col justify-end gap-3 ${hasSortComp ? 'h-auto md:h-[67px]' : 'h-auto'}`}>
                {data?.nearest_place && !isEmptyObject(data?.nearest_place) && <Placements type="complex" data={data?.nearest_place} />}
                {hasSortComp && data?.sort_value !== undefined && <SortComp sort_value={data?.sort_value as number} />}
              </div>
            </div>
          </div>
          <div className={cx('mt-3 flex flex-col gap-3 md:mt-0', data?.nearest_place && !isEmptyObject(data?.nearest_place) && 'mt-4')}>
            <div className="mb-6 flex flex-col justify-between gap-3">
              <ComplexApartments apartments={data?.apartment_statistics} data-sentry-element="ComplexApartments" data-sentry-source-file="ComplexCard.tsx" />
            </div>
            <div className={styles.call} onClick={handleCallBack}>
              <PhoneIcon data-sentry-element="PhoneIcon" data-sentry-source-file="ComplexCard.tsx" />
              <span>{layoutLang[locale || 'ru']['Перезвоните мне']}</span>
            </div>
          </div>
        </div>
        <CustomLink className="absolute bottom-14 left-4 z-[2] mb-3 flex min-w-[80%] items-center gap-2" href={brandHref} data-sentry-element="CustomLink" data-sentry-source-file="ComplexCard.tsx">
          <div className={`${styles.builder} !mb-0 pr-2 !text-[#1570EF]`}>
            {data?.brand?.name ?? data?.builder?.name}
          </div>
          {data?.brand?.badge ? <Tooltip overlay={layoutLang[locale || 'ru']['badge_text']}>
              <span>
                <BadgeIcon />
              </span>
            </Tooltip> : ''}
        </CustomLink>
        <div className="absolute left-3 top-[170px] z-[10] md:top-[195px]">
          <ComplexAvailability data={data} href={cardHref} data-sentry-element="ComplexAvailability" data-sentry-source-file="ComplexCard.tsx" />
        </div>
      </div>
    </div>;
};
export default ComplexCard;