import { Button, Skeleton } from "antd";
import { useRouter } from "next/router";
import { useGetMainRegions } from "pages/main-layout/services/queries";
import React from "react";
import { layoutLang } from "utils/language/layout";
import { itemStyle } from "../Quiz";
import { QuizStateModel } from "../utils/models/quizStateModel";
import { createArrayWithDan } from "../utils/helpers";
interface IProps {
  quizState: QuizStateModel;
  setQuizState: React.Dispatch<React.SetStateAction<QuizStateModel>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
const QuizComponent1: React.FC<IProps> = ({
  quizState,
  setQuizState,
  setPage,
  page
}) => {
  const {
    data: regions,
    isLoading
  } = useGetMainRegions();
  const {
    locale
  } = useRouter();
  return <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0" data-sentry-component="QuizComponent1" data-sentry-source-file="QuizComponent1.tsx">
      <div className="min-h-[305px]">
        <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${quizState?.payment_method_id === 2 ? 6 : 5} ${layoutLang[locale || 'ru']["savol"]}`}</p>
        <h2 className="text-[#344054] text-2xl font-medium">
          {layoutLang[locale || 'ru']["xonadoni_qayerdan_qidiramiz"]}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5 overflow-auto h-[85%]">
          {isLoading ? createArrayWithDan(8)?.map((_, key) => <Skeleton.Input key={key} active className="!w-full !h-[47px] rounded-xl" />) : regions?.map(item => <div key={item?.id} className={`${quizState?.city_id === item?.id ? "bg-[#EEFBF6]" : ""} ${quizState?.city_id === item?.id ? "!border-[#00A389]" : ""} ${itemStyle}`} onClick={() => quizState?.city_id === item?.id ? setQuizState(state => ({
          ...state,
          city_id: undefined
        })) : setQuizState(state => ({
          ...state,
          city_id: item?.id
        }))}>
                <span>{item?.name[locale as keyof typeof item.name]}</span>
                {/* {item?.isTop && (
                    <div className="bg-[#00A389] px-2 py-0.5 rounded-2xl text-[#FFFFFF] text-xs font-medium">
                      {layoutLang[locale || 'ru']["TOP"]}
                    </div>
                  )} */}
              </div>)}
        </div>
      </div>
      <div className="flex items-center gap-3 w-full mt-[68px]">
        <Button type="primary" onClick={() => setPage(page + 1)} className="h-[47px] rounded-xl flex items-center justify-center w-full text-base" disabled={!quizState?.city_id} data-sentry-element="Button" data-sentry-source-file="QuizComponent1.tsx">
          {layoutLang[locale || 'ru']["keyingisi"]}
        </Button>
      </div>
    </div>;
};
export default QuizComponent1;