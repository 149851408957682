import React from 'react';
import { useRouter } from 'next/router';
import { StaticImageData } from 'next/image';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setActiveIndex, setOpenProBrandModal } from 'store/reducers/pro-brand-reducer';
import { proBrandData } from 'pages/app/utils/constants/pro-brand-modal-data';
import { cx } from 'utils/cx';
import CloseIcon from 'assets/icons/CloseIcon';
import ShimmerImage from 'common/shimmer-image';
import SwiperArrowIcon from 'assets/icons/swiper-arrow-icon';
const Right: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    locale
  } = useRouter();
  const activeIndex = useAppSelector(state => state.proBrandReducer.activeIndex);
  const handleCancel = () => {
    dispatch(setOpenProBrandModal(false));
    dispatch(setActiveIndex(0));
  };
  const handlePrev = () => {
    dispatch(setActiveIndex(activeIndex - 1));
  };
  const handleNext = () => {
    dispatch(setActiveIndex(activeIndex + 1));
  };

  // bu yerda ham shadowni px lariga teginmadim.Sabab bir xil (juda kichkina raqam bo'lib ketadi).

  return <div className='flex flex-col rounded-r-xl min-h-full justify-between w-[56%] bg-gradient-to-b from-purple-50 to-purple-300 pt-6 pb-7 px-6' data-sentry-component="Right" data-sentry-source-file="index.tsx">
        <div className='flex justify-end items-center'>
            <div className='flex justify-center items-center  w-8 h-8 rounded-full p-6 bg-[#5C44B999] cursor-pointer' onClick={handleCancel}>
                <div>
                    <CloseIcon fill='white' data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
                </div>
            </div>
        </div>
        {proBrandData?.map((el, i) => <div className={cx('flex-col gap-[2.375rem] h-[24.125rem] justify-center items-center', i === activeIndex ? 'flex' : 'hidden')} key={el?.name?.uz}>
            <span className='text-5xl font-bold text-[var(--color-pink)] text-center'>#{(el?.name?.[(locale || 'ru') as never] as string)?.toUpperCase()}</span>
            {el?.image && <div className=' flex justify-center items-center'>
                    <ShimmerImage className='!h-max' src={el?.image as unknown as StaticImageData} alt={el?.name?.[(locale || 'ru') as never]} />
                </div>}
            {el?.social_networks && <div className='grid grid-cols-2 gap-4'>
                    {el?.social_networks?.map(el => <div key={el?.text?.uz} className='flex flex-col gap-2 justify-center items-center bg-white rounded-2xl shadow-[inset_0px_2.6px_2.6px_0px_#E5E5E540,inset_0px_-1.3px_2.6px_0px_#62626240,0px_2.6px_4.87px_0px_#00000040] p-5'>
                            <div className='flex justify-center items-center'>
                                <ShimmerImage alt={el?.text?.uz} src={el?.image} />
                            </div>
                            <span className='text-base font-extrabold text-gray-900'>{el?.amount}</span>
                            <span className='text-[10px] font-semibold text-gray-800'>{el?.text?.[(locale || 'ru') as never]}</span>
                        </div>)}
                </div>}
        </div>)}
        <div className='flex justify-end items-center gap-9'>
            {activeIndex > 0 && <div className='cursor-pointer' onClick={handlePrev}>
                    <SwiperArrowIcon fill='white' />
                </div>}
            {activeIndex < 4 && <div className={cx('cursor-pointer', activeIndex === 4 && 'pointer-events-none')} onClick={handleNext}>
                    <SwiperArrowIcon rotate={180} fill='white' />
                </div>}
        </div>
    </div>;
};
export default Right;