import React from "react";
import MiniCloseIcon from "assets/icons/MiniCloseIcon";
import { Grid, Modal } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import styles from "./index.module.scss";
import { useGetUyotCoin } from "pages/main-layout/services/queries";
import UysotCoinContent from "./uysot-coin-content";
import { formatAmount } from "utils/helpers/format-amount";
import { cx } from "utils/cx";
const UysotCoinModal = () => {
  const {
    data
  } = useGetUyotCoin();
  const {
    setCoinVisible
  } = favouritesReducerActions;
  const {
    md
  } = Grid.useBreakpoint();
  const dispatch = useAppDispatch();
  const coinVisible = useAppSelector(state => state.favouritesReducer.coinVisible);
  const coinCounts = data?.reduce((prev, acc) => {
    return prev + acc?.count_coin;
  }, 0);
  const handleOpenUysotCoin = () => {
    dispatch(setCoinVisible(true));
  };
  const handleClose = () => {
    dispatch(setCoinVisible(false));
  };
  return <>
      <div className="flex items-center gap-1 md:gap-2.5 bg-[#4D6F68] pl-[11px] rounded-full cursor-pointer w-max h-7 relative" onClick={handleOpenUysotCoin}>
        <span className="text-base text-white font-semibold">
          {formatAmount(coinCounts as number) || 0}
        </span>
        <div className={cx("w-[28.5px] h-[30px]", styles.coin_container)} />
      </div>
      {md && <Modal open={coinVisible} onCancel={handleClose} footer={false} closable={false} title={<div className="flex items-center justify-between">
              <div className="text-xl font-bold flex items-center gap-1">
                <span className="text-[#1D2939]">U</span>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0DC683] to-[#00A389]">
                  COIN
                </span>
              </div>
              <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full bg-[#F2F2F7] cursor-pointer" onClick={handleClose}>
                <MiniCloseIcon size={11} />
              </div>
            </div>} className={styles.coinModal} centered>
          <UysotCoinContent coinCounts={coinCounts} data={data} />
        </Modal>}
    </>;
};
export default UysotCoinModal;