import { useMutation, useQueryClient } from "@tanstack/react-query"
import { message } from "antd"
import { SendAppModel } from "../utils/models/sendAppModel"
import { endPoints } from "../utils/constants/endPoints"
import { ErrorRes } from "utils/models/responseType"
import { queryKeys } from "pages/main-layout/utils/constants/queryKeys"
import { localStorageKeys } from "utils/constants/localStorageKeys"
import { useAppDispatch } from "hooks/reduxHooks"
import { userReducerActions } from "store/reducers/userReducer"

import $api from "services/RequestService"

export function useSendApplication() {
  const qc = useQueryClient()
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions

  return useMutation({
    mutationFn: async (data: SendAppModel) => {
      const res = await $api.post(endPoints.SEND_APP, data)
      return res.data
    },
    onError: (err: ErrorRes) => {
      message.error("Error:")
      if (err?.response?.status === 401) {
        qc.invalidateQueries([queryKeys.CHECK_AUTH])
        localStorage.removeItem(localStorageKeys.IS_AUTH)
        localStorage.removeItem(localStorageKeys.TOKEN)
        dispatch(setIsAuth(false))
      }
    },
  })
}
