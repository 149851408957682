import React from 'react';
import { Badge } from 'antd';
import { useRouter } from 'next/router';
import { useAppDispatch } from 'hooks/reduxHooks';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { cleanObject } from 'utils/helpers/cleanObject';
import { removeObjProperties } from 'utils/helpers/removeObjProperties';
import SearchIcon from 'assets/icons/SearchIcon';
import RegionItem from 'common/filter-items/region-item/region-item';
import FilterMetro from 'pages/main/components/filter-metro/FilterMetro';
import MenuLocationIcon from 'assets/icons/MenuLocationIcon';
const ReelsMobileFilter = () => {
  const {
    pathname,
    query
  } = useRouter();
  const dispatch = useAppDispatch();
  const {
    setOpenRegionSelect,
    setOpenRegionDrawer
  } = favouritesReducerActions;
  const handleOpenSearch = () => {
    dispatch(setOpenRegionSelect({
      type: "mobile_location",
      value: true
    }));
    dispatch(setOpenRegionDrawer(true));
  };
  const keyCount = Object.keys(cleanObject({
    ...removeObjProperties(query, 'search')
  }))?.length;
  return <>
         {pathname === '/reels' && <div className={`mobile_only sticky transition-transform duration-[0.15s] ease-out bg-white top-0 z-[1000] h-[3.875rem] border-b border-t px-3 border-solid border-gray-200 shadow-sm`}>
                    <div className='flex justify-center items-center w-full h-full'>
                        <div className="flex h-[2.375rem] bg-gray-100 w-full cursor-pointer items-center justify-between rounded-xl border border-solid border-gray-100 px-2.5">
                            <div className='flex items-center gap-2'>
                                <SearchIcon size="20" color="var(--lavender-gray)" />
                                <FilterMetro className='border-none !bg-gray-100 !w-[16.25rem] !h-full [&_.ant-select-selector]:!bg-gray-100 [&_.ant-select-selector]:!p-0' type="header" />
                            </div>
                                <div className='flex justify-center items-center' onClick={handleOpenSearch}>
                                    <Badge count={keyCount} className=''>
                                            <MenuLocationIcon stroke={keyCount > 0 ? "var(--green)" : "var(--lavender-gray)"} />
                                    </Badge>
                                </div>
                        </div>
                    </div>
                    <RegionItem />
                </div>}
    </>;
};
export default ReelsMobileFilter;