import React, { useEffect } from 'react';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { staticUrls } from 'utils/constants/staticUrls';
import { layoutLang } from 'utils/language/layout';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
const buildingStatus = [{
  id: 1,
  value: "true",
  name: {
    uz: "Tayyor",
    ru: "Готовый"
  }
}, {
  id: 2,
  value: "false",
  name: {
    uz: "Qurilmoqda",
    ru: "Строится"
  }
}];
const BuildItem = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    setComplatedComplex
  } = favouritesReducerActions;
  const comp_complex = useAppSelector(state => state.favouritesReducer.filter.completed_complex);
  useEffect(() => {
    if (staticUrls[(query?.id || query?.apartmentId) as string]?.completed_complex) {
      dispatch(setComplatedComplex(staticUrls[(query?.id || query?.apartmentId) as string]?.completed_complex as string));
    }
    if (query.completed_complex) {
      dispatch(setComplatedComplex(query?.completed_complex as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="BuildItem" data-sentry-source-file="build-item.tsx">
      {layoutLang[locale || 'ru']['qurilish_holati']}
      <div className="flex items-center gap-2">
        {buildingStatus?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 ${comp_complex === item.value ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => {
        comp_complex === item?.value ? dispatch(setComplatedComplex(null)) : dispatch(setComplatedComplex(item.value));
      }}>
            {item?.name[locale as keyof typeof item.name]}
          </div>)}
      </div>
    </div>;
};
export default BuildItem;