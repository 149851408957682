export const delayItems = [
  { id: 1, value: "12", name: { uz: "12 oy", ru: "12 месяцев" } },
  { id: 2, value: "24", name: { uz: "24 oy", ru: "24 месяцев" } },
  { id: 3, value: "36", name: { uz: "36 oy", ru: "36 месяцев" } },
  { id: 4, value: "48", name: { uz: "48 oy", ru: "48 месяцев" } },
  { id: 5, value: "60", name: { uz: "60 oy", ru: "60 месяцев" } },
]
export const deadlineItems = [
  { id: 2, value: "2025", name: { uz: "2025 yil", ru: "2025 год" } },
  { id: 4, value: "2026", name: { uz: "2026 yil", ru: "2026 год" } },
  { id: 5, value: "2027", name: { uz: "2027 yil", ru: "2027 год" } },
  { id: 6, value: "2028", name: { uz: "2028 yil", ru: "2028 год" } },
  { id: 7, value: "2029", name: { uz: "2029+ yil", ru: "2029+ год" } },
]

export const repairedItems = [
  { id: 1, value: "true", name: { uz: "Ta'mirli", ru: "С ремонтом" } },
  { id: 2, value: "false", name: { uz: "Ta'mirsiz", ru: "Без ремонта" } },
]
export const filterTypes = [
  { id: 1, value: "complex", name: { uz: "Yangi binolar", ru: "Новостройки" } },
  { id: 2, value: "apartment", name: { uz: "Xonadon", ru: "Квартира" } },
]
