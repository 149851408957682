import { StaticImageData } from 'next/image';
import telegram from '../../assets/images/telegram.webp';
import instagram from '../../assets/images/instagram.webp';
import uysot from '../../assets/images/uysot-mobile-app.webp';
import youtube from '../../assets/images/uysot-web-app.webp';
import top from '../../assets/images/top.webp';
import brand from '../../assets/images/brand.webp';
import special_page from '../../assets/images/special-page.webp';
import analyze from '../../assets/images/analyze.webp'

export const proBrandData:{
    title:{
        uz:string;
        ru:string
    },
    description:{
        uz:string;
        ru:string
    },
    name:{
        uz:string;
        ru:string
    },
    image?:StaticImageData;
    social_networks?:{
        image:StaticImageData;
        text:{
            uz:string;
            ru:string
        }
        amount:string
    }[]
}[] = [
    {
        name:{
            uz:'Maxsus safiha',
            ru:'Special страница'
        },
        title:{
            uz:'Yangi binongiz uchun maxsus sahifa',
            ru:'Специальная страница для вашей новостройки'
        },
        description:{
            uz:'Alohida sayt yaratishga hojat yo‘q! Marketplace ichida SEO optimallashtirilgan qulay landing sahifasini taqdim etamiz.',
            ru:'Не нужно создавать отдельный сайт! Мы предоставляем удобную SEO-оптимизированную целевую страницу на торговой площадке.'
        },
        image:special_page
    },
    {
        name:{
            uz:'Brend',
            ru:'Бренд'
        },
        title:{
             uz:'Brendingiz uchun maxsus sahifa',
             ru:'Отдельная страница для вашего бренда'
        },
        description:{
            uz:'Marketplace ichida brendingizni rivojlantirish uchun maxsus sahifa taqdim etiladi',
            ru:'На торговой площадке предусмотрена специальная страница для продвижения вашего бренда.'
        },
        image:brand
    },
    {
        name:{
            uz:'Top',
            ru:'Топ'
        },
        title:{
            uz:'Avtomatik tavsiyalar va Premium maqom',
            ru:'Автоматические рефералы и премиум-статус'
        },
        description:{
            uz:'Premium maqom sizning TJM-laringizni marketplace foydalanuvchilariga birinchi tavsiya qilishni ta’minlaydi!',
            ru:'Премиум-статус гарантирует, что ваши ЖК будут первыми рекомендованы пользователям торговой площадки!'
        },
        image:top
    },
    {
        name:{
            uz:'Tahlil',
            ru:'Анализ'
        },
        title:{
            uz:'Yangi bino va Sotuv jarayoni tahlili',
            ru:'Автоматизированная отправка отчетов.'
        },
        description:{
            uz:'Oylik mijozlarining sizga qiziqishlarini telegram gruppangizga  yuborib beramiz',
            ru:'Автоматическое распределение и управление потоком лидов.'
        },
        image:analyze
    },
    {
        name:{
            uz:"Reklama",
            ru:'Реклама'
        },
        title:{
            uz:'Ijtimoiy tarmoq targ‘iboti',
            ru:'Широкий выбор рекламных возможностей'
        },
        description:{
            uz:'Brendingiz haqida maxsus post e’lon qilamiz. TJM tanlangan mavzuga mos bo‘lsa, obzor videolar e’lon qilamiz. ',
            ru:'Бесплатная публикация в Instagram-профиле с 85K подписчиками.Отправка push-рекламы 10K клиентам в приложении Uysot.'
        },
        social_networks:[
            {
               image:telegram,
               text:{
                uz:'Foydalanuvchi',
                ru:'Пользователь'
               },
               amount:'2,000'
            },
            {
               image:instagram,
               text:{
                uz:'Obunachilar',
                ru:'Подписчики'
               },
               amount:'87,000'
            },
            {
               image:uysot,
               text:{
                uz:'Oylik foydalanuvchi',
                ru:'Мес. пользователи'
               },
               amount:'10,000'
            },
            {
               image:youtube,
               text:{
                uz:'Obunachilar',
                ru:'Подписчики'
               },
               amount:'100,000'
            },
        ]
    },
    
]