import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import { useGetClasses } from 'pages/residential-complexes/services/queries';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { layoutLang } from 'utils/language/layout';
const ClassItem = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const class_ids = useAppSelector(state => state.favouritesReducer.filter.class_ids);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    setClasses
  } = favouritesReducerActions;
  const {
    data
  } = useGetClasses();
  const handleAdd = (id: string) => {
    if (class_ids.includes(id)) {
      const newDeadlineItems = class_ids?.filter(item => item !== id);
      dispatch(setClasses(newDeadlineItems));
    } else {
      dispatch(setClasses([...class_ids, id]));
    }
  };
  useEffect(() => {
    if (query?.class_ids) {
      if (Array.isArray(query?.class_ids)) {
        dispatch(setClasses(query?.class_ids));
      } else {
        dispatch(setClasses([query?.class_ids]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="ClassItem" data-sentry-source-file="class-item.tsx">
      {layoutLang[locale || 'ru']['Uy_sinfi']}
      <div className="flex items-center gap-2">
        {data?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 
            ${class_ids?.includes(String(item?.id)) ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => handleAdd(String(item?.id))}>
            {item?.name[locale as keyof typeof item.name]}
          </div>)}
      </div>
    </div>;
};
export default ClassItem;