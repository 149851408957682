import React, { useEffect } from 'react';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { delayItems } from '../filter-desktop-modal/filterItems';
import { layoutLang } from 'utils/language/layout';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
const DelayItem = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const delays = useAppSelector(state => state.favouritesReducer.filter.delays);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    setDelays
  } = favouritesReducerActions;
  const data = delayItems;
  const handleAdd = (value: string) => {
    if (delays.includes(value)) {
      const newDelayItems = delays?.filter(item => item !== value);
      dispatch(setDelays(newDelayItems));
    } else {
      dispatch(setDelays([...delays, value]));
    }
  };
  useEffect(() => {
    if (query?.delays) {
      if (Array.isArray(query?.delays)) {
        dispatch(setDelays(query?.delays));
      } else {
        dispatch(setDelays([query?.delays]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="DelayItem" data-sentry-source-file="delay-item.tsx">
      {layoutLang[locale || 'ru']['Tolov muddati']}
      <div className="flex items-center gap-2">
        {data?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 
            ${delays?.includes(item?.value) ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => handleAdd(item?.value)}>
            {item?.name[locale as keyof typeof item.name]}
          </div>)}
      </div>
    </div>;
};
export default DelayItem;