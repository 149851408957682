export const localStorageKeys = {
  COMPLEX: "complex",
  APARTMENT: "apartment",
  ADDRESS: "address",
  PHONE: "phone",
  MAIN_PHONE: "main_phone",
  IS_AUTH: "is_auth",
  TOKEN: "token",
  IS_SEND: "is_send"
}
