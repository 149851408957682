import React from 'react';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setOpenProBrandModal } from 'store/reducers/pro-brand-reducer';
import CloseIcon from 'assets/icons/CloseIcon';
import SwiperPart from './swiper-part';
const MobileContent = () => {
  const dispatch = useAppDispatch();
  const handleCancel = () => {
    dispatch(setOpenProBrandModal(false));
  };
  return <div className='relative bg-white rounded-xl overflow-hidden flex flex-col' data-sentry-component="MobileContent" data-sentry-source-file="index.tsx">
        <div className='flex z-10 absolute right-3 top-3 justify-center items-center  w-6 h-6 rounded-full p-1 bg-[#5C44B999] cursor-pointer' onClick={handleCancel}>
            <div>
                <CloseIcon size={12} fill='white' data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
            </div>
        </div>
        <SwiperPart data-sentry-element="SwiperPart" data-sentry-source-file="index.tsx" />
    </div>;
};
export default MobileContent;