import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { layoutLang } from 'utils/language/layout';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
const distanceItem = ['5', '10', '15', '20'];
const DistanceMetroItem = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const {
    setDistanceMetro
  } = favouritesReducerActions;
  const complex_nearest_place_duration = useAppSelector(state => state.favouritesReducer.filter.complex_nearest_place_duration);
  const openDeadlineDrawer = useAppSelector(state => state.favouritesReducer.openDeadlineDrawer);
  useEffect(() => {
    if (query.complex_nearest_place_duration) {
      dispatch(setDistanceMetro(query?.complex_nearest_place_duration as string));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDeadlineDrawer]);
  const handleClick = (item: string) => {
    if (item === complex_nearest_place_duration) {
      dispatch(setDistanceMetro(null));
    } else {
      dispatch(setDistanceMetro(item));
    }
  };
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="DistanceMetroItem" data-sentry-source-file="distance-metro-item.tsx">
      {layoutLang[locale || 'ru']['metrogacha_piyoda']}
      <div className="flex items-center gap-2">
        {distanceItem?.map(item => <div key={item} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300
              ${complex_nearest_place_duration === item ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => handleClick(item)}>
            {`${item} ${layoutLang[locale || 'ru']['min']}`}
          </div>)}
      </div>
    </div>;
};
export default DistanceMetroItem;