import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface InitialState {
    open:boolean;
    activeIndex:number
}

const initialState:InitialState = {
    open:false,
    activeIndex:0
}

export const proBrandSlice = createSlice({
    name:'pro-brand',
    initialState,
    reducers:{
        setOpenProBrandModal:(state,action:PayloadAction<boolean>)=>{
            state.open = action.payload
        },
        setActiveIndex:(state,action:PayloadAction<number>)=>{
            state.activeIndex = action.payload
        }
    }
})

export default proBrandSlice.reducer
export const { setOpenProBrandModal, setActiveIndex } = proBrandSlice.actions