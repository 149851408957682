import React from 'react';
const Video2Icon = (svg: React.SVGProps<SVGSVGElement>) => {
  return <svg {...svg} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Video2Icon" data-sentry-source-file="video2-icon.tsx">
      <path d="M18.9342 12.4993V7.49935C18.9342 3.33268 17.2676 1.66602 13.1009 1.66602H8.10091C3.93424 1.66602 2.26758 3.33268 2.26758 7.49935V12.4993C2.26758 16.666 3.93424 18.3327 8.10091 18.3327H13.1009C17.2676 18.3327 18.9342 16.666 18.9342 12.4993Z" stroke={svg.stroke || "#475467"} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="video2-icon.tsx" />
      <path d="M2.7002 5.92578H18.5002" stroke={svg.stroke || "#475467"} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="video2-icon.tsx" />
      <path d="M7.7002 1.75781V5.80781" stroke={svg.stroke || "#475467"} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="video2-icon.tsx" />
      <path d="M13.501 1.75781V5.43281" stroke={svg.stroke || "#475467"} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="video2-icon.tsx" />
      <path d="M8.72559 12.0421V11.0421C8.72559 9.75875 9.63392 9.23376 10.7423 9.87542L11.6089 10.3754L12.4756 10.8754C13.5839 11.5171 13.5839 12.5671 12.4756 13.2088L11.6089 13.7088L10.7423 14.2088C9.63392 14.8504 8.72559 14.3254 8.72559 13.0421V12.0421V12.0421Z" stroke={svg.stroke || "#475467"} strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="video2-icon.tsx" />
    </svg>;
};
export default Video2Icon;