import React from 'react';
import { useRouter } from 'next/router';
import { UZ } from 'utils/constants/locales';
import { proBrandData } from 'pages/app/utils/constants/pro-brand-modal-data';
import { cx } from 'utils/cx';
import { layoutLang } from 'utils/language/layout';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import CustomLink from 'common/custom-link';
import { setActiveIndex } from 'store/reducers/pro-brand-reducer';
const Left: React.FC = () => {
  const activeIndex = useAppSelector(state => state.proBrandReducer.activeIndex);
  const {
    locale
  } = useRouter();
  const dispatch = useAppDispatch();
  const handleSetActiveIndex = (index: number) => {
    dispatch(setActiveIndex(index));
  };
  return <div className='pl-7 py-7 min-h-full rounded-l-xl pr-5 bg-white w-[45%]' data-sentry-component="Left" data-sentry-source-file="index.tsx">
        <div className='flex flex-col h-full justify-between'>
            <div className='flex flex-col gap-10'>
                <span className='text-[1.5625rem] font-bold leading-7 bg-gradient-to-r from-[#578CFF] to-[#BA78E2] text-transparent bg-clip-text'>{locale === UZ ? "PRO QURUVCHI" : 'ПРО ЗАСТРОЙЩИК'}</span>
                <div className='flex flex-col gap-[0.375rem]'>
                    {proBrandData?.map((el, i) => <div key={el?.title?.uz} className={cx('py-4 cursor-pointer', i !== 4 && i !== activeIndex && 'border-b border-solid border-b-gray-100', {
            'rounded-[0.625rem] p-2.5 border-none bg-[var(--lightest-blue)] flex flex-col gap-2': i === activeIndex
          })} onClick={() => handleSetActiveIndex(i)}>
                            <span className='text-base font-semibold text-[var(--dark-gray-blue)]'>{i + 1}. {el?.title?.[(locale || 'ru') as never]}</span>
                            {i === activeIndex && <span className='text-xs font-normal pl-[1.5625rem] text-[var(--cool-gray)]'>
                                    {el?.description?.[(locale || 'ru') as never]}
                                </span>}
                        </div>)}
                </div>
            </div>
            <CustomLink href="https://t.me/uysot_manager" target="_blank" rel="noopener noreferrer" className='w-max hover:text-white  rounded-xl py-4 px-7 border border-solid border-[var(--lightest-blue-mist)] flex justify-center items-center text-white bg-gradient-to-r from-[#578CFF] to-[#BA78E2]' data-sentry-element="CustomLink" data-sentry-source-file="index.tsx">
                {layoutLang[locale || 'ru']["E‘lon joylashtirish"]}
            </CustomLink>
        </div>
    </div>;
};
export default Left;