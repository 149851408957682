import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/router"

import { errorHandler } from "utils/helpers/errorHandler"
import { NewApartmentCardModel } from "utils/models/apartmentModel"
import { Pagination } from "utils/models/pagination"
import { ErrorRes } from "utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import { fetchApartments, fetchNewPaymentMethods } from "./queryFunctions"
import { PaymentMethodsAndClassesType } from "../utils/models/models"
import { useAppSelector } from "hooks/reduxHooks"
import { USD } from "utils/constants/locales"
import { clearObj } from "utils/helpers/clearObj"
import { removeObjProperties } from "utils/helpers/removeObjProperties"

// get apartments
export function useGetApartments(id?: number) {
  const { query } = useRouter()
  const currency = useAppSelector(state => state.localeReducer.currency)
  const usdValue = useAppSelector(state => state.localeReducer.usdValue)

  const price = {
    min_total_price: query?.min_total_price ? currency === USD ? (Number(query?.min_total_price) * usdValue) : Number(query?.min_total_price) : null,
    max_total_price: query?.max_total_price ? currency === USD ? (Number(query?.max_total_price) * usdValue) : Number(query?.max_total_price) : null,
    min_prepayment: query?.min_prepayment ? currency === USD ? (Number(query?.min_prepayment) * usdValue) : Number(query?.min_prepayment) : null,
    max_prepayment: query?.max_prepayment ? currency === USD ? (Number(query?.max_prepayment) * usdValue) : Number(query?.max_prepayment) : null,
    min_price: query?.min_price ? currency === USD ? (Number(query?.min_price) * usdValue) : Number(query?.min_price) : null,
    max_price: query?.max_price ? currency === USD ? (Number(query?.max_price) * usdValue) : Number(query?.max_price) : null,
    min_monthly_price: query?.min_monthly_price ? currency === USD ? (Number(query?.min_monthly_price) * usdValue) : Number(query?.min_monthly_price) : null,
    max_monthly_price: query?.max_monthly_price ? currency === USD ? (Number(query?.max_monthly_price) * usdValue) : Number(query?.max_monthly_price) : null,
  }

  const finalQuery = { ...removeObjProperties(query, 'currency', 'type'), ...(clearObj(price)) }
  const { url, fetch } = fetchApartments(finalQuery, id as number)

  return useQuery<Pagination<NewApartmentCardModel>, ErrorRes>({
    queryKey: [queryKeys.APARTMENTS, url],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get classes for select
export function useGetNewPaymentMethods() {
  return useQuery<PaymentMethodsAndClassesType[], ErrorRes>({
    queryKey: [queryKeys.PAYMENT_METHODS],
    queryFn: fetchNewPaymentMethods,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}
