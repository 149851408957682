import React, { useEffect, useRef, useState } from "react";
import { Avatar, RefSelectProps, Select, SelectProps, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useRouter } from "next/router";
import { useGetComplexFilteres, useGetQueryFilteres } from "pages/main-layout/components/region-city/services/queries";
import { filterReducerActions } from "store/reducers/filterReducer";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { bodyOverflowAuto, bodyOverflowHidden } from "utils/helpers/changeBodyOverflow";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
import { clearObj } from "utils/helpers/clearObj";
import { LOCATION } from "utils/constants/queryParamsKeys";
import { makeStaticFilters } from "utils/helpers/makeStaticFilters";
import { layoutLang } from "utils/language/layout";
import Complex1Icon from "assets/icons/Complex1Icon";
import MetroIcon from "assets/icons/MetroIcon";
import CustomImage from "common/custom-image";
import styles from "./filterMetro.module.scss";
import { cx } from "utils/cx";
interface IProps {
  type: "filter" | "header";
  className?: string;
}
const FilterMetro: React.FC<IProps> = ({
  type,
  className
}) => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query,
    pathname,
    push
  } = useRouter();
  const [value, setValue] = useState("");
  const [time, setTime] = useState<any>();
  const {
    data,
    isLoading,
    isFetching
  } = useGetComplexFilteres(value);
  const {
    data: filters
  } = useGetQueryFilteres();
  const {
    setComplexes,
    setMetroLines,
    setNearestPlaces,
    setBrands,
    setFilterSearch
  } = filterReducerActions;
  const staticFilters = makeStaticFilters(query?.apartmentId as string || query?.id as string);
  const complexes = useAppSelector(state => state.filterReducer.complexes);
  const metro_lines = useAppSelector(state => state.filterReducer.metro_lines);
  const nearest_places = useAppSelector(state => state.filterReducer.nearest_places);
  const brands = useAppSelector(state => state.filterReducer.brands);
  const filterSearchValue = useAppSelector(state => state.filterReducer.filterSearchValue);
  useEffect(() => {
    if (filters?.complexes) {
      dispatch(setComplexes(filters?.complexes));
    } else {
      dispatch(setComplexes([]));
    }
    if (filters?.metro_lines) {
      dispatch(setMetroLines(filters?.metro_lines));
    } else {
      dispatch(setMetroLines([]));
    }
    if (filters?.nearest_places || staticFilters?.nearest_place_ids) {
      dispatch(setNearestPlaces(filters?.nearest_places));
    } else {
      dispatch(setNearestPlaces([]));
    }
    if (filters?.brands) {
      dispatch(setBrands(filters?.brands));
    } else {
      dispatch(setBrands([]));
    }
    if (query?.search) {
      dispatch(setFilterSearch(query?.search as string));
    } else {
      dispatch(setFilterSearch(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, query, pathname]);
  const queryIds = {
    complex_ids: complexes?.map(item => item?.id),
    metro_line_ids: metro_lines?.map(item => item?.id),
    nearest_place_ids: nearest_places?.map(item => item?.id),
    brand_ids: brands?.map(item => item?.id)
  };
  const handleSetComplexes = (id: number) => {
    const value = data?.complexes?.find(item => item?.id === id);
    if (complexes) {
      if (complexes?.some(item => item?.id === id)) {
        const newData = complexes?.filter(item => item?.id !== id);
        dispatch(setComplexes(newData));
      } else {
        dispatch(setComplexes([...complexes, value!]));
      }
    } else {
      dispatch(setComplexes([value!]));
    }
  };
  const handleSetMetroLines = (id: number) => {
    const value = data?.metro_lines?.find(item => item?.id === id);
    if (metro_lines) {
      if (metro_lines?.some(item => item?.id === id)) {
        const newData = metro_lines?.filter(item => item?.id !== id);
        dispatch(setMetroLines(newData));
      } else {
        dispatch(setMetroLines([...metro_lines, value!]));
      }
    } else {
      dispatch(setMetroLines([value!]));
    }
  };
  const handleSetNearestPlaces = (id: number) => {
    const value = data?.nearest_places?.find(item => item?.id === id);
    if (nearest_places) {
      if (nearest_places?.some(item => item?.id === id)) {
        const newData = nearest_places?.filter(item => item?.id !== id);
        dispatch(setNearestPlaces(newData));
      } else {
        dispatch(setNearestPlaces([...nearest_places, value!]));
      }
    } else {
      dispatch(setNearestPlaces([value!]));
    }
  };
  const handleSetBrands = (id: number) => {
    const value = data?.brands?.find(item => item?.id === id);
    if (brands) {
      if (brands?.some(item => item?.id === id)) {
        const newData = brands?.filter(item => item?.id !== id);
        dispatch(setBrands(newData));
      } else {
        dispatch(setBrands([...brands, value!]));
      }
    } else {
      dispatch(setBrands([value!]));
    }
  };
  const complexes1 = !isEmptyArr(data?.complexes) ? data?.complexes?.concat(filters?.complexes || [])?.map(item => ({
    id: item?.id,
    label: <div className="flex items-center gap-2" onClick={() => handleSetComplexes(item?.id)}>
          <Complex1Icon />
          <span className="text-[#1D2939] text-[13px]">{item?.name}</span>
        </div>,
    value: `complexes:${item?.id}`
  })) : [];
  const metro_lines1 = !isEmptyArr(data?.metro_lines) ? data?.metro_lines?.concat(filters?.metro_lines || [])?.map(item => ({
    id: item?.id,
    label: <div className="flex items-center gap-2" onClick={() => handleSetMetroLines(item?.id)}>
          <MetroIcon stroke={item?.color} />
          <span className="text-[#1D2939] text-[13px]">
            {item?.name[locale as keyof typeof item.name]}
          </span>
        </div>,
    value: `metro_line_ids:${item?.id}`
  })) : [];
  const nearest_places1 = !isEmptyArr(data?.nearest_places) || !isEmptyArr(filters?.nearest_places) ? data?.nearest_places?.concat(filters?.nearest_places || [])?.map(item => ({
    id: item?.id,
    label: <div className="flex items-center gap-2" onClick={() => handleSetNearestPlaces(item?.id)}>
          <MetroIcon fill={item?.metro_line?.color} />
          <span className="text-[#1D2939] text-[13px]">
            {item?.name[locale as keyof typeof item.name]}
          </span>
        </div>,
    value: `nearest_place_ids:${item?.id}`
  })) : [];
  const brands1 = !isEmptyArr(data?.brands) ? data?.brands?.concat(filters?.brands || [])?.map(item => ({
    id: item?.id,
    label: <div className="flex items-center gap-2" onClick={() => handleSetBrands(item?.id)}>
          {item?.logo ? <div className="min-w-6 h-6 rounded-full overflow-hidden relative">
                <CustomImage alt="metro logo" src={item?.logo} />
              </div> : <div className="min-w-6"><Avatar size={24} icon={item?.name?.charAt(0)} /></div>}
          <span className="text-[#1D2939] text-[13px] truncate">
            {item?.name}
          </span>
        </div>,
    value: `brand_ids:${item?.id}`
  })) : [];
  const uniqueComplex = complexes1?.filter((value, index, self) => index === self.findIndex(t => t.id === value.id && t?.value === value?.value));
  const uniqueMetroLines = metro_lines1?.filter((value, index, self) => index === self.findIndex(t => t.id === value.id && t?.value === value?.value));
  const uniqueNearestPlaces = !isEmptyArr(nearest_places1) ? nearest_places1?.filter((value, index, self) => index === self.findIndex(t => t.id === value.id && t?.value === value?.value)) : [];
  const uniqueBrands = brands1?.filter((value, index, self) => index === self.findIndex(t => t.id === value.id && t?.value === value?.value));
  const options: SelectProps["options"] = [...uniqueComplex!, ...uniqueMetroLines!, ...uniqueNearestPlaces!, ...uniqueBrands!];
  const handleDeleteItem = (id: number, type: "complexes" | "metro_line_ids" | "nearest_places" | "brand_ids") => {
    const newComplexes = complexes?.filter(item => item?.id !== id);
    const newMetroLines = metro_lines?.filter(item => item?.id !== id);
    const newNearestPlaces = nearest_places?.filter(item => item?.id !== id);
    const brandsI = brands?.filter(item => item?.id !== id);
    type === "complexes" ? dispatch(setComplexes(newComplexes!)) : type === "metro_line_ids" ? dispatch(setMetroLines(newMetroLines!)) : type === "brand_ids" ? dispatch(setBrands(brandsI!)) : dispatch(setNearestPlaces(newNearestPlaces!));
  };
  const defValues = [complexes?.map(item => `complexes:${item?.id}`), metro_lines?.map(item => `metro_line_ids:${item?.id}`), nearest_places?.map(item => `nearest_place_ids:${item?.id}`), brands?.map(item => `brand_ids:${item?.id}`)]?.flat(Infinity)?.filter(item => item);
  const handleChangeSearch = (value: string) => {
    dispatch(setFilterSearch(value));
    clearTimeout(time);
    if (value) {
      setTime(setTimeout(() => {
        setValue(value);
      }, 800));
    } else {
      setValue(value);
    }
  };
  const selectRef = useRef<RefSelectProps>(null);
  const condition = filterSearchValue?.length > 0 && isEmptyArr(complexes) && isEmptyArr(metro_lines) && isEmptyArr(nearest_places) && isEmptyArr(brands);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (condition) {
        if (pathname !== '/reels') {
          push({
            query: {
              [LOCATION]: query?.location,
              search: filterSearchValue
            }
          });
        } else {
          push({
            query: {
              search: filterSearchValue
            }
          });
        }
      } else {
        if (pathname !== '/reels') {
          push({
            query: {
              ...removeObjProperties(query, "search"),
              ...clearObj(queryIds)
            }
          });
        } else {
          push({
            query: {
              ...removeObjProperties(query, "search", "complex_ids", 'metro_line_ids', 'brand_ids'),
              ...clearObj(queryIds)
            }
          });
        }
      }
      // for closing the dropdown of the select
      selectRef?.current?.blur();
    }
  };
  return <div className={`${styles.filterMetro} ${type === "filter" ? "h-[43px] rounded-[4px]" : `h-10 rounded-xl ${pathname !== "/reels" ? "min-w-[320px]" : "w-[320px]"}`} overflow-auto border border-solid ${isEmptyArr(defValues) ? 'border-[#d9d9d9]' : 'border-[#00A389]'} ${className}`} data-sentry-component="FilterMetro" data-sentry-source-file="FilterMetro.tsx">
      <Select ref={selectRef} aria-owns={undefined} aria-controls={undefined}
    // getPopupContainer={(triggerNode) => triggerNode.parentNode}
    onSearch={handleChangeSearch} maxTagCount="responsive" mode="multiple" placeholder={layoutLang[locale || 'ru']["filter_text"]} className={cx("w-full h-full", condition && '[&_.ant-select-selection-search-input]:!w-max')} options={options} loading={isLoading} filterOption={false} notFoundContent={isFetching ? <Spin size="small" /> : null} onDeselect={(e: any) => handleDeleteItem(Number(e?.split(":")[1]), e?.split(":")[0])} onDropdownVisibleChange={e => e ? bodyOverflowHidden() : bodyOverflowAuto()} value={defValues} searchValue={filterSearchValue} onKeyDown={handleKeyDown} autoClearSearchValue={false} onChange={() => {
      dispatch(setFilterSearch(''));
    }} data-sentry-element="Select" data-sentry-source-file="FilterMetro.tsx" />
    </div>;
};
export default FilterMetro;