import ShimmerImage from 'common/shimmer-image';
import { useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { layoutLang } from 'utils/language/layout';
import priseImg from '../../../assets/images/prise-img.webp';
interface DiscountType {
  id: number;
  text: {
    uz: string;
    ru: string;
  };
  name: string;
  value: string;
}
interface IProps {
  setDiscount: React.Dispatch<React.SetStateAction<{
    name: string;
    value: string;
  }[] | undefined>>;
  discount: {
    name: string;
    value: string;
  }[] | undefined;
}
const rowClass = "grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4";
export const discounts: DiscountType[] = [{
  id: 1,
  text: {
    uz: "Chegirmali",
    ru: "Со скидкой"
  },
  name: "discount",
  value: "true"
}, {
  id: 2,
  text: {
    uz: "UYSOT bonus",
    ru: "UYSOT бонус"
  },
  name: "uysot_bonus",
  value: "true"
}];
const DiscountItem: React.FC<IProps> = ({
  discount,
  setDiscount
}) => {
  const {
    locale,
    query
  } = useRouter();
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  useEffect(() => {
    if (query?.discount) setDiscount([{
      name: 'discount',
      value: 'true'
    }]);
    if (query?.uysot_bonus) setDiscount([{
      name: 'uysot_bonus',
      value: 'true'
    }]);
    if (query?.uysot_bonus && query?.discount) setDiscount([{
      name: 'uysot_bonus',
      value: 'true'
    }, {
      name: 'discount',
      value: 'true'
    }]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  const handleChangeDiscounts = (item: DiscountType) => {
    if (discount) {
      const isHas = discount.some(elem => elem?.name === item?.name);
      const newData = discount?.filter(el => el?.name !== item?.name);
      if (isHas) {
        setDiscount(newData);
      } else {
        setDiscount([...discount, {
          name: item?.name,
          value: item?.value
        }]);
      }
    } else {
      setDiscount([{
        name: item?.name,
        value: item?.value
      }]);
    }
  };
  return <div className={rowClass} data-sentry-component="DiscountItem" data-sentry-source-file="discount-item.tsx">
      {layoutLang[locale || 'ru']["Chegirma va bonuslar"]}
      <div className="flex items-center gap-2">
        {discounts?.map(item => <div key={item?.id} className={`h-[37px] px-4 rounded-[4px] flex items-center justify-center gap-2 text-base font-medium cursor-pointer transition-all duration-300 
                ${discount?.some(el => el?.name === item?.name) ? "text-[#00A389] bg-[#e7fbf5]" : 'bg-[#F2F4F7]'}`} onClick={() => handleChangeDiscounts(item)}>
            <span>{item?.text[locale as keyof typeof item.text]}</span>
            {item?.name === "uysot_bonus" ? <div className="w-5 h-5">
                <ShimmerImage alt='prise-image' src={priseImg} title='prise-image' />
              </div> : ''}

          </div>)}
      </div>
    </div>;
};
export default DiscountItem;