import ReactInputMask from "react-input-mask";
type Props = {
  mask: string;
  placeholder?: string;
  type?: string;
  className?: string;
  id?: string;
  ariaRequired?: boolean;
};
export const InputMask: React.FC<Props> = ({
  mask,
  placeholder,
  type,
  className,
  id,
  ariaRequired
}) => {
  return (
    // @ts-ignore
    <ReactInputMask id={id} aria-required={ariaRequired} mask={mask}
    // @ts-ignore
    maskChar="" className={className ? className : type === "quiz" ? "h-11 rounded-xl bg-[#F2F4F7] border-none ant-input" : "ant-input"} placeholder={placeholder} data-sentry-element="ReactInputMask" data-sentry-component="InputMask" data-sentry-source-file="InputMask.tsx" />
  );
};