import { Button, Form, FormInstance } from "antd";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import ArrowRightIcon from "../../../../../assets/icons/ArrowRightIcon";
import { buttonType } from "../../../../../utils/constants/buttonType";
import { SUBMIT } from "../../../../../utils/constants/htmlTypes";
import { lengthValidator } from "../../../../../utils/helpers/lengthValidator";
import Countdown from "../countdown/Countdown";
import styles from "../loginModal.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "../../../../../utils/language/layout";
type Props = {
  formInstance: FormInstance;
  setStep: (step: number) => void;
  isActive: boolean;
  isLoading: boolean;
  step: number;
};
const {
  Item
} = Form;
const Confirmation: React.FC<Props> = ({
  formInstance,
  setStep,
  isActive,
  isLoading,
  step
}) => {
  const {
    locale
  } = useRouter();
  const [disableSubmit, setDisableSubmit] = useState(true);

  // handle prev
  const handlePrev = () => {
    setStep(1);
    formInstance.resetFields(["code"]);
  };

  // phone number
  const phoneNumber = formInstance.getFieldValue("phone");

  // handle  success
  const handleSuccess = () => {
    if (disableSubmit) setDisableSubmit(false);
  };

  // handle error
  const handleError = () => {
    if (!disableSubmit) setDisableSubmit(true);
  };
  return <div className={`${styles.login_modal_confirmation} ${isActive ? styles.active : ""}`} data-sentry-component="Confirmation" data-sentry-source-file="Confirmation.tsx">
      <p className={styles.code_sended_phone}>{locale === 'uz' ? `Kod ${phoneNumber} raqamiga yuborildi` : `Код отправлен на ${phoneNumber}`}</p>
      <p className={styles.change_phone} onClick={handlePrev} aria-hidden>
        <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="Confirmation.tsx" />
        <span>{layoutLang[locale || 'ru']['Изменить номер']}</span>
      </p>
      <div className={styles.login_modal_code}>
        <Item name={`sms_code`} rules={step === 2 ? [lengthValidator(5, handleSuccess, handleError)] : []} data-sentry-element="Item" data-sentry-source-file="Confirmation.tsx">
          <OtpInput numInputs={5} className={styles.otp} isInputNum shouldAutoFocus data-sentry-element="OtpInput" data-sentry-source-file="Confirmation.tsx" />
        </Item>
      </div>
      <Countdown phoneNumber={phoneNumber} data-sentry-element="Countdown" data-sentry-source-file="Confirmation.tsx" />
      <Button className={styles.submit_btn} htmlType={SUBMIT} type={buttonType.PRIMARY} disabled={disableSubmit} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="Confirmation.tsx">
        {layoutLang[locale || 'ru']["Подтвердить"]}
      </Button>
    </div>;
};
export default Confirmation;