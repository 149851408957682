export const sub_titles = {
  price: {
    uz: "Eng arzon m² yangi binolar",
    ru: "Самая низкая цена за м² Новостройки",
  },
  area: {
    uz: "Eng kichik m² xonadonlar",
    ru: "Самые маленькие квартиры по площади (м²)",
  },
  total_price: { uz: "Eng arzon yangi binolar", ru: "Самые дешевые Новостройки" },
  prepayment: {
    uz: "Eng past boshlang’ich to‘lovli yangi binolar",
    ru: "Новостройки с низким первоначальным взносом",
  },
  delay: {
    uz: "Eng uzoq muddatli to‘lovli yangi binolar",
    ru: "Новостройки с самой долгой рассрочкой",
  },
  discount_percent: {
    uz: "Eng yuqori chegirmali yangi binolar",
    ru: "Новостройки с максимальными скидками",
  },
  monthly_price: {
    uz: "Eng past oylik to'lovli yangi binolar",
    ru: "Новостройки с низкими ежемесячными платежами",
  },
  created_time:{
    ru:"Новое в Uysot",
    uz:"Yangi qo'shilgan binolar"
  }
}
export const hot_sub_titles = {
  price: {
    uz: "M² narxi",
    ru: "Цена за м²",
  },
  prepayment: {
    uz: "Boshlang’ich to‘lov",
    ru: "Первоначальный взнос",
  },
  delay: {
    uz: "Muddati",
    ru: "Срок",
  },
  monthly_price: {
    uz: "Oylik to‘lov",
    ru: "Ежемесячный платеж",
  }
}

export const sub_apartment_titles = {
  price: {
    uz: "Eng arzon m² kvartiralar",
    ru: "Самая низкая цена за м² квартиры",
  },
  area: {
    uz: "Eng kichik m² kvartiralar",
    ru: "Самые маленькие квартиры по площади (м²)",
  },
  total_price: {
    uz: "Eng arzon kvartiralar",
    ru: "Самые дешевые квартиры"
  },
  prepayment: {
    uz: "Eng past boshlang’ich to‘lovli kvartiralar",
    ru: "Квартиры с низким первоначальным взносом",
  },
  delay: {
    uz: "Eng uzoq muddatli to‘lovli kvartiralar",
    ru: "Квартиры с самой долгой рассрочкой",
  },
  discount_percent: {
    uz: "Eng yuqori chegirmali kvartiralar",
    ru: "Квартиры с максимальными скидками",
  },
  monthly_price: {
    uz: "Eng past oylik to'lovli kvartiralar",
    ru: "Квартиры с низкими ежемесячными платежами",
  },
  created_time:{
    ru:"Новое в Uysot",
    uz:"Yangi qo'shilgan binolar"
  }
}