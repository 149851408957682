import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { favouritesReducerActions } from 'store/reducers/favouritesReducer';
import { repairedItems } from '../filter-desktop-modal/filterItems';
import { layoutLang } from 'utils/language/layout';
const RepairedItem = () => {
  const dispatch = useAppDispatch();
  const {
    locale,
    query
  } = useRouter();
  const repaired = useAppSelector(state => state.favouritesReducer.filter.repaired);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    setRepairedAndDiscount,
    setRepaired
  } = favouritesReducerActions;
  const data = repairedItems;
  const handleAdd = (value: string) => {
    dispatch(setRepairedAndDiscount({
      type: "repaired",
      value
    }));
  };
  useEffect(() => {
    if (query?.repaired) {
      dispatch(setRepaired(query?.repaired as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, openDesktopModal]);
  return <div className='grid grid-cols-[180px_auto_auto] gap-2 items-center text-left text-[13px] leading-4' data-sentry-component="RepairedItem" data-sentry-source-file="repaired-item.tsx">
      {layoutLang[locale || 'ru']['Тип жилья']}

      <div className="flex items-center gap-2">
        {data?.map(item => <div key={item?.id} className={`h-10 px-[23px] flex items-center justify-center text-base font-medium cursor-pointer rounded-sm hover:bg-[#e7fbf5] hover:text-[#00A389] transition-all duration-300 bg-[#f2f4f7] text-[#000000D9] 
          ${repaired?.includes(item?.value) ? '!bg-[#e7fbf5] !text-[#00A389]' : 'bg-[#f2f4f7] text-[#000000D9]'}`} onClick={() => handleAdd(item?.value)}>
            {item?.name[locale as keyof typeof item.name]}
          </div>)}
      </div>
    </div>;
};
export default RepairedItem;