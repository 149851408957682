import ReactInputMask from "react-input-mask";
type Props = {
  mask: string;
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  borderStatus?: boolean;
};
export const InputMaskCopy: React.FC<Props> = ({
  mask,
  placeholder,
  type,
  onChange,
  value,
  borderStatus
}) => {
  return (
    // @ts-ignore
    <ReactInputMask mask={mask}
    // @ts-ignore
    maskChar="" className={type === "quiz" ? `h-11 rounded-xl bg-[#F2F4F7] !shadow-none ${borderStatus ? "!border-red-500" : "border-none"} ant-input` : "ant-input"} onChange={onChange} value={value} placeholder={placeholder} data-sentry-element="ReactInputMask" data-sentry-component="InputMaskCopy" data-sentry-source-file="InputMaskCopy.tsx" />
  );
};