import React, { useEffect, useState } from "react";
import { QuizStateModel } from "../utils/models/quizStateModel";
import { layoutLang } from "utils/language/layout";
import { useRouter } from "next/router";
import { InputMaskCopy } from "common/inputMask/InputMaskCopy";
import { PHONE_MASK } from "utils/constants/inputMasks";
import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon";
import { Button, Input, message } from "antd";
import { minPrices } from "../utils/constants/quizConstants";
import { PaymentModel } from "../utils/models/paymentModel";
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel";
import { SendAppModel } from "../utils/models/sendAppModel";
import { useSendApplication } from "../services/mutation";
interface IProps {
  quizState: QuizStateModel;
  setQuizState: React.Dispatch<React.SetStateAction<QuizStateModel>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  finalPayment: PaymentModel[];
  total_amount: string;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
  roomsCount: number[];
  setQuizData: React.Dispatch<React.SetStateAction<NewResidentalComplexModel[]>>;
}
const QuizComponent6: React.FC<IProps> = ({
  page,
  quizState,
  setPage,
  setQuizState,
  finalPayment,
  total_amount,
  setStatus,
  roomsCount,
  setQuizData
}) => {
  const {
    locale
  } = useRouter();
  const sendApp = useSendApplication();
  const [borderStatus, setBorderStatus] = useState({
    name: false,
    phone: false
  });
  const handleSendApp = () => {
    const sendData: SendAppModel = quizState?.payment_method_id === 2 ? {
      city_id: Number(quizState?.city_id),
      diapason_monthly_amount: String(minPrices?.find(item => quizState?.monthly_amount === item?.value)?.label),
      diapason_total_amount: String(finalPayment?.find(item => item?.value === total_amount)?.label),
      name: String(quizState?.name),
      payment_method_id: Number(quizState?.payment_method_id),
      phone: `+${String(quizState?.phone)?.replace(/\D/g, "")}`,
      rooms_count: roomsCount?.join(",")
    } : quizState?.payment_method_id !== -1 ? {
      city_id: Number(quizState?.city_id),
      diapason_total_amount: String(finalPayment?.find(item => item?.value === total_amount)?.label),
      name: String(quizState?.name),
      payment_method_id: Number(quizState?.payment_method_id),
      phone: `+${String(quizState?.phone)?.replace(/\D/g, "")}`,
      rooms_count: roomsCount?.join(",")
    } : {
      city_id: Number(quizState?.city_id),
      diapason_total_amount: String(finalPayment?.find(item => item?.value === total_amount)?.label),
      name: String(quizState?.name),
      phone: `+${String(quizState?.phone)?.replace(/\D/g, "")}`,
      rooms_count: roomsCount?.join(",")
    };

    // eslint-disable-next-line no-empty
    if (Number(quizState?.phone?.length) > 11) {
      setBorderStatus(state => ({
        ...state,
        phone: false
      }));
    } else {
      setBorderStatus(state => ({
        ...state,
        phone: true
      }));
    }
    if (Number(quizState?.name?.length) < 1) {
      setBorderStatus(state => ({
        ...state,
        name: true
      }));
    } else {
      setBorderStatus(state => ({
        ...state,
        name: false
      }));
    }
    Number(quizState?.phone?.length) > 11 && Number(quizState?.name?.length) > 0 && sendApp.mutateAsync(sendData).then(res => {
      message.success(layoutLang[locale || 'ru']["muvaffaqqiyatli"]);
      if (res?.message?.uz === "Muvaffaqiyatli") {
        setStatus(true);
      }
      setQuizData(res?.data);
    });
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleSendApp();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizState, finalPayment, total_amount, roomsCount]);
  return <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0" data-sentry-component="QuizComponent6" data-sentry-source-file="QuizComponent6.tsx">
      <div className="min-h-[305px]">
        <p className="text-[#98A2B3] text-base font-medium mb-2">
          {layoutLang[locale || 'ru']["oxirgi_savol"]}
        </p>
        <h2 className="text-[#344054] text-2xl font-medium">
          {layoutLang[locale || 'ru']["ism_telefon_raqam"]}
        </h2>
        <p className="text-[#98A2B3] text-base font-medium">
          {layoutLang[locale || 'ru']["ism_telefon_raqam_text"]}
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
          <div className="flex flex-col gap-1.5">
            <span className="text-[#344054] text-sm font-medium">
              {layoutLang[locale || 'ru']["FISH"]}
            </span>
            <Input className={`h-11 rounded-xl bg-[#F2F4F7] ${borderStatus?.name ? "!border-red-500" : "border-none"}`} placeholder={layoutLang[locale || 'ru']["FISH"]} value={quizState?.name} onChange={e => {
            setQuizState(state => ({
              ...state,
              name: e.target.value
            }));
            if (e?.target?.value?.length > 0) {
              setBorderStatus(state => ({
                ...state,
                name: false
              }));
            } else {
              setBorderStatus(state => ({
                ...state,
                name: true
              }));
            }
          }} data-sentry-element="Input" data-sentry-source-file="QuizComponent6.tsx" />
          </div>
          <div className="flex flex-col gap-1.5">
            <span className="text-[#344054] text-sm font-medium">
              {layoutLang[locale || 'ru']["Номер телефона"]}
            </span>
            {InputMaskCopy({
            mask: PHONE_MASK,
            placeholder: "+998 00 000 00 00",
            type: "quiz",
            borderStatus: borderStatus?.phone,
            onChange: e => {
              setQuizState(state => ({
                ...state,
                phone: e.target.value?.replace(/\D/g, "")
              }));
              if (e?.target?.value?.length > 18) {
                setBorderStatus(state => ({
                  ...state,
                  phone: false
                }));
              } else {
                setBorderStatus(state => ({
                  ...state,
                  phone: true
                }));
              }
            },
            value: quizState?.phone
          })}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-3 w-full mt-[68px]">
        <div onClick={() => quizState?.payment_method_id === 2 ? setPage(page - 1) : setPage(page - 2)} className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base">
          <JKArrowLeftIcon fill="#475467" width={18} height={14} data-sentry-element="JKArrowLeftIcon" data-sentry-source-file="QuizComponent6.tsx" />
          <span className="text-base text-[#475467] font-medium">
            {layoutLang[locale || 'ru']["ortga"]}
          </span>
        </div>
        <Button type="primary" className="w-full h-[47px] rounded-xl flex items-center justify-center text-base" onClick={handleSendApp} loading={sendApp?.isLoading} data-sentry-element="Button" data-sentry-source-file="QuizComponent6.tsx">
          {layoutLang[locale || 'ru']["ariza_yuborish"]}
        </Button>
      </div>
    </div>;
};
export default QuizComponent6;