import { staticUrls } from "utils/constants/staticUrls"

export function makeStaticFilters(url: string) {
  let o: any = {}
  Object.keys(staticUrls).forEach((key) => {
    if (url?.includes(key)) {
      o = {
        ...o,
        ...staticUrls[key]
      }
    }
  })
  return o
}