import { useQuery, useQueryClient } from "@tanstack/react-query"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { NewResidentalComplexModel } from "../../../utils/models/residentialComplexModel"
import { ErrorRes } from "../../../utils/models/responseType"
import { queryKeys } from "../utils/constants/queryKeys"
import {
  fetchBanners,
  fetchDailyActiveUserCoins,
  fetchNewComplexesOfBuilder,
  fetchNewSimilarApartments,
  fetchNewSimilarComplexes,
} from "./queryFunctions"
import { BannerObjectType } from "../utils/models/banner"
import { endpoints } from "../utils/constants/endpoints"
import { Pagination } from "utils/models/pagination"
import { NewApartmentCardModel } from "utils/models/apartmentModel"
import { DailyActiveUserCoinModel } from "../utils/models/daily-active-user-coin-model"
import $api from "services/RequestService"

export function useGetNewSimilarComplexes(
  id?: string | null,
  type?: string | null,
  payment_method_ids?: number[] | null,
  article_id?: number | null
) {
  const { fetch } = fetchNewSimilarComplexes(id, payment_method_ids, article_id)

  return useQuery<NewResidentalComplexModel[], ErrorRes>({
    queryKey: [
      queryKeys.NEW_SIMILAR_COMPLEXES,
      id || null,
      payment_method_ids || null,
      article_id || null,
    ],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
    enabled: type !== "apartment_id",
  })
}
export function useGetNewSimilarApartments(
  id?: string | null,
  type?: string,
  payment_method_ids?: number[] | null,
  article_id?: number | null
) {
  const { fetch } = fetchNewSimilarApartments(
    id,
    payment_method_ids,
    article_id
  )
  return useQuery<NewApartmentCardModel[], ErrorRes>({
    queryKey: [
      queryKeys.NEW_SIMILAR_APARTMENTS,
      id || null,
      payment_method_ids || null,
      article_id || null
    ],
    queryFn: fetch,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
    enabled: type !== "complex_id",
  })
}

export function useGetNewComplexesOfBuilder(id: string, builderId: number) {
  return useQuery<Pagination<NewResidentalComplexModel>, ErrorRes>({
    queryKey: [queryKeys.NEW_COMPLEXES_OF_BUILDER, id, builderId],
    queryFn: () => fetchNewComplexesOfBuilder(id, builderId),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

// get banners
export function useBanners(page_type?: string, lang?: string) {
  return useQuery<BannerObjectType, ErrorRes>({
    queryKey: [queryKeys.BANNER({ lang, page_type })],
    queryFn: () => fetchBanners({ page_type, lang }),
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
  })
}

export function useBannerSendView(uuid?: string) {
  const qc = useQueryClient()
  return useQuery({
    queryKey: [queryKeys.BANNER_VIEW(uuid)],
    queryFn: async () => {
      if (qc.getQueryData([queryKeys.BANNER_VIEW(uuid)])) {
        const res = await Promise.all([])
        return res
      } else {
        const res = await $api.get(
         endpoints.API_BANNER_VIEW_UUID(uuid)
        )
        return res.data
      }
    },
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler,
    staleTime: Infinity,
  })
}

export function useGetDailyActiveUserCoins(enabled:boolean){
  return useQuery<DailyActiveUserCoinModel,ErrorRes>({
    queryKey:[queryKeys.DAILY_ACTIVE_USER_COINS],
    queryFn:()=>fetchDailyActiveUserCoins(),
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorHandler,
  })
}