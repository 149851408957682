export const staticLinks = [
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/novostroyki',
      ru: 'https://uysot.uz/andijan/novostroyki'
    },
    label: {
      uz: 'Andijon viloyatidagi yangi binolar',
      ru: 'Новостройки в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/andijan/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/andijan/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/andijan/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/andijan/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/andijan/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Andijon viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/novostroyki',
      ru: 'https://uysot.uz/buxara/novostroyki'
    },
    label: {
      uz: 'Buxoro viloyatidagi yangi binolar',
      ru: 'Новостройки в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/buxara/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/buxara/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/buxara/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/buxara/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/buxara/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Buxoro viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/novostroyki',
      ru: 'https://uysot.uz/kashkadarya/novostroyki'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi yangi binolar',
      ru: 'Новостройки в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/kashkadarya/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/kashkadarya/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/kashkadarya/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/kashkadarya/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/kashkadarya/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Qashqadaryo viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/novostroyki',
      ru: 'https://uysot.uz/navoi/novostroyki'
    },
    label: {
      uz: 'Navoiy viloyatidagi yangi binolar',
      ru: 'Новостройки в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/navoi/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/navoi/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/navoi/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/navoi/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/navoi/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Navoiy viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/novostroyki',
      ru: 'https://uysot.uz/namangan/novostroyki'
    },
    label: {
      uz: 'Namangan viloyatidagi yangi binolar',
      ru: 'Новостройки в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/namangan/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/namangan/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/namangan/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/namangan/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/namangan/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Namangan viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/novostroyki',
      ru: 'https://uysot.uz/samarkand/novostroyki'
    },
    label: {
      uz: 'Samarqand viloyatidagi yangi binolar',
      ru: 'Новостройки в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/samarkand/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/samarkand/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/samarkand/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/samarkand/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/samarkand/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Samarqand viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/novostroyki',
      ru: 'https://uysot.uz/sirdarya/novostroyki'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi yangi binolar',
      ru: 'Новостройки в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/sirdarya/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/sirdarya/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/sirdarya/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/sirdarya/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/sirdarya/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Sirdaryo viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/novostroyki',
      ru: 'https://uysot.uz/fergana/novostroyki'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi yangi binolar',
      ru: 'Новостройки в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/fergana/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/fergana/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/fergana/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/fergana/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/fergana/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Farg‘ona viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/novostroyki',
      ru: 'https://uysot.uz/tashkent/novostroyki'
    },
    label: {
      uz: 'Toshkent viloyatidagi yangi binolar',
      ru: 'Новостройки в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/tashkent/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/tashkent/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/tashkent/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/tashkent/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/tashkent/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Toshkent viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent/novostroyki'
    },
    label: {
      uz: 'Toshkent shahridagi yangi binolar',
      ru: 'Новостройки в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Toshkent shahridagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/novostroyki',
      ru: 'https://uysot.uz/xorezm/novostroyki'
    },
    label: {
      uz: 'Xorazm viloyatidagi yangi binolar',
      ru: 'Новостройки в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/xorezm/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/xorezm/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/xorezm/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/xorezm/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/xorezm/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Xorazm viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/novostroyki',
      ru: 'https://uysot.uz/djizak/novostroyki'
    },
    label: {
      uz: 'Jizzax viloyatidagi yangi binolar',
      ru: 'Новостройки в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/djizak/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 1 xonali yangi binolar',
      ru: '1-комнатные новостройки в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/djizak/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 2 xonali yangi binolar',
      ru: '2-комнатные новостройки в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/djizak/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 3 xonali yangi binolar',
      ru: '3-комнатные новостройки в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/djizak/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 4 xonali yangi binolar',
      ru: '4-комнатные новостройки в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/djizak/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Jizzax viloyatidagi ko'p xonali yangi binolar",
      ru: 'Многокомнатные новостройки в Джизакской области'
    }
  },

  // kvartiri

  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/kvartiri',
      ru: 'https://uysot.uz/andijan/kvartiri'
    },
    label: {
      uz: 'Andijon viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/andijan/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/andijan/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/andijan/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/andijan/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Andijon viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/andijan/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/andijan/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Andijon viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Андижанской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/kvartiri',
      ru: 'https://uysot.uz/buxara/kvartiri'
    },
    label: {
      uz: 'Buxoro viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/buxara/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/buxara/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/buxara/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/buxara/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Buxoro viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/buxara/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/buxara/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Buxoro viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Бухарской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/kvartiri',
      ru: 'https://uysot.uz/kashkadarya/kvartiri'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/kashkadarya/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/kashkadarya/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/kashkadarya/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/kashkadarya/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Qashqadaryo viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/kashkadarya/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/kashkadarya/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Qashqadaryo viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Кашкадарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/kvartiri',
      ru: 'https://uysot.uz/navoi/kvartiri'
    },
    label: {
      uz: 'Navoiy viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/navoi/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/navoi/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/navoi/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/navoi/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Navoiy viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/navoi/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/navoi/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Navoiy viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Навоийской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/kvartiri',
      ru: 'https://uysot.uz/namangan/kvartiri'
    },
    label: {
      uz: 'Namangan viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/namangan/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/namangan/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/namangan/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/namangan/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Namangan viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/namangan/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/namangan/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Namangan viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Наманганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/kvartiri',
      ru: 'https://uysot.uz/samarkand/kvartiri'
    },
    label: {
      uz: 'Samarqand viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/samarkand/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/samarkand/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/samarkand/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/samarkand/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Samarqand viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/samarkand/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/samarkand/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Samarqand viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Самаркандской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/kvartiri',
      ru: 'https://uysot.uz/sirdarya/kvartiri'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/sirdarya/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/sirdarya/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/sirdarya/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/sirdarya/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Sirdaryo viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/sirdarya/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/sirdarya/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Sirdaryo viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Сырдарьинской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/kvartiri',
      ru: 'https://uysot.uz/fergana/kvartiri'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/fergana/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/fergana/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/fergana/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/fergana/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Farg‘ona viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/fergana/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/fergana/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Farg‘ona viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Ферганской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/kvartiri',
      ru: 'https://uysot.uz/tashkent/kvartiri'
    },
    label: {
      uz: 'Toshkent viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/tashkent/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/tashkent/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/tashkent/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/tashkent/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Toshkent viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/tashkent/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/tashkent/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Toshkent viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Ташкентской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent/kvartiri'
    },
    label: {
      uz: 'Toshkent shahridagi yangi kvartiralar',
      ru: 'Новые квартиры в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Toshkent shahridagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Toshkent shahridagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в городе Ташкент'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/kvartiri',
      ru: 'https://uysot.uz/xorezm/kvartiri'
    },
    label: {
      uz: 'Xorazm viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/xorezm/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/xorezm/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/xorezm/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/xorezm/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Xorazm viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/xorezm/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/xorezm/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Xorazm viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Хорезмской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/kvartiri',
      ru: 'https://uysot.uz/djizak/kvartiri'
    },
    label: {
      uz: 'Jizzax viloyatidagi yangi kvartiralar',
      ru: 'Новые квартиры в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/djizak/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/djizak/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/djizak/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/djizak/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Jizzax viloyatidagi 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Джизакской области'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/djizak/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/djizak/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Jizzax viloyatidagi ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Джизакской области'
    }
  },


  // tashkent regions

  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Yunusobod tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Yunusobod tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Mirobod tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Mirobod tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Yakkasaroy tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Yakkasaroy tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Olmazor tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Olmazor tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Bektemir tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Bektemir tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Yashnobod tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Yashnobod tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Chilonzor tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Chilonzor tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Uchtepa tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Uchtepa tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Mirzo Ulug‘bek tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Sergeli tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Sergeli tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Shayxontohur tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Shayxontohur tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri'
    },
    label: {
      uz: 'Yangihayot tumanidan yangi kvartiralar',
      ru: 'Новые квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/odnokomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 1 xonali kvartiralar',
      ru: '1-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/dvuhkomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 2 xonali kvartiralar',
      ru: '2-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/trehkomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 3 xonali kvartiralar',
      ru: '3-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 4 xonali kvartiralar',
      ru: '4-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/kvartiri/mnogokomnatnye'
    },
    label: {
      uz: "Yangihayot tumanidan ko'p xonali kvartiralar",
      ru: 'Многокомнатные квартиры в Янгихаётского района'
    }
  },

  // novostroyki

  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Yunusobod tumanidan yangi binolar',
      ru: 'Новостройки в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yunusobod tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yunusabadskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Yunusobod tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Юнусабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Mirobod tumanidan yangi binolar',
      ru: 'Новостройки в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Mirobod tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirabadskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Mirobod tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Мирабадского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Yakkasaroy tumanidan yangi binolar',
      ru: 'Новостройки в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yakkasaroy tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yakkasarayskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Yakkasaroy tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Яккасарайского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Olmazor tumanidan yangi binolar',
      ru: 'Новостройки в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Olmazor tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-almazarskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-almazarskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Olmazor tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Алмазарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Bektemir tumanidan yangi binolar',
      ru: 'Новостройки в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Bektemir tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-bektemirskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Bektemir tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Бектемирского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Yashnobod tumanidan yangi binolar',
      ru: 'Новостройки в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yashnobod tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yashnobodskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Yashnobod tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Яшнободского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Chilonzor tumanidan yangi binolar',
      ru: 'Новостройки в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Chilonzor tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-chilanzarskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Chilonzor tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Чиланзарского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Uchtepa tumanidan yangi binolar',
      ru: 'Новостройки в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Uchtepa tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-uchtepinskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Uchtepa tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Учтепинского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan yangi binolar',
      ru: 'Новостройки в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Mirzo Ulug‘bek tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-mirzo-ulugbekskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Mirzo Ulug‘bek tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Мирзо-Улугбекского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Sergeli tumanidan yangi binolar',
      ru: 'Новостройки в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Sergeli tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-sergeliyskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Sergeli tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Сергелийского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Shayxontohur tumanidan yangi binolar',
      ru: 'Новостройки в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Shayxontohur tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-shayhantahurskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Shayxontohur tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Шайхантахурского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki'
    },
    label: {
      uz: 'Yangihayot tumanidan yangi binolar',
      ru: 'Новостройки в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/odnokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/odnokomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 1 xonali yangi binolar',
      ru: '1-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/dvuhkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/dvuhkomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 2 xonali yangi binolar',
      ru: '2-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/trehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/trehkomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 3 xonali yangi binolar',
      ru: '3-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/chetyrehkomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/chetyrehkomnatnye'
    },
    label: {
      uz: 'Yangihayot tumanidan 4 xonali yangi binolar',
      ru: '4-комнатные квартиры в Янгихаётского района'
    }
  },
  {
    link: {
      uz: 'https://uysot.uz/uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/mnogokomnatnye',
      ru: 'https://uysot.uz/gorod-tashkent-yangihaetskiy-rayon/novostroyki/mnogokomnatnye'
    },
    label: {
      uz: "Yangihayot tumanidan ko'p xonali yangi binolar",
      ru: 'Многокомнатные квартиры в Янгихаётского района'
    }
  },

]