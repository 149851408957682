import React from 'react';
type Props = {
  rotate?: number;
  fill?: string;
};
const SwiperArrowIcon: React.FC<Props> = ({
  rotate,
  fill
}) => {
  return <svg style={{
    transform: `rotate(${rotate}deg)`
  }} width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SwiperArrowIcon" data-sentry-source-file="swiper-arrow-icon.tsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.92964 19.2477C10.3979 19.7165 11.1577 19.717 11.6267 19.2487C12.0955 18.7804 12.096 18.0205 11.6278 17.5516L5.28372 11.1996L22.8 11.1996C23.4628 11.1996 24 10.6624 24 9.99963C24 9.33686 23.4628 8.79962 22.8 8.79962L5.30508 8.79962L11.6491 2.44766C12.1174 1.9787 12.1169 1.21887 11.648 0.750505C11.1791 0.282267 10.4192 0.282745 9.95088 0.751587L1.55088 9.16226L0.704041 10.0103L1.55088 10.8583L9.92964 19.2477Z" fill={fill || "black"} data-sentry-element="path" data-sentry-source-file="swiper-arrow-icon.tsx" />
    </svg>;
};
export default SwiperArrowIcon;