import React, { useState, useEffect } from "react";
import { Button, Modal, message } from "antd";
import { isBrowser } from "../../../../utils/helpers/isBrowser";
import { urlEncode } from "../../../../utils/helpers/urlEnCode";
import { layoutLang } from "../../../../utils/language/layout";
import { useRouter } from "next/router";
import { useCreateUserInteractions } from "pages/detailed-magazine/service/mutations";
import { sotialItems } from "pages/main-layout/components/mobile-menu/MobileMenu";
import styles from "./shareModal.module.scss";
type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  copyUrl?: string;
};
const ShareModal: React.FC<Props> = ({
  open,
  setOpen,
  copyUrl
}) => {
  const {
    locale,
    query,
    pathname
  } = useRouter();
  const userInteraction = useCreateUserInteractions();
  const [code, setCode] = useState("");
  useEffect(() => {
    if (copyUrl) {
      setCode(copyUrl);
    }
  }, [copyUrl]);
  const articleId = Number(query?.id?.toString()?.split("-")[query?.id?.toString()?.split("-")?.length - 1]);
  const idKey = pathname?.includes('novostroyki') ? 'complex_id' : pathname?.includes("kvartiri") ? "apartment_id" : "article_id";
  useEffect(() => {
    if (isBrowser()) {
      setCode(window.location.href);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrowser()]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleInteraction = (platform: string) => {
    userInteraction.mutate({
      interaction_type: "share",
      platform,
      [idKey as string]: articleId
    });
  };
  const copyOperation = () => {
    navigator.clipboard.writeText(copyUrl ?? code).then(() => {
      message.success({
        content: layoutLang[locale || 'ru']["Nusxa olindi"]
      }).then(() => handleInteraction("copy"));
    });
  };
  return <Modal title={layoutLang[locale || 'ru']["Ulashish"]} open={open} onCancel={handleClose} footer={null} className={styles.container} centered wrapClassName="z-[100001]" maskStyle={{
    zIndex: 100001
  }} data-sentry-element="Modal" data-sentry-component="ShareModal" data-sentry-source-file="ShareModal.tsx">
      <div className={styles.container_wrapper}>
        {sotialItems?.filter(item => item?.shareUrl)?.map(item => <a key={item?.id} className="flex h-[60px] w-[64px] cursor-pointer items-center justify-center rounded-full bg-[#F2F4F7]" target="_blank" href={`${item?.shareUrl}${urlEncode(copyUrl ?? code)}`} onClick={() => handleInteraction(String(item?.platform))}>
              {item?.icon()}
            </a>)}
      </div>
      <div className={`${styles.container_copy} flex !flex-nowrap items-center`}>
        <span className="truncate">{copyUrl ?? code}</span>
        <Button type="primary" onClick={copyOperation} data-sentry-element="Button" data-sentry-source-file="ShareModal.tsx">
          {layoutLang[locale || 'ru']["Nusxa olish"]}
        </Button>
      </div>
    </Modal>;
};
export default ShareModal;