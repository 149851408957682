export const minPrice = [200000000, 400000000, 600000000]
export const minUSDPrice = [20000, 40000, 60000]
export const maxPrice = [400000000, 600000000, 800000000]
export const maxUSDPrice = [40000, 60000, 80000]
export const minPerPrice = [3000000, 5000000, 7000000, 10000000]
export const minUSDPerPrice = [300, 500, 700, 1000]
export const maxPerPrice = [4000000, 6000000, 8000000, 10000000]
export const maxUSDPerPrice = [400, 600, 800, 1000]
export const maxInitPrice = [30000000, 50000000, 100000000, 200000000]
export const maxUSDInitPrice = [3000, 5000, 10000, 20000]
export const minInitPrice = [50000000, 60000000, 80000000, 120000000]
export const minUSDInitPrice = [5000, 6000, 8000, 12000]
export const maxMonthlyPrice = [4000000, 6000000, 8000000, 10000000]
export const maxUSDMonthlyPrice = [400, 600, 800, 1000]
export const minMonthlyPrice = [5000000, 8000000, 10000000, 15000000]
export const minUSDMonthlyPrice = [500, 800, 1000, 1500]

export const hotSearchs = [
  {
    link: "/gorod-tashkent/novostroyki?min_area=23&max_area=70&sort_value=asc&sort_key=area",
    value: { uz: "23-70 m²", ru: "23-70 м²" },
  },
  {
    link: "/gorod-tashkent/novostroyki/v-ipoteku",
    value: {
      uz: "Ipoteka",
      ru: "Ипотека",
    },
  },
  {
    link: "/gorod-tashkent/novostroyki",
    value: { uz: "Toshkent markazidan", ru: "из центра Ташкента" },
  },
  {
    link: "/gorod-tashkent/novostroyki/biznes-klass",
    value: { uz: "Biznes klass", ru: "Бизнес класс" },
  },
  {
    link: "/gorod-tashkent/novostroyki/sdannye",
    value: { uz: "Tayyor uylar 2025", ru: "Сданные новостройки 2025" },
  },
  {
    link: "/gorod-tashkent/novostroyki?discount=true",
    value: { uz: "Chegirmali", ru: "Со скидкой" },
  },
]
export const initialRooms = [
  {
    label: "Студия",
    key: "0",
    isTop: false,
  },
  {
    label: "1",
    key: "1",
    isTop: true,
  },
  {
    label: "2",
    key: "2",
    isTop: false,
  },
  {
    label: "3",
    key: "3",
    isTop: false,
  },
  {
    label: "4+",
    key: "4",
    isTop: false,
  },
]
