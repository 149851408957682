import { useAppSelector } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import React from 'react';
import { USD, UZ } from 'utils/constants/locales';
import { hot_sub_titles } from 'utils/constants/subTitles';
import { calcUsdValue } from 'utils/helpers/calcUsdValue';
import { filterPriceSeparator } from 'utils/helpers/filterPriceSeparator';
import { layoutLang } from 'utils/language/layout';
interface IProps {
  sort_value: number | null;
}
const SortComp: React.FC<IProps> = ({
  sort_value
}) => {
  const {
    locale,
    query
  } = useRouter();
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const usdValue = useAppSelector(state => state.localeReducer?.usdValue);
  const hotFilterText = locale === UZ ? hot_sub_titles[query?.sort_key as keyof typeof hot_sub_titles]?.uz : hot_sub_titles[query?.sort_key as keyof typeof hot_sub_titles]?.ru;
  const finalPrice = (price: number) => currency === USD ? filterPriceSeparator(Math.ceil(calcUsdValue(price, usdValue, currency)), locale) : filterPriceSeparator(price, locale);
  if (query?.sort_key === "total_price" || sort_value === null) return null;
  return <div className='py-2 px-3 flex items-center justify-between bg-[#FFAB5526] rounded-lg' data-sentry-component="SortComp" data-sentry-source-file="sort-comp.tsx">
      <div className='text-[13px] font-medium text-[#DC6803] w-[60%] truncate'>{hotFilterText}</div>
      <span className='text-base font-semibold text-[#DC6803]'>
        {`${finalPrice(sort_value as number)} ${query?.sort_key === 'delay' ? layoutLang[locale || 'ru']['oy'] : currency === USD ? '$' : layoutLang[locale || 'ru']['sum']}`}
      </span>
    </div>;
};
export default SortComp;