export const prePayment = [
  {
    id: 1,
    value: "50",
    label: "0-50000000",
    type: "mln",
  },
  {
    id: 2,
    value: "50-100",
    label: "50000000-100000000",
    type: "mln",
  },
  {
    id: 2,
    value: "100-200",
    label: "100000000-200000000",
    type: "mln",
  },
  {
    id: 3,
    value: "200-300",
    label: "200000000-300000000",
    type: "mln",
  },
  {
    id: 4,
    value: "300-400",
    label: "300000000-400000000",
    type: "mln",
  },
  {
    id: 5,
    value: "400-500",
    label: "400000000-500000000",
    type: "mln",
  },
  {
    id: 6,
    value: "500+",
    label: "500000000",
    type: "mln",
  },
]
export const maxPrices = [
  {
    id: 1,
    value: "200",
    label: "0-200000000",
    type: "mln",
  },
  {
    id: 2,
    value: "200-400",
    label: "200000000-400000000",
    type: "mln",
  },
  {
    id: 3,
    value: "400-600",
    label: "400000000-600000000",
    type: "mln",
  },
  {
    id: 4,
    value: "600-800",
    label: "600000000-800000000",
    type: "mln",
  },
  {
    id: 5,
    value: "800 mln - 1 mlrd",
    label: "800000000-1000000000",
    type: "mln",
  },
  {
    id: 6,
    value: "+1",
    label: "1000000000",
    type: "mlrd",
  },
]
export const minPrices = [
  {
    id: 1,
    value: "5",
    label: "0-5000000",
    type: "mln",
  },
  {
    id: 2,
    value: "5-7",
    label: "5000000-7000000",
    type: "mln",
  },
  {
    id: 3,
    value: "7-10",
    label: "7000000-10000000",
    type: "mln",
  },
  {
    id: 4,
    value: "10+",
    label: "10000000",
    type: "mln",
  },
]

export const quizCities = [
  {
    id: 1,
    value: 8,
    label: {
      uz: "Samarqand viloyati",
      ru: "Самаркандская область",
    }
  },
  {
    id: 2,
    value: 12,
    label: {
      uz: "Toshkent shahri",
      ru: "город Ташкент",
    }
  },
  {
    id: 3,
    value: null,
    label: {
      uz: "Boshqa",
      ru: "Другой",
    }
  },
]
