import { USD } from "../constants/locales"

export const calcUsdValue = (value: number, currentCurrency: number, currency: string) => {
  
  if (currency === USD) {
    const finalData = value / currentCurrency
    return finalData
  } else {
    return value
  }
} 