import { useRouter } from 'next/router';
import { stringify, UrlObject } from 'query-string';
import React from 'react';
import { RU } from 'utils/constants/locales';
const CustomLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: React.ReactNode;
  key?: React.Key;
  href: string | (UrlObject & string);
}> = props => {
  const {
    href,
    target,
    className,
    children,
    onClick,
    ...rest
  } = props;
  const router = useRouter();
  const isExternal = (url: string) => /^https?:\/\//.test(url);
  const onNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
    if (typeof href === 'string' && isExternal(href)) {
      return;
    }
    if (target === '_blank') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (typeof href === 'object') {
      router.push(href);
    } else {
      router.push(`${router?.locale !== RU ? `/${router?.locale}` : ''}${href}`);
    }
  };
  const getHref = () => {
    if (!href) return ""; // Default fallback if href is null or undefined

    if (typeof href === "string") {
      if (isExternal(href)) {
        return href;
      }
      return `${process.env.NEXT_PUBLIC_FRONT_URL}${router?.locale !== RU ? `/${router?.locale}` : ""}${href}`;
    } else if (typeof href === "object" && (href as {
      pathname: string;
    }).pathname) {
      const {
        pathname,
        query
      } = href as {
        pathname: string;
        query?: Record<string, any>;
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {
        location,
        ...rest
      } = query ?? {};
      return Object.keys(rest).length > 0 ? `${process.env.NEXT_PUBLIC_FRONT_URL}${pathname}?${stringify(rest)}` : `${process.env.NEXT_PUBLIC_FRONT_URL}${pathname}`;
    }
    return ""; // Fallback if href is an unexpected value
  };
  return <a {...rest} target={target} href={getHref()} onClick={onNavigate} rel={target === '_blank' ? 'noopener noreferrer' : undefined} className={className ?? 'decoration-none hover:pointer-events-auto'} data-sentry-component="CustomLink" data-sentry-source-file="index.tsx">
      {children}
    </a>;
};
export default CustomLink;