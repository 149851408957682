import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import style from "./headerDropdown.module.scss";
import { layoutLang } from "../../../../../utils/language/layout";
import { RU, USD, UZ, UZS } from "../../../../../utils/constants/locales";
import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks";
import { localeReducerActions } from "../../../../../store/reducers/localeReducer";
import UzFlagIcon from "assets/icons/UzFlagIcon";
import RuFlagIcon from "assets/icons/RuFlagIcon";
import Link from "next/link";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
interface IProps {
  type: "lang" | "currency";
}
const HeaderDropDown: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const {
    asPath,
    locale,
    query
  } = useRouter();
  const currency = useAppSelector(state => state.localeReducer?.currency);
  const lang = useAppSelector(state => state.localeReducer?.lang);
  const {
    changeLanguage,
    changeCurrency,
    changeUsdValue
  } = localeReducerActions;
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    fetch(`https://cbu.uz/ru/arkhiv-kursov-valyut/json/`).then(res => res.json()).then((res: any[]) => {
      dispatch(changeUsdValue(res?.find(item => item?.Ccy === "USD")?.Rate));
    });
    dispatch(changeLanguage(locale === RU ? RU : UZ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeLang = (lang: string) => {
    dispatch(changeLanguage(lang));
  };
  useEffect(() => {
    if (query?.currency) {
      dispatch(changeCurrency(USD));
    } else {
      dispatch(changeCurrency(UZS));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currency]);
  return <Dropdown placement="bottomRight" open={visible} trigger={["click", "hover"]} mouseEnterDelay={1000} mouseLeaveDelay={0.3} overlayClassName={style.overlayClassDrop} onOpenChange={() => setVisible(!visible)} overlay={<div className="flex select-none flex-col gap-6 rounded-lg border border-solid border-[#EAECF0] bg-white px-4 py-[15px]" style={{
    boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
  }}>
          <div className="flex flex-col gap-3">
            <span className="text-base font-medium text-[#101828]">{layoutLang[locale || 'ru']["til"]}</span>
            <div className="flex items-center gap-2">
              <Link href={asPath} locale={UZ}>
                <div onClick={() => handleChangeLang(UZ)} className={`relative flex cursor-pointer items-center gap-3 rounded-xl border-[1.5px] border-solid px-4 py-2.5 ${lang === UZ ? "border-[#2BCD93] bg-[#F3FCF9]" : "border-[#F9FAFB] bg-[#F2F4F7]"}`}>
                  <UzFlagIcon />
                  <span className={`text-sm font-medium ${lang === UZ ? "text-[#00A389]" : "text-[#1D2939]"}`}>
                    O‘zbekcha
                  </span>
                </div>
              </Link>
              <Link href={asPath} locale={RU}>
                <div onClick={() => handleChangeLang(RU)} className={`relative flex cursor-pointer items-center gap-3 rounded-xl border-[1.5px] border-solid px-4 py-2.5 ${lang === RU ? "border-[#2BCD93] bg-[#F3FCF9]" : "border-[#F9FAFB] bg-[#F2F4F7]"}`}>
                  <RuFlagIcon />
                  <span className={`text-sm font-medium ${lang === RU ? "text-[#00A389]" : "text-[#1D2939]"}`}>
                    Русский
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="text-base font-medium text-[#101828]">{layoutLang[locale || 'ru']["valyuta"]}</span>
            <div className="flex items-center gap-2">
              <Link href={{
          query: {
            ...removeObjProperties(query, 'currency')
          }
        }} className={`relative flex w-[50%] cursor-pointer items-center justify-center gap-3 rounded-xl border-[1.5px] border-solid px-4 py-2.5 ${currency === UZS ? "border-[#2BCD93] bg-[#F3FCF9]" : "border-[#F9FAFB] bg-[#F2F4F7]"}`}>
                <span className={`min-w-max text-sm font-medium ${currency === UZS ? "text-[#00A389]" : "text-[#1D2939]"}`}>
                  {layoutLang[locale || 'ru']["ozbek_somi"]}
                </span>
              </Link>
              <Link href={{
          query: {
            ...query,
            currency: USD
          }
        }} className={`relative flex w-[50%] cursor-pointer items-center justify-center gap-3 rounded-xl border-[1.5px] border-solid px-4 py-2.5 ${currency === USD ? "border-[#2BCD93] bg-[#F3FCF9]" : "border-[#F9FAFB] bg-[#F2F4F7]"}`}>
                <span className={`min-w-max text-sm font-medium ${currency === USD ? "text-[#00A389]" : "text-[#1D2939]"}`}>
                  {layoutLang[locale || 'ru']["aqsh_dollari"]}
                </span>
              </Link>
            </div>
          </div>
        </div>} data-sentry-element="Dropdown" data-sentry-component="HeaderDropDown" data-sentry-source-file="HeaderDropDown.tsx">
      <div className={`flex h-8 cursor-pointer items-center gap-2.5 rounded-xl border border-solid border-[#EAECF0] px-3 text-sm font-semibold text-[#475467]`} onClick={() => setVisible(!visible)}>
        {locale === UZ ? <UzFlagIcon width={20} height={14} /> : <RuFlagIcon width={20} height={14} />}
        {` |  ${currency?.toUpperCase()}`}
      </div>
    </Dropdown>;
};
export default HeaderDropDown;