import React, { cloneElement, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { LOCATION } from "../../../utils/constants/queryParamsKeys";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import { isEmptyArr } from "utils/helpers/isEmptyArr";
import { filterReducerActions } from "store/reducers/filterReducer";
import { bodyOverflowAuto } from "utils/helpers/changeBodyOverflow";
import { DEFAULT_LOCATION } from "utils/constants/defaultLocation";
import { rootPaths } from "utils/constants/rootPaths";
import { findMatchingKey } from "utils/helpers/findMatchingKey";
import { removeObjProperties } from "utils/helpers/removeObjProperties";
import { cleanObject } from "utils/helpers/cleanObject";
import RefreshIcon from "../../../assets/icons/RefreshIcon";
import ShareIcon from "assets/icons/ShareIcon";
import ShareModal from "pages/detailed-residential-complex/components/share-modal/ShareModal";
import FilterDesktopTop from "./filter-desktop-top/filter-desktop-top";
import FilterDesktopBottom from "./filter-dektop-bottom/filter-desktop-bottom";
import styles from "./filterDesktopModal.module.scss";
interface IProps {
  children?: JSX.Element;
}
const FilterDesktopModal = ({
  children
}: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const {
    push,
    query,
    locale,
    pathname
  } = useRouter();
  const [open, setOpen] = useState(false);
  const filter = useAppSelector(state => state.favouritesReducer.filter);
  const location = useAppSelector(state => state.favouritesReducer.location);
  const openDesktopModal = useAppSelector(state => state.favouritesReducer.openDesktopModal);
  const {
    clearFilters,
    setCurrentRegion,
    setOpenDesktopModal,
    setChangeFilterType
  } = favouritesReducerActions;
  const {
    clearPlaceFilters
  } = filterReducerActions;
  const [typeFilter, setTypeFilter] = useState('');
  const [discount, setDiscount] = useState<{
    name: string;
    value: string;
  }[] | undefined>(undefined);
  const viewType = pathname.includes("karta");
  const handleClose = () => {
    dispatch(setCurrentRegion({
      type: "location",
      value: {
        city: null,
        district: null
      }
    }));
    setLoading(false);
    dispatch(setOpenDesktopModal(false));
    dispatch(clearFilters());
    setDiscount(undefined);
    bodyOverflowAuto();
  };
  const handleOpen = () => {
    dispatch(setOpenDesktopModal(true));
  };
  const {
    complexes,
    metro_lines,
    nearest_places,
    brands,
    filterSearchValue
  } = useAppSelector(state => state.filterReducer);
  const complex_ids = complexes && !isEmptyArr(complexes) ? complexes?.map(item => item?.id) : null;
  const metro_ids = metro_lines && !isEmptyArr(metro_lines) ? metro_lines?.map(item => item?.id) : null;
  const nearest_place_ids = nearest_places && !isEmptyArr(nearest_places) ? nearest_places?.map(item => String(item?.id)) : null;
  const brand_ids = brands && !isEmptyArr(brands) ? brands?.map(item => item?.id) : null;
  const currentLocation = location?.regionValue && location?.regionValue?.district ? `${location?.regionValue?.district?.city?.url}-${location?.regionValue?.district?.url}` : location?.regionValue?.city ? location?.regionValue?.city?.url : DEFAULT_LOCATION;
  const currenSharetLocation = location?.regionValue && location?.regionValue?.district ? location?.regionValue?.district?.city?.url : location?.regionValue?.city ? location?.regionValue?.city?.url : DEFAULT_LOCATION || DEFAULT_LOCATION;
  const transformedObject = discount?.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {} as Record<string, string>);
  const updatedItems: {
    [key: string]: any;
  } = {
    ...filter,
    complex_ids,
    metro_line_ids: metro_ids,
    nearest_place_ids,
    brand_ids,
    search: filterSearchValue,
    sort_key: query?.sort_key,
    sort_value: query?.sort_value,
    currency: query?.currency,
    ...(discount !== undefined ? transformedObject : {})
  };
  const basePath = `/${currenSharetLocation}${typeFilter === "complex" ? rootPaths.RESIDENTIAL_COMPLEXES : rootPaths.APARTMENTS}`;
  const params = new URLSearchParams();
  const newParamItems = {
    ...updatedItems,
    city_id: location?.regionValue?.city ? location?.regionValue?.city?.id : location?.regionValue?.district ? location?.regionValue?.district?.city?.id : undefined,
    district_id: location?.regionValue?.district ? location?.regionValue?.district?.id : undefined
  };
  for (const key in cleanObject(newParamItems)) {
    const value = cleanObject(newParamItems)[key];
    if (Array.isArray(value)) {
      value.forEach(val => params.append(key, val));
    } else {
      params.append(key, value);
    }
  }
  const fullUrl = `${process.env.NEXT_PUBLIC_FRONT_URL}${basePath}?${params.toString()}`;
  const onFinish = () => {
    setLoading(true);
    dispatch(setChangeFilterType(typeFilter as "complex" | "apartment"));
    const isComplex = typeFilter === "complex";
    const cleanedQuery = cleanObject(updatedItems);
    const matchedKey = findMatchingKey(removeObjProperties(cleanedQuery, 'location', 'page', 'apartmentId', 'id', 'city_id'));
    const mapQuery = {
      ...cleanedQuery,
      ...(viewType && {
        type: typeFilter
      }),
      ...(viewType && currentLocation !== DEFAULT_LOCATION && {
        [LOCATION]: currentLocation
      })
    };
    if (!viewType && matchedKey) {
      push({
        pathname: `/${currentLocation}/${isComplex ? rootPaths.RESIDENTIAL_COMPLEXES : rootPaths.APARTMENTS}/${matchedKey}`
      }).then(() => {
        dispatch(setCurrentRegion({
          type: 'hot_location',
          value: location?.regionValue
        }));
        handleClose();
      });
    } else {
      push({
        pathname: `${viewType ? "/karta" : `/${currentLocation}/${isComplex ? "novostroyki" : "kvartiri"}`}`,
        query: mapQuery
      }).then(() => {
        dispatch(setCurrentRegion({
          type: 'hot_location',
          value: location?.regionValue
        }));
        handleClose();
      });
    }
  };
  const handleRefreshFilter = () => {
    setClearLoading(true);
    if (pathname.includes("kvartiri")) {
      setTypeFilter("apartment");
    } else {
      setTypeFilter("complex");
    }
    push({
      pathname: `/${DEFAULT_LOCATION}${typeFilter === 'apartment' ? rootPaths.APARTMENTS : rootPaths.RESIDENTIAL_COMPLEXES}`
    }).then(() => {
      dispatch(setOpenDesktopModal(false));
      setClearLoading(false);
      setDiscount(undefined);
      dispatch(clearFilters());
      dispatch(clearPlaceFilters());
      dispatch(setCurrentRegion({
        type: "location",
        value: {
          city: null,
          district: null
        }
      }));
    });
  };
  const handleOpenShareModal = () => {
    setOpen(true);
  };
  useEffect(() => {
    dispatch(clearFilters());
    bodyOverflowAuto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const footerContent = () => <div className={styles.footer} data-sentry-component="footerContent" data-sentry-source-file="FilterDesktopModal.tsx">
    <Button loading={clearLoading} size="large" icon={<RefreshIcon />} className={styles.footer_clear} onClick={handleRefreshFilter} data-sentry-element="Button" data-sentry-source-file="FilterDesktopModal.tsx">
      {layoutLang[locale || "ru"]['tozalash']}
    </Button>
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-3 h-[42px] rounded-[10px] cursor-pointer bg-[#EAECF0] px-4" onClick={handleOpenShareModal}>
        <ShareIcon stroke="#344054" data-sentry-element="ShareIcon" data-sentry-source-file="FilterDesktopModal.tsx" />
        <span className="text-[#344054] text-base font-medium">{layoutLang[locale || "ru"]["Ulashish"]}</span>
      </div>
      <Button size="large" className="rounded-lg" type="primary" loading={loading} onClick={onFinish} data-sentry-element="Button" data-sentry-source-file="FilterDesktopModal.tsx">
        {layoutLang[locale || "ru"]['Найти']}
      </Button>
    </div>
  </div>;
  return <>
      {children && cloneElement(children, {
      onClick: handleOpen
    })}
      <Modal title={layoutLang[locale || "ru"]["Фильтр"]} footer={footerContent()} open={openDesktopModal} onCancel={handleClose} width={1000} wrapClassName={styles.modal} centered data-sentry-element="Modal" data-sentry-source-file="FilterDesktopModal.tsx">
        <div className="flex flex-col gap-4">
          <FilterDesktopTop setTypeFilter={setTypeFilter} typeFilter={typeFilter} data-sentry-element="FilterDesktopTop" data-sentry-source-file="FilterDesktopModal.tsx" />
          <FilterDesktopBottom discount={discount} setDiscount={setDiscount} data-sentry-element="FilterDesktopBottom" data-sentry-source-file="FilterDesktopModal.tsx" />
        </div>
      </Modal>
      <ShareModal open={open} setOpen={setOpen} copyUrl={fullUrl} data-sentry-element="ShareModal" data-sentry-source-file="FilterDesktopModal.tsx" />
    </>;
};
export default FilterDesktopModal;