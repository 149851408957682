export const staticUrls: {
  [key: string]: {
    rooms_count?: string[];
    repaired?: 'true' | "false",
    payment_method_ids?: string[],
    class_ids?: string[],
    nearest_place_ids?: string[],
    completed_complex?: string
  }
} = {
  odnokomnatnye: { rooms_count: ['1'] },
  dvuhkomnatnye: { rooms_count: ['2'] },
  trehkomnatnye: { rooms_count: ['3'] },
  chetyrehkomnatnye: { rooms_count: ['4'] },
  mnogokomnatnye: { rooms_count: ['4+'] },
  studii: { rooms_count: ['0'] },

  sdannye: { completed_complex: 'true' },
  "v-processe-stroitelstva": { completed_complex: 'false' },

  "bez-otdelki": { repaired: 'false' },
  "s-otdelkoy": { repaired: 'true' },

  "v-ipoteku": { payment_method_ids: ['1'] },
  "v-rassrochku": { payment_method_ids: ['2'] },

  "komfort-klass": { class_ids: ['1'] },
  "biznes-klass": { class_ids: ['2'] },
  "ekonom-klass": { class_ids: ['3'] },
  'premium-klass': { class_ids: ['4'] },

  "metro-buyuk-ipak-yuli": { nearest_place_ids: ['42'] },
  "metro-pushkin": { nearest_place_ids: ['43'] },
  "metro-novza": { nearest_place_ids: ['50'] },
  'metro-mirzo-ulugbek': { nearest_place_ids: ['51'] },
  "metro-chilonzor": { nearest_place_ids: ['52'] },
  "metro-choshtepa": { nearest_place_ids: ['54'] },
  "metro-uzgarish": { nearest_place_ids: ['55'] },
  "metro-sergeli": { nearest_place_ids: ['56'] },
  "metro-yangihaet": { nearest_place_ids: ['57'] },
  "metro-mashinastroitelov": { nearest_place_ids: ['68'] },
  'metro-gafur-gulom': { nearest_place_ids: ['62'] },
  "metro-bodomzor": { nearest_place_ids: ['88'] },
  "metro-yunusabad": { nearest_place_ids: ['90'] },
  "metro-yashnobod": { nearest_place_ids: ['71'] },
  "metro-chinor": { nearest_place_ids: ["58"] },
  "metro-tuzel": { nearest_place_ids: ["72"] },
  "metro-olmos": { nearest_place_ids: ["73"] },
  "metro-yangiobod": { nearest_place_ids: ["75"] },
  "metro-matonot": { nearest_place_ids: ["77"] },
  "metro-kiet": { nearest_place_ids: ["78"] },
  "metro-turon": { nearest_place_ids: ["82"] },
  "metro-kipchok": { nearest_place_ids: ["83"] }
}

export const breadCrumbsUrls = {
  odnokomnatnye: { uz: "1 xonalik", ru: "Однокомнатная" },
  dvuhkomnatnye: { uz: "2 xonalik", ru: "Двухкомнатная" },
  trehkomnatnye: { uz: "3 xonalik", ru: "Трехкомнатная" },
  chetyrehkomnatnye: { uz: "4 xonalik", ru: "Четырехкомнатная" },
  mnogokomnatnye: { uz: "ko'p xonalik", ru: "Многокомнатная" },
  studii: { uz: "Studio", ru: "Студия" },

  sdannye: { uz: "Tayyor", ru: "Готовый" },
  "v-processe-stroitelstva": { uz: "Qurilmoqda", ru: "Строится" },

  "bez-otdelki": { uz: "Ta'mirsiz", ru: "Без ремонтом" },
  "s-otdelkoy": { uz: "Ta'mirli", ru: "С ремонтом" },

  "v-ipoteku": { uz: "Ipotekaga", ru: "К ипотеке" },
  "v-rassrochku": { uz: "Bo'lib to'lashga", ru: "Оплатить в рассрочку" },

  "komfort-klass": { uz: "Komfort sinfli", ru: "Комфорт класс" },
  "biznes-klass": { uz: "Biznes sinfli", ru: "Бизнес класс" },
  "ekonom-klass": { uz: "Ekonom sinfli", ru: "Эконом класс" },
  'premium-klass': { uz: "Premium sinfli", ru: "Премиум класс" },

  "metro-buyuk-ipak-yuli": { uz: "Buyuk Ipak Yo'li metro", ru: "метро Буюк Ипак Йўли" },
  "metro-pushkin": { uz: "Pushkin metro", ru: "метро Пушкин" },
  "metro-novza": { uz: "Novza metro", ru: "метро Новза" },
  'metro-mirzo-ulugbek': { uz: "Mirzo Ulug'bek metro", ru: "метро Мирзо Улугбек" },
  "metro-chilonzor": { uz: "Chilonzor metro", ru: "метро Чиланзор" },
  "metro-choshtepa": { uz: "Choshtepa metro", ru: "метро Чаштепа" },
  "metro-uzgarish": { uz: "O'zgarish metro", ru: "метро Ўзгариш" },
  "metro-sergeli": { uz: "Sergeli metro", ru: "метро Сергели" },
  "metro-yangihaet": { uz: "Yangi hayot metro", ru: "метро Янги хаёт" },
  "metro-mashinastroitelov": { uz: "Mashinasozlar metro", ru: "метро Машиносозлар" },
  'metro-gafur-gulom': { uz: "Ga'fur G'ulom metro", ru: "метро Ғафур Ғулом" },
  "metro-bodomzor": { uz: "Bodomzor metro", ru: "метро Бодомзор" },
  "metro-yunusabad": { uz: "Yunusobod metro", ru: "метро Юнусобод" },
  "metro-yashnobod": { uz: "Yashnobod metro", ru: "метро Яшнабад" },
  "metro-chinor": { uz: "Chinor metro", ru: "метро Чинор" },
  "metro-tuzel": { uz: "Tuzel metro", ru: "метро Тузел" },
  "metro-olmos": { uz: "Olmos metro", ru: "метро Олмос" },
  "metro-yangiobod": { uz: "Yangiobod metro", ru: "метро Янгиабад" },
  "metro-matonot": { uz: "Matonat metro", ru: "метро Матонат" },
  "metro-kiet": { uz: "Qiyot metro", ru: "метро Кият" },
  "metro-turon": { uz: "Turon metro", ru: "метро Туран" },
  "metro-kipchok": { uz: "Qipchoq metro", ru: "метро Кипчак" }
}