import React from 'react';
const MapMenuIcon = ({
  stroke = '#1D2939'
}: ({
  stroke?: string;
})) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="MapMenuIcon" data-sentry-source-file="MapMenuIcon.tsx">
      <path d="M20 10L12 5L4 10L12 15L20 10Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MapMenuIcon.tsx" />
      <path d="M20 14L12 19L4 14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="MapMenuIcon.tsx" />
    </svg>;
};
export default MapMenuIcon;