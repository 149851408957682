import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../../store/reducers/userReducer";
import { rootPaths } from "../../../../../utils/constants/rootPaths";
import { menuLinks } from "../../../utils/constants/menuLinks";
import { layoutLang } from "../../../../../utils/language/layout";
import UysotIcon from "../../../../../assets/icons/UysotIcon";
import HamburgerIcon from "../../../assets/icon/HamburgerIcon";
import styles from "./HeaderBottom.module.scss";
import UzFlagIcon from "assets/icons/UzFlagIcon";
import React from "react";
import RuFlagIcon from "assets/icons/RuFlagIcon";
import HeaderTop from "../top/HeaderTop";
import { favouritesReducerActions } from "store/reducers/favouritesReducer";
import CustomLink from "common/custom-link";
import { UZ } from "utils/constants/locales";
import UysotCoinMobile from "../../uysot-coin/uysot-coin-mobile";
import { cx } from "utils/cx";
import VideoIcon from "assets/icons/VideoIcon";
const HeaderBottom: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    pathname,
    locale,
    query
  } = useRouter();
  const {
    toggleVisibleMobileMenu,
    setChangeMenu,
    setOpenLang
  } = userReducerActions;
  const {
    setChangeFilterType
  } = favouritesReducerActions;
  const menuIcon = useAppSelector(state => state.userReducer?.menuIcon);
  const lang = useAppSelector(state => state.localeReducer?.lang);

  // handle open mobile menu
  const handleOpenMobilMenu = () => {
    dispatch(toggleVisibleMobileMenu());
    dispatch(setChangeMenu(!menuIcon));
  };
  const handleOpenLang = () => {
    dispatch(setOpenLang(true));
  };
  return <div className={`${styles.header_bottom} flex flex-col w-full gap-3`} data-sentry-component="HeaderBottom" data-sentry-source-file="HeaderBottom.tsx">
      <div className="w-full relative z-[101] bg-white">
        <div className="border-b border-solid border-gray-200">
          <div className={`${styles.header_bottom_content} justify-between max-w-[1254px] mx-auto md:!h-[56px]`}>
            <div className="flex items-center gap-7 h-full">
              <div className={styles.header_bottom_content_left}>
                <div className="w-8 h-8 border border-solid border-[#EAECF0] rounded-lg xl:hidden flex items-center justify-center">
                  <HamburgerIcon className={`${styles.header_bottom_content_left_menuIcon} ${styles.hamburger}`} onClickFunc={handleOpenMobilMenu} data-sentry-element="HamburgerIcon" data-sentry-source-file="HeaderBottom.tsx" />
                </div>
                <CustomLink href={query?.currency ? `${rootPaths.INDEX}?currency=${query?.currency}` : rootPaths.INDEX} className={styles.root_link} data-sentry-element="CustomLink" data-sentry-source-file="HeaderBottom.tsx">
                  <UysotIcon data-sentry-element="UysotIcon" data-sentry-source-file="HeaderBottom.tsx" />
                </CustomLink>
              </div>

            </div>
            <div className="xl:block hidden h-full">
              <HeaderTop data-sentry-element="HeaderTop" data-sentry-source-file="HeaderBottom.tsx" />
            </div>
            <div className={`${styles.header_bottom_content_right} flex xl:hidden  items-center gap-3`} aria-hidden suppressHydrationWarning>
              <div className="mobile_only">
                <UysotCoinMobile data-sentry-element="UysotCoinMobile" data-sentry-source-file="HeaderBottom.tsx" />
              </div>
              <div className="flex items-center h-[37px] gap-2.5 p-2.5 border border-solid border-[#EAECF0] rounded-xl cursor-pointer" onClick={handleOpenLang}>
                {lang === UZ ? <>
                    <UzFlagIcon width={19} height={16} />
                    <span className="text-[#475467] font-semibold">O‘z</span>
                  </> : <>
                    <RuFlagIcon width={19} height={19} />
                    <span className="text-[#475467] font-semibold">РУ</span>
                  </>}
              </div>

            </div>
          </div>
        </div>
        <div className="border-b border-solid border-gray-200 hidden md:block">
          <div className={cx(styles.header_bottom_content, 'max-w-[1254px] mx-auto md:!h-[51px]')}>
            <nav className="xl:flex hidden" aria-hidden suppressHydrationWarning>
              <ul className={styles.header_bottom_content_center}>
                {menuLinks().map((item, index) => <li key={index}>
                      <CustomLink href={query?.currency ? `${item.path}?currency=${query?.currency}` : item?.path} className={`${pathname.includes(item?.basePath) ? styles.active : ""} flex items-center gap-2`} onClick={() => {
                  item?.path?.includes("kvartiri") ? dispatch(setChangeFilterType("apartment")) : item?.path?.includes("novostroyki") ? dispatch(setChangeFilterType("complex")) : "";
                }}>
                        {item?.path === rootPaths.REELS ? <VideoIcon width={16} height={16} /> : ''}
                        <span>{layoutLang[locale || 'ru'][item?.label as string]}</span>
                      </CustomLink>
                    </li>)}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>;
};
export default HeaderBottom;